import {
  TableRow,
  TableCell,
  IconButton,
  Typography,
  Stack,
  Link,
  Autocomplete,
  TextField,
  Skeleton,
  Checkbox,
  Tooltip,
  Chip
} from "@mui/material";
import { Dispatch, SetStateAction, useCallback, useEffect, useState } from "react";
import Iconify from "../../minimals/components/iconify";
import { statusColorMap } from "./statusColorMap";
import { BasicTask, EventType, SelectedTasks, StatusType } from "./types";
import axios from "axios";
import { StyledBadge } from "../shared/StyledBadge";
import { Contact } from "../shared/orderInfo/types";
import getRegularNotesCount from "../utils/getRegularNotesCount";
import RequiredDocAlertModal from "./RequiredDocAlertModal";
import { fMonthDayYearDotNotation } from "../utils/formatTime";
import RequirementInfo from "./RequirementInfo";
import { debounce } from "lodash";
import { DraggableProvided, DraggableStateSnapshot } from "react-beautiful-dnd";

const TaskRow = ({
  t,
  statusTypes,
  eventTypes,
  refreshTasks,
  setSelectedTaskNotes,
  setSelectedTaskUploadDocument,
  setSelectedTaskViewDocuments,
  setSelectedTaskHistory,
  contacts,
  handleSelectTask,
  isSelected,
  provided,
  snapshot
}: {
  t: BasicTask;
  parentTask?: BasicTask;
  statusTypes: StatusType[];
  eventTypes: EventType[];
  refreshTasks: () => void;
  selectedTaskNotes: BasicTask | null;
  selectedTaskAssignment: BasicTask | null;
  setSelectedTaskNotes: Dispatch<SetStateAction<BasicTask | null>>;
  setSelectedAssignment: Dispatch<SetStateAction<BasicTask | null>>;
  selectedTaskUploadDocument: BasicTask | null;
  setSelectedTaskUploadDocument: Dispatch<SetStateAction<BasicTask | null>>;
  selectedTaskViewDocuments: BasicTask | null;
  setSelectedTaskViewDocuments: Dispatch<SetStateAction<BasicTask | null>>;
  setSelectedTaskHistory: Dispatch<SetStateAction<BasicTask | null>>;
  contacts: Contact[];
  handleSelectTask: (taskId: number, isChecked: boolean) => void;
  isSelected: boolean;
  selectedTasks: SelectedTasks;
  index: number;
  provided: DraggableProvided;
  snapshot: DraggableStateSnapshot;
}) => {
  const [selectedContact, setSelectedContact] = useState<Contact | null>(null);
  const [alertRequiredDocOpen, setAlertRequiredDocOpen] = useState<boolean>(false);

  const updateTaskStatus = async (basicTaskId: number, status: number) => {
    await axios.post("/api/taskmanagement/tasks/updateStatus", {
      basicTaskId,
      status
    });
    refreshTasks();
  };

  const updateContactCode = async (contactCode: string | undefined) => {
    await axios.post("/api/taskmanagement/tasks/setContactCode", {
      basicTaskId: t.id,
      contactCode
    });
    refreshTasks();
  };

  const saveQuickNote = async (taskId: number, note: string | null) => {
    await axios.post("/api/taskmanagement/tasks/editQuickNote", {
      taskId,
      note
    });
    refreshTasks();
  };

  const debouncedSaveQuickNote = useCallback(
    debounce((taskId, note) => saveQuickNote(taskId, note), 300),
    []
  );

  useEffect(() => {
    if (contacts.length === 0) return;
    setSelectedContact(contacts.find((c) => c.code === t.contactCode) || null);
  }, [t.contactCode, contacts]);

  const isContactsLoading = contacts.length === 0;

  const isFlips = t.applicationId === 1;

  const latestEvent = t.statusUpdateHistory.length > 0 ? t.statusUpdateHistory[t.statusUpdateHistory.length - 1] : null;

  return (
    <>
      <TableRow
        key={t.id}
        ref={provided.innerRef}
        sx={{
          ...provided.draggableProps.style,
          background: "#ffffff",
          border: snapshot.isDragging ? "1px dashed lightgreen" : "none",
          boxShadow: snapshot.isDragging ? "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.15)" : "none"
        }}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
      >
        {!isFlips && (
          <TableCell padding="checkbox" sx={{ verticalAlign: "top", paddingTop: 1.2 }}>
            <Checkbox
              checked={isSelected}
              onChange={(event) => handleSelectTask(t.id, event.target.checked)}
              size="small"
            />
          </TableCell>
        )}
        <TableCell sx={{ verticalAlign: "top", width: 500 }}>
          {!t.parentTask && <Typography variant="subtitle2">{t.subTasks.length === 0 && t.description}</Typography>}
          {(t.parentTask || t.subTasks.length > 0) && (
            <Typography variant="subtitle2" display="inline-block" marginBottom={0.5}>
              {(t.parentTask || t.subTasks.length > 0) && (
                <Chip
                  variant="soft"
                  size="small"
                  label={t.parentTask?.description || t.description}
                  sx={{
                    borderRadius: 1,
                    fontWeight: 600,
                    marginRight: 1
                  }}
                />
              )}
              {t.subTasks.length === 0 && t.description}
            </Typography>
          )}
          {t.parentTask?.originalSelectRequirementText && <RequirementInfo task={t.parentTask} />}
          {t.originalSelectRequirementText && <RequirementInfo task={t} />}
        </TableCell>
        <TableCell sx={{ verticalAlign: "top" }}>
          <Stack direction="row" spacing={0.5}>
            <TextField
              defaultValue={t.quickNote}
              size="small"
              sx={{ width: 300 }}
              multiline
              placeholder="Quick Note"
              onChange={(e) => {
                debouncedSaveQuickNote(t.id, e.target.value);
              }}
              inputProps={{ maxLength: 75 }}
            />
          </Stack>
        </TableCell>
        {isFlips && (
          <TableCell sx={{ verticalAlign: "top" }}>
            {latestEvent && (
              <>
                <Typography variant="body2" color="GrayText" noWrap>
                  <Link onClick={() => setSelectedTaskHistory(t)} sx={{ cursor: "pointer" }}>
                    {eventTypes.find((e) => e.id === latestEvent.eventType)?.description}
                  </Link>{" "}
                  <Typography variant="body2">
                    {latestEvent?.dateTime && fMonthDayYearDotNotation(latestEvent?.dateTime)}
                  </Typography>
                </Typography>
                <Typography variant="body2" color="GrayText">
                  by&nbsp;{latestEvent?.username}
                </Typography>
              </>
            )}
          </TableCell>
        )}

        {!isFlips && (
          <TableCell sx={{ verticalAlign: "top" }}>
            {isContactsLoading && (
              <>
                <Skeleton width={200} />
              </>
            )}
            {!isContactsLoading && (
              <Autocomplete
                sx={{ width: 200 }}
                renderInput={(params) => (
                  <TextField {...params} placeholder="Responsible Party" size="small" variant="outlined" />
                )}
                getOptionLabel={(o) => {
                  if (o.type === "Attorney" && o.represents) {
                    let { represents } = o;
                    if (represents.charAt(represents.length - 1) !== "s") {
                      represents += "s";
                    }
                    return `${represents} Attorney - ${o.name}`;
                  }
                  return `${o.code} - ${o.name || ""}`;
                }}
                options={contacts}
                value={selectedContact}
                onChange={(_, value) => updateContactCode(value?.code)}
              />
            )}
          </TableCell>
        )}
        <TableCell sx={{ verticalAlign: "top" }}>
          <Autocomplete
            sx={{ width: 200 }}
            value={statusTypes.find((s) => s.id === t.taskItemStatusId)}
            onChange={(_, option) => option?.id && updateTaskStatus(t.id, option?.id)}
            options={statusTypes}
            getOptionLabel={(option) => option.description}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                variant="outlined"
                placeholder="Status"
                InputProps={{
                  ...params.InputProps,
                  sx: { color: statusColorMap[statusTypes.find((s) => s.id === t.taskItemStatusId)?.statusType || ""] }
                }}
              />
            )}
            renderOption={(props, option) => (
              <li {...props} key={option.id}>
                <Typography color={statusColorMap[option.statusType]}>{option.description}</Typography>
              </li>
            )}
          />
        </TableCell>
        <TableCell width={400} sx={{ verticalAlign: "top" }}>
          <Stack direction="row" spacing={0.5} justifyContent="flex-end">
            <Tooltip title="View Documents">
              <IconButton onClick={() => setSelectedTaskViewDocuments(t)}>
                <StyledBadge badgeContent={t.documents.length} color="info">
                  <Iconify icon="iconamoon:folder-document" />
                </StyledBadge>
              </IconButton>
            </Tooltip>
            <Tooltip title="Upload Documents">
              <IconButton onClick={() => setSelectedTaskUploadDocument(t)}>
                <Iconify icon="eva:upload-fill" />
              </IconButton>
            </Tooltip>
            <Tooltip title="Add Notes">
              <IconButton onClick={() => setSelectedTaskNotes(t)}>
                <StyledBadge badgeContent={getRegularNotesCount(t.notes.map((n) => n.text))} color="error">
                  <Iconify icon="eva:file-add-outline" />
                </StyledBadge>
              </IconButton>
            </Tooltip>
            <Tooltip title="View History">
              <IconButton onClick={() => setSelectedTaskHistory(t)}>
                <Iconify icon="eva:clock-outline" />
              </IconButton>
            </Tooltip>
          </Stack>
        </TableCell>
      </TableRow>

      <RequiredDocAlertModal open={alertRequiredDocOpen} closeModal={() => setAlertRequiredDocOpen(false)} />
    </>
  );
};

export default TaskRow;
