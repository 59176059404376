import { Autocomplete, TextField } from "@mui/material";
import { useContext } from "react";
import PrefilledIndicator from "../../../components/PrefilledIndicator";
import { OrderContext } from "../../../contexts/OrderDataContext";
import { PropertyUse } from "../../types/PropertyUse";

export default function PropertyUseComponent() {
  const propertyUseList: PropertyUse[] = ["Residential", "Commercial"];

  const { propertyUse, setPropertyUse, errorMode, autoFilled, setAutoFilled } = useContext(OrderContext);
  return (
    <>
      <Autocomplete
        value={propertyUse}
        options={propertyUseList}
        getOptionLabel={(option) => option || ""}
        onChange={(e, value) => {
          setPropertyUse(value);
          setAutoFilled((autoFilled) => ({
            ...autoFilled,
            propertyUse: {
              ...autoFilled.propertyUse,
              autoFilled: false
            }
          }));
        }}
        renderInput={(params) => (
          <TextField
            required
            error={errorMode && !propertyUse}
            {...params}
            label="Property Use"
            placeholder="Select a Property Use"
          />
        )}
      />
      {autoFilled.propertyUse.autoFilled && <PrefilledIndicator title={autoFilled.propertyUse.reason} />}
    </>
  );
}
