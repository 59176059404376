import { Card, Stack, CardHeader } from "@mui/material";
import { OrderContext } from "../contexts/OrderDataContext";
import { useContext, useState, useEffect } from "react";
import ProfilePreviewCard from "./ProfilePreviewCard";
import { curry } from "lodash";
import { Contact } from "./types/Contact";

export default function ProfilePreview() {
  const { selectedClient, buyersAttorney, sellersAttorney, additionalContacts, selectedBuyerPrincipals, selectedSellerPrincipals } = useContext(OrderContext);
  const [contacts, setContacts] = useState<(Contact | null)[]>([]);

  const [openIndexes, setOpenIndexes] = useState<Set<number>>(new Set());

  const setAddedContacts = (client: Contact | null) => {
    const array =
      selectedClient?.lookupCode === buyersAttorney?.lookupCode ||
      selectedClient?.lookupCode === sellersAttorney?.lookupCode
        ? [sellersAttorney, buyersAttorney]
        : [sellersAttorney, buyersAttorney, selectedClient];
    const contacts = array.filter((c) => c);
    setContacts(contacts);
    setIndexes(client, contacts);
    return () => {
      setContacts([]);
    };
  };

  const setIndexes = (client: Contact | null, contacts: (Contact | null)[]) => {
    var index = contacts.indexOf(client);
    setOpenIndexes(new Set([index]));
  };

  useEffect(() => {
    setAddedContacts(sellersAttorney);
  }, [sellersAttorney]);

  useEffect(() => {
    setAddedContacts(buyersAttorney);
  }, [buyersAttorney]);

  useEffect(() => {
    setAddedContacts(selectedClient);
  }, [selectedClient]);
 
  useEffect(() => {
      const openedIndex = contacts.length;
      setContacts([sellersAttorney, buyersAttorney, ...selectedBuyerPrincipals, ...selectedSellerPrincipals, ...additionalContacts].filter(c => c?.name));
      setOpenIndexes(new Set([openedIndex]));
  }, [additionalContacts, selectedBuyerPrincipals, selectedSellerPrincipals]);

  const onAccordianClick = (index: number) => {
    const newList = new Set(openIndexes);
    if (openIndexes.has(index)) {
      newList.delete(index);
    } else {
      newList.add(index);
    }
    setOpenIndexes(newList);
  };

  const updateContact = (index: number, contact: Contact) => {
    setContacts((contacts) => contacts.map((c, ind) => (index === ind ? contact : c)));
  };

  const updateCurrentContact = curry(updateContact);

  if (!contacts.length) return <></>;

  return (
    <>
      <Card
        sx={{
          mb: 3,
          position: "sticky",
          top: 62,
          maxHeight: "75vh",
          overflowY: "scroll"
        }}
      >
        <CardHeader title="Added Contacts" />
        <Stack spacing={2} sx={{ p: 3 }}>
          {contacts.map((c, i) => (
            <ProfilePreviewCard
              key={c?.id}
              contact={c}
              updateCurrentContact={updateCurrentContact(i)}
              open={openIndexes.has(i)}
              index={i}
              onAccordianClick={() => onAccordianClick(i)}
            />
          ))}
        </Stack>
      </Card>
    </>
  );
}
