import { ReactNode, createContext, useState, Dispatch, SetStateAction, useEffect, useMemo } from "react";
import {
  Company,
  CompanyBranch,
  Contact,
  ContactRole,
  Phone,
  Role,
  ContactRep,
  ContactAndCompanies,
  FullContact,
  MergedOrderResult
} from "../../types";
import { useImmer, Updater } from "use-immer";
import axios from "axios";
import { useNavigate, useSearchParams } from "react-router-dom";
import { uniqueId } from "lodash";
import validateEmail from "../../../utils/validateEmail";
import { WritableDraft } from "immer/dist/internal";
import { postMessageToParentWindow } from "../../../utils/iframeUtils";
import { logEvent } from "../../../utils/analyticsLogger";
import { InternalUser } from "../../../types/app";
import { ContactOrder } from "../../shared/ordersAndInvoices/types";

// ----------------------------------------------------------------------

const defaultPhoneNumbers = [
  { type: "Business", number: "" },
  { type: "Mobile", number: "" }
] as Phone[];

const defaultContactRoles = [
  {
    phoneNumbers: defaultPhoneNumbers
  }
];

const defaultContact = {
  contactRoles: defaultContactRoles,
  contactReps: [{ contactRepTypeId: 1, key: uniqueId() }] as unknown as ContactRep[]
} as FullContact;

interface IContactContext {
  editMode: boolean;
  selectedRole: Role | null;
  setSelectedRole: Dispatch<SetStateAction<Role | null>>;
  selectedProfession: Role | null;
  setSelectedProfession: Dispatch<SetStateAction<Role | null>>;
  isRoleSameAsProfession: boolean;
  setIsRoleSameAsProfession: Dispatch<SetStateAction<boolean>>;
  handleIsRoleSameAsProfession: (isRoleSameAsProfession: boolean) => void;
  selectedCompany: Company | null;
  setSelectedCompany: Dispatch<SetStateAction<Company | null>>;
  selectedCompanyBranch: CompanyBranch | null;
  setSelectedCompanyBranch: Dispatch<SetStateAction<CompanyBranch | null>>;
  contact: FullContact;
  company: Company;
  setCompany: Updater<Company>;
  setContact: Updater<FullContact>;
  submit: () => void;
  submitting: false;
  merging: false;
  submissionAttempted: boolean;
  setSubmissionAttempted: Dispatch<SetStateAction<boolean>>;
  submitBranchWithoutCompany: (company: Company) => Promise<CompanyBranch>;
  setSubmitting: Dispatch<SetStateAction<boolean>>;
  handleContactChange: (key: keyof Contact, value: any) => void;
  handleCompanyBranchChange: (value: any) => void;
  handleRoleTypeChange: (value: Role | null) => void;
  handleProfessionTypeChange: (value: Role | null) => void;
  handleContactRoleChange: (key: keyof ContactRole, value: any) => void;
  handlePhoneChange: (index: number, key: keyof Phone, value: any) => void;
  newCompany: boolean;
  setNewCompany: Dispatch<SetStateAction<boolean>>;
  newBranch: boolean;
  setNewBranch: Dispatch<SetStateAction<boolean>>;
  homePhone: Phone | undefined;
  setHomePhone: Dispatch<SetStateAction<Phone | undefined>>;
  handleCompanyChange: (value: CompanyBranch) => void;
  handleSelectedCompanyChange: (value: Company | null) => void;
  attemptedNewCompanyAddress: string | null;
  setAttemptedNewCompanyAddress: Dispatch<SetStateAction<string | null>>;
  newAddressWithoutCompanyInfoMode: boolean;
  setNewAddressWithoutCompanyInfoMode: Dispatch<SetStateAction<boolean>>;
  addPhone: () => void;
  removePhone: (index: number) => void;
  noCompanyMode: boolean;
  setNoCompanyMode: Dispatch<SetStateAction<boolean>>;
  roleList: Role[];
  reset: () => void;
  formIsInvalid: boolean;
  showInvalidAlert: boolean;
  setShowInvalidAlert: Dispatch<SetStateAction<boolean>>;
  showRoleAlert: boolean;
  setAllowNoRole: Dispatch<SetStateAction<boolean>>;
  setUseDefaultCompanyBranch: Dispatch<SetStateAction<boolean>>;
  setShowRoleAlert: Dispatch<SetStateAction<boolean>>;
  showMissingCompanyBranchAlert: boolean;
  setShowMissingCompanyBranchAlert: Dispatch<SetStateAction<boolean>>;
  isBranchWithoutCompany: boolean;
  focusOnCompanyName: boolean;
  setFocusOnCompanyName: Dispatch<SetStateAction<boolean>>;
  addCompanyNameMode: boolean;
  setAddCompanyNameMode: Dispatch<SetStateAction<boolean>>;
  setIsBranchWithoutCompany: Dispatch<SetStateAction<boolean>>;
  mergingContacts: FullContact[];
  setMergingContacts: Dispatch<SetStateAction<FullContact[]>>;
  resetMergingContacts: () => void;
  handleMergeAndDelete: (lookupCode: string, id: number) => Promise<void>;
  getAndSetSelectedCompany: (companyId: number, branchId: number) => Promise<void>;
  isDiscrepancyMode: boolean;
  handleAccountRepChange: (rep: InternalUser | undefined, repTypeId: number) => void;
  getAllContactInfo: (id: number) => Promise<FullContact>;
  showOrdersModal: boolean;
  setShowOrdersModal: Dispatch<SetStateAction<boolean>>;
  mergedOrderResult: ContactOrder[];
  getOrdersToMerge: (lookupCode: string) => Promise<void>;
  getOrdersForLookupCode: (lookupCode: string) => Promise<any[]>;
  deactivateContact: () => Promise<void>;
}

const AddEditContactContext = createContext<IContactContext>({} as IContactContext);

type ContactProviderProps = {
  children: ReactNode;
};

function AddEditContactProvider({ children }: ContactProviderProps) {
  const navigate = useNavigate();
  let [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get("id");
  const lookupcode = searchParams.get("lookupcode");
  const [editMode, setEditMode] = useState<boolean>(window.location.pathname.includes("edit-contact"));
  const [mergeMode, setMergeMode] = useState<boolean>(window.location.pathname.includes("contactsMerge"));
  const [contact, setContact] = useImmer<FullContact>(defaultContact);
  const [isBranchWithoutCompany, setIsBranchWithoutCompany] = useState<boolean>(false);
  const [noCompanyMode, setNoCompanyMode] = useState(false);
  const [selectedRole, setSelectedRole] = useState<Role | null>(null);
  const [selectedProfession, setSelectedProfession] = useState<Role | null>(null);
  const [selectedCompany, setSelectedCompany] = useState<Company | null>(null);
  const [selectedCompanyBranch, setSelectedCompanyBranch] = useState<CompanyBranch | null>(null);
  const [isDuplicate, setIsDuplicate] = useState<boolean>(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [merging, setMerging] = useState<boolean>(false);
  const [submissionAttempted, setSubmissionAttempted] = useState<boolean>(false);
  const [newCompany, setNewCompany] = useState<boolean>(false);
  const [newBranch, setNewBranch] = useState<boolean>(false);
  const [attemptedNewCompanyAddress, setAttemptedNewCompanyAddress] = useState<string | null>(null);
  const [company, setCompany] = useImmer<Company>({
    branches: [
      {
        address1: attemptedNewCompanyAddress,
        isHeadquarters: true
      } as CompanyBranch
    ] as CompanyBranch[]
  } as Company);
  const [newAddressWithoutCompanyInfoMode, setNewAddressWithoutCompanyInfoMode] = useState<boolean>(false);
  const isDiscrepancyMode = window.location.pathname.includes("contactsMerge");
  const [isRoleSameAsProfession, setIsRoleSameAsProfession] = useState<boolean>(isDiscrepancyMode ? false : true);
  const [roleList, setRoleList] = useState<Role[]>([]);
  const [showInvalidAlert, setShowInvalidAlert] = useState<boolean>(false);
  const [showRoleAlert, setShowRoleAlert] = useState<boolean>(false);
  const [showMissingCompanyBranchAlert, setShowMissingCompanyBranchAlert] = useState<boolean>(false);
  const [focusOnCompanyName, setFocusOnCompanyName] = useState<boolean>(false);
  const [addCompanyNameMode, setAddCompanyNameMode] = useState<boolean>(false);
  const [allowNoRole, setAllowNoRole] = useState<boolean>(false);
  const [useDefaultCompanyBranch, setUseDefaultCompanyBranch] = useState<boolean>(false);
  const [homePhone, setHomePhone] = useState<Phone>();
  const [mergingContacts, setMergingContacts] = useState<FullContact[]>([]);
  const getAndSetRoles = async () => {
    const { data } = await axios.get(`/api/clientphonebook/roles/search`);
    setRoleList(data);
  };
  const [showOrdersModal, setShowOrdersModal] = useState<boolean>(false);
  const [mergedOrderResult, setMergedOrderResult] = useState<ContactOrder[]>([]);
  const handleAccountRepChange = (rep: InternalUser | undefined, repTypeId: number) => {
    setContact((draft) => {
      //Delete rep if cleared
      if (!rep) {
        draft.contactReps = draft.contactReps.filter((r) => r.contactRepTypeId != repTypeId);
        return;
      }
      let existingRep = draft.contactReps.find((r) => r.contactRepTypeId === repTypeId);
      //Update or Add rep
      if (existingRep) {
        existingRep.internalUserId = rep.id;
        existingRep.internalUser = rep;
      } else {
        draft.contactReps.push({
          contactId: contact.id,
          contactRepTypeId: repTypeId,
          internalUser: rep,
          internalUserId: rep.id
        } as ContactRep);
      }
    });
  };

  const getOrdersForLookupCode: (lookupCode: string) => Promise<any[]> = async (lookupCode: string) => {
    const { data } = await axios.get(
      `/api/clientphonebook/contacts/getcontactordersbylookupcode?lookupCode=${lookupCode}`
    );

    return data;
  };

  const deactivateContact = async () => {
    await axios.post("/api/clientphonebook/contacts/deactivatecontact", {
      lookupCodeToDelete: mergingContacts[1].lookupCode,
      contactToKeep: mergingContacts[0],
      idToDelete: mergingContacts[1].id
    });
  };

  const getOrdersToMerge = async (lookupCode: string) => {
    const data = await getOrdersForLookupCode(lookupCode);

    setMergedOrderResult(data);
    // return data;
  };

  const handleMergeAndDelete = async (lookupCode: string, id: number) => {
    setMerging(true);

    const newContact = {
      ...contact,
      notes: mergingContacts[1].notes
    };
    const { data } = await axios.post("/api/clientphonebook/contacts/mergeAndDelete", {
      contactToKeep: newContact,
      lookupCodeToDelete: lookupCode,
      idToDelete: id
    });
    logEvent("Phonebook", `Merged ${contact.id}`);
    const orders = await getOrdersForLookupCode(mergingContacts[1].lookupCode);
    if (orders.length > 0) {
      setShowOrdersModal(true);
      setMergedOrderResult(orders);
    } else {
      await deactivateContact();
      navigate(`../phonebook/contact/profile?id=${contact.id}`);
      resetMergingContacts();
    }
  };

  const submit = async () => {
    setSubmissionAttempted(true);
    // ------------- Warn user of invalid form -------------
    if (formIsInvalid) {
      setShowInvalidAlert(true);
      return;
    } else if (!allowNoRole && !contact.contactRoles[0].roleId) {
      setShowRoleAlert(true);
      return;
    } else if (selectedCompany && !useDefaultCompanyBranch && !selectedCompanyBranch) {
      setShowMissingCompanyBranchAlert(true);
      return;
    }
    setSubmitting(true);

    const defaultCompanyBranchId =
      selectedCompany?.branches.find((b) => b.isHeadquarters)?.id || selectedCompany?.branches[0].id;

    let companyBranchId = useDefaultCompanyBranch ? defaultCompanyBranchId : contact.contactRoles[0].companyBranchId;

    let newCompanyId;

    // ------------- Update existing branch -------------
    var updateCompany = companyBranchId || !selectedCompanyBranch?.companyId;
    const branchPayload = structuredClone(company.branches[0]) as any;
    delete branchPayload.company;
    if (updateCompany) {
      if (newCompanyId) {
        branchPayload.companyId = newCompanyId;
      } else if (!selectedCompanyBranch?.companyId) {
        branchPayload.companyId = selectedCompany?.id;
      }
    }
    await axios.put("/api/clientphonebook/companybranch/update", branchPayload);

    // ------------- Add new orphaned branch -------------
    if (noCompanyMode && !companyBranchId) {
      const returnedBranch = await submitBranchWithoutCompany(company);
      companyBranchId = returnedBranch.id;
    }

    // ------------- Contact payload setup -------------
    const payload = structuredClone(contact);
    payload.contactReps = payload.contactReps.map((cr) => ({
      ...cr,
      internalUser: null
    }));
    payload.profession = null;
    payload.firstName = payload.firstName?.replace(/\s+/g, " ").trim();
    payload.lastName = payload.lastName?.replace(/\s+/g, " ").trim();

    payload.contactRoles.forEach((contactRole, i) => {
      if (i === 0) {
        if (companyBranchId) {
          contactRole.companyBranchId = companyBranchId;
        }
      }
      contactRole.phoneNumbers = contactRole.phoneNumbers.filter((n) => n.number);

      contactRole.phoneNumbers.forEach((n) => {
        if (n.type === "Phone") {
          n.type = "Business";
        }
      });

      const cr = contactRole as any;
      delete cr.role;
      delete cr.contact;
      delete cr.branch;
    });

    if (!payload.contactReps || !payload.contactReps[0]?.internalUserId || editMode) {
      (payload.contactReps as any) = null;
    }

    //TODO: Hack for now.
    //According to Freylicher, for now, when a new contact is added, mobile phones should be
    //set as a personal phone which therefore means it needs to go on the contact itself as the contact.PhoneNumbers.
    //Therefore, as a temporary hack, I'm removing the mobile phones from the contact roles collection,
    //and putting it on the contact itself, before posting it to the API.
    //The way it SHOULD be done is that the UI should correctly bind the phone numbers in the dropdown
    //to the correct properties. E.g. - if it's a mobile phone, it should go on the contact itself.
    //If it's a work or fax, it should go on the contact roles phone numbers.
    const mobilePhones = payload.contactRoles[0].phoneNumbers.filter((p) => p.type === "Mobile");

    payload.contactRoles[0].phoneNumbers = payload.contactRoles[0].phoneNumbers.filter((p) => p.type !== "Mobile");

    payload.phoneNumbers = mobilePhones;

    // ------------- Contact home phone setup -------------
    if (homePhone?.number) {
      payload.phoneNumbers.push(homePhone);
      const updatedContact = {
        ...contact,
        contactRoles: [
          {
            ...contact.contactRoles[0],
            roleId: null,
            role: null
          } as unknown as ContactRole
        ] as ContactRole[]
      } as FullContact;

      setContact((contact) => {
        return {
          ...contact,
          contactRoles: [
            {
              ...contact.contactRoles[0],
              roleId: null,
              role: null
            } as unknown as ContactRole
          ] as ContactRole[]
        } as FullContact;
      });
    }

    // ------------- Contact update/add requests -------------
    if (editMode) {
      await axios.put(`/api/clientphonebook/contacts/update`, payload);
      postMessageToParentWindow({ eventType: "editContact", phonebookId: id });
      reset();
      navigate(`../phonebook/contact/profile?id=${id}`);
    } else {
      const { data } = await axios.post("/api/clientphonebook/contacts/add", payload);
      postMessageToParentWindow({ eventType: "addContact", phonebookId: data });
      reset();
      navigate(`../phonebook/contact/profile?id=${data}`);
    }
  };

  const getBranchById = async (id: number) => {
    const { data } = await axios.get<CompanyBranch>(`/api/clientphonebook/companybranch/${id}`);
    return data;
  };

  const submitBranchWithoutCompany = async (company: Company) => {
    const { data } = await axios.post("/api/clientphonebook/companybranch/add", company.branches[0]);
    const returnedBranch = await getBranchById(data);
    return returnedBranch;
  };

  const handleContactChange = (key: keyof Contact, value: any) => {
    setContact((draft) => {
      (draft[key] as any) = value;
    });
  };

  const handleContactRoleChange = (key: keyof ContactRole, value: any) => {
    setContact((draft) => {
      (draft.contactRoles[0][key] as any) = value;
    });
  };

  const handleCompanyChange = (value: CompanyBranch) => {
    setSelectedCompany(value.company);
    setSelectedCompanyBranch(value);
    setContact((draft) => {
      draft.contactRoles[0].companyBranchId = value.id;
      draft.contactRoles[0].branch = value;
    });
  };

  const handleSelectedCompanyChange = (value: Company | null) => {
    var existingBranch = contact.contactRoles[0].branch;
    const isOrphanedBranch = !existingBranch?.companyId;
    if (!value) {
      setSelectedCompany(null);
      if (!isOrphanedBranch) {
        handleCompanyBranchChange(null);
      }
      return;
    }
    if (isOrphanedBranch && existingBranch) {
      value.branches = [existingBranch, ...value.branches];
      setSelectedCompany(value);
      handleCompanyBranchChange(existingBranch);
    } else {
      setSelectedCompany(value);
      const selectedBranch = value?.branches.find((b) => b.isHeadquarters) || null;
      handleCompanyBranchChange(selectedBranch);
    }
  };

  const handleCompanyBranchChange = (value: any) => {
    setSelectedCompanyBranch(value);
    setContact((draft) => {
      draft.contactRoles[0].companyBranchId = value?.id || null;
      draft.contactRoles[0].branch = value;
    });
  };

  const getAndSetSelectedCompany = async (companyId: number, branchId: number) => {
    if (!companyId) return;
    let { data } = await axios.get<Company>(`/api/clientphonebook/companies/get/${companyId}`);
    const selectedBranch = data.branches.find((b) => b.id === branchId) || null;
    setSelectedCompany(data);
    setSelectedCompanyBranch(selectedBranch);
    handleCompanyBranchChange(selectedBranch);
  };

  const setDefaultSuffix = (draft: WritableDraft<FullContact>, value: Role | null) => {
    draft.suffix = !draft.suffix && value?.name === "Attorney" ? "Esq." : draft.suffix;
  };

  const handleRoleTypeChange = (value: Role | null) => {
    if (isRoleSameAsProfession) {
      setContact((draft) => {
        draft.contactRoles[0].roleId = value?.id || null;
        draft.professionId = value?.id || null;
        draft.profession = value;
        setDefaultSuffix(draft, value);
      });
      return;
    }
    setContact((draft) => {
      draft.contactRoles[0].roleId = value?.id || null;
      setDefaultSuffix(draft, value);
    });
  };

  const handleProfessionTypeChange = (value: Role | null) => {
    setContact((draft) => {
      draft.professionId = value?.id || null;
      draft.profession = value;
      setDefaultSuffix(draft, value);
    });
  };

  const handleIsRoleSameAsProfession = (isRoleSameAsProfession: boolean) => {
    setIsRoleSameAsProfession(isRoleSameAsProfession);
    setContact((draft) => {
      draft.professionId = isRoleSameAsProfession ? contact.contactRoles[0].roleId : null;
    });
  };

  const handlePhoneChange = (index: number, key: keyof Phone, value: any) => {
    setContact((draft) => {
      (draft.contactRoles[0].phoneNumbers[index][key] as any) = value;
    });
  };

  const addPhone = () => {
    setContact((draft) => {
      const phoneNumbers = draft.contactRoles[0].phoneNumbers;
      draft.contactRoles[0].phoneNumbers.push({
        type: "Mobile",
        key: crypto.randomUUID()
      } as Phone);
    });
  };

  const removePhone = (index: number) => {
    setContact((draft) => {
      draft.contactRoles[0].phoneNumbers = draft.contactRoles[0].phoneNumbers.filter((_, i) => i != index);
    });
  };
  const getAllContactInfo = async (id: number) => {
    const { data } = await axios.get<ContactAndCompanies>(
      `/api/clientphonebook/contacts/getContactAndCompanyInfo/${id}`
    );
    const { contact: contactInfo, companies } = data;
    if (contactInfo.contactReps.length === 0) {
      contactInfo.contactReps = [{ contactRepTypeId: 1 }] as unknown as ContactRep[];
    }
    if (contactInfo.contactRoles.length === 0) {
      contactInfo.contactRoles = [
        {
          phoneNumbers: [
            {
              type: "Business",
              number: "",
              countryCode: "",
              key: crypto.randomUUID()
            } as Phone
          ]
        } as ContactRole
      ];
    }
    const noRolePhones = contactInfo.contactRoles[0].phoneNumbers.length === 0 && contactInfo.phoneNumbers.length === 0;

    const hasMobilePhones =
      contactInfo.contactRoles[0].phoneNumbers &&
      contactInfo.phoneNumbers &&
      contactInfo.phoneNumbers.some((n) => n.type === "Mobile");

    if (noRolePhones) {
      contactInfo.contactRoles[0].phoneNumbers = defaultPhoneNumbers;
    }

    if (hasMobilePhones) {
      contactInfo.contactRoles[0].phoneNumbers = [
        ...contactInfo.contactRoles[0].phoneNumbers,
        ...contactInfo.phoneNumbers.filter((n) => n.type === "Mobile")
      ];
    }
    contactInfo.phoneNumbers = contactInfo.phoneNumbers || [
      {
        type: "Business",
        number: "",
        countryCode: "",
        key: crypto.randomUUID()
      } as Phone
    ];

    return { ...data.contact, ...contactInfo } as FullContact;
  };

  const getAndSetEditContact = async () => {
    const { data } = await axios.get<ContactAndCompanies>(
      `/api/clientphonebook/contacts/getContactAndCompanyInfo/${id}`
    );
    const { contact: contactInfo, companies } = data;
    if (contactInfo.contactReps.length === 0) {
      contactInfo.contactReps = [{ contactRepTypeId: 1 }] as unknown as ContactRep[];
    }
    if (contactInfo.contactRoles.length === 0) {
      contactInfo.contactRoles = [
        {
          phoneNumbers: [
            {
              type: "Business",
              number: "",
              countryCode: "",
              key: crypto.randomUUID()
            } as Phone
          ]
        } as ContactRole
      ];
    }
    const noRolePhones = contactInfo.contactRoles[0].phoneNumbers.length === 0 && contactInfo.phoneNumbers.length === 0;

    const hasMobilePhones =
      contactInfo.contactRoles[0].phoneNumbers &&
      contactInfo.phoneNumbers &&
      contactInfo.phoneNumbers.some((n) => n.type === "Mobile");

    if (noRolePhones) {
      contactInfo.contactRoles[0].phoneNumbers = defaultPhoneNumbers;
    }

    if (hasMobilePhones) {
      contactInfo.contactRoles[0].phoneNumbers = [
        ...contactInfo.contactRoles[0].phoneNumbers,
        ...contactInfo.phoneNumbers.filter((n) => n.type === "Mobile")
      ];
    }
    contactInfo.phoneNumbers = contactInfo.phoneNumbers || [
      {
        type: "Business",
        number: "",
        countryCode: "",
        key: crypto.randomUUID()
      } as Phone
    ];
    const companyBranch = contactInfo.contactRoles[0]?.branch;
    setSelectedCompany(companies.find((c) => c.id === companyBranch?.companyId) || null);
    setSelectedCompanyBranch(companyBranch);
    setIsRoleSameAsProfession(contactInfo.contactRoles[0]?.roleId === contactInfo.professionId);
    const contactData = { ...data.contact, ...contactInfo } as FullContact;
    setContact(contactData);

    const isWithoutCompany =
      (contactInfo.contactRoles[0]?.companyBranchId && !contactInfo.contactRoles[0]?.branch?.companyId) === true;

    if (isWithoutCompany) {
      setNoCompanyMode(isWithoutCompany && !addCompanyNameMode);
      setCompany({
        branches: [contactInfo.contactRoles[0].branch]
      } as Company);
    }
  };

  const reset = () => {
    setAddCompanyNameMode(false);
    setEditMode(false);
    setNoCompanyMode(false);
    setIsBranchWithoutCompany(false);
    setContact(defaultContact);
    setSelectedRole(null);
    setSelectedProfession(null);
    setSelectedCompany(null);
    setSelectedCompanyBranch(null);
    setIsDuplicate(false);
    setSubmitting(false);
    setSubmissionAttempted(false);
    setNewCompany(false);
    setNewBranch(false);
    setAttemptedNewCompanyAddress(null);
    setCompany({ branches: [{} as CompanyBranch] } as Company);
    setNewAddressWithoutCompanyInfoMode(false);
    setIsRoleSameAsProfession(isDiscrepancyMode ? false : true);
    setShowInvalidAlert(false);
    setFocusOnCompanyName(false);
    setAllowNoRole(false);
    setShowRoleAlert(false);
    setShowMissingCompanyBranchAlert(false);
    setUseDefaultCompanyBranch(false);
  };

  let formIsInvalid = useMemo(() => {
    if (
      contact.lastName &&
      company.branches[0]?.address1 &&
      company.branches[0]?.zip &&
      (!contact.contactRoles[0].businessEmail || // Allow empty emails
        validateEmail(contact.contactRoles[0].businessEmail)) // Validate if not empty
    ) {
      return false;
    } else if (
      contact.lastName &&
      (!contact.contactRoles[0].businessEmail || // Allow empty emails
        validateEmail(contact.contactRoles[0].businessEmail)) // Validate if not empty
    ) {
      return false;
    } else if (
      contact.lastName &&
      contact.contactRoles[0].phoneNumbers.some((n) => n.number) &&
      (!contact.contactRoles[0].businessEmail || // Allow empty emails
        validateEmail(contact.contactRoles[0].businessEmail)) // Validate if not empty
    ) {
      return false;
    } else {
      return true;
    }
  }, [
    contact.lastName,
    selectedCompanyBranch,
    selectedCompany,
    contact.contactRoles[0].businessEmail,
    contact.contactRoles[0].phoneNumbers,
    company.branches[0]?.address1,
    company.branches[0]?.zip,
    contact.contactRoles[0].roleId
  ]);

  useEffect(() => {
    setEditMode(window.location.pathname.includes("edit-contact"));
    setMergeMode(window.location.pathname.includes("contactsMerge"));
  }, [window.location.pathname]);

  useEffect(() => {
    if (editMode && id) {
      getAndSetEditContact();
      // } else if (mergeMode) {
      //   setUpMergingContacts(mergingContacts[0]?.id, mergingContacts[1]?.id);
    } else {
      setContact(defaultContact);
    }
  }, [id, editMode, mergeMode]);

  useEffect(() => {
    reset();
    setEditMode(window.location.pathname.includes("edit"));
  }, [id]);

  useEffect(() => {
    if (allowNoRole) {
      submit();
    }
  }, [allowNoRole]);

  useEffect(() => {
    if (useDefaultCompanyBranch) {
      submit();
    }
  }, [useDefaultCompanyBranch]);

  useEffect(() => {
    if (mergingContacts.length) {
      getOrdersToMerge(mergingContacts[1].lookupCode);
    }
  }, [mergingContacts]);

  const resetMergingContacts = () => {
    setMergingContacts([]);
    setContact(defaultContact);
    setMerging(false);
  };

  useEffect(() => {
    getAndSetRoles();
    resetMergingContacts();
  }, []);

  return (
    <AddEditContactContext.Provider
      value={
        {
          selectedRole,
          setSelectedRole,
          selectedProfession,
          setSelectedProfession,
          isRoleSameAsProfession,
          setIsRoleSameAsProfession,
          handleIsRoleSameAsProfession,
          selectedCompany,
          setSelectedCompany,
          selectedCompanyBranch,
          setSelectedCompanyBranch,
          contact,
          setContact,
          company,
          setCompany,
          submit,
          submitting,
          merging,
          submitBranchWithoutCompany,
          setSubmitting,
          handleContactChange,
          handleCompanyBranchChange,
          handleRoleTypeChange,
          handleProfessionTypeChange,
          handleContactRoleChange,
          handlePhoneChange,
          newCompany,
          setNewCompany,
          newBranch,
          setNewBranch,
          handleCompanyChange,
          handleSelectedCompanyChange,
          attemptedNewCompanyAddress,
          setAttemptedNewCompanyAddress,
          newAddressWithoutCompanyInfoMode,
          setNewAddressWithoutCompanyInfoMode,
          addPhone,
          removePhone,
          editMode,
          noCompanyMode,
          setNoCompanyMode,
          roleList,
          reset,
          homePhone,
          setHomePhone,
          formIsInvalid,
          submissionAttempted,
          setSubmissionAttempted,
          showInvalidAlert,
          setShowInvalidAlert,
          showMissingCompanyBranchAlert,
          setShowMissingCompanyBranchAlert,
          isBranchWithoutCompany,
          focusOnCompanyName,
          setFocusOnCompanyName,
          addCompanyNameMode,
          setAddCompanyNameMode,
          setIsBranchWithoutCompany,
          showRoleAlert,
          setAllowNoRole,
          setShowRoleAlert,
          setUseDefaultCompanyBranch,
          mergingContacts,
          setMergingContacts,
          resetMergingContacts,
          handleMergeAndDelete,
          getAndSetSelectedCompany,
          isDiscrepancyMode,
          handleAccountRepChange,
          getAllContactInfo,
          showOrdersModal,
          setShowOrdersModal,
          mergedOrderResult,
          getOrdersToMerge,
          getOrdersForLookupCode,
          deactivateContact
        } as IContactContext
      }
    >
      {children}
    </AddEditContactContext.Provider>
  );
}

export { AddEditContactProvider, AddEditContactContext };
