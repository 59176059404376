import { Box, Card, CardContent, IconButton, Skeleton, Stack } from "@mui/material";
import Iconify from "../../../minimals/components/iconify";
import { useState } from "react";
import { StyledBadge } from "../../shared/StyledBadge";
import OrderNotesModal from "../notes/OrderNotesModal";
import { TaskGroupEntity } from "../types";
import SpreadAmount from "./SpreadAmount";
import Properties from "./Properties";
import Order from "./Order";

const TaskOrderInfo = ({
  taskGroupEntity,
  refreshTaskGroupEntity
}: {
  taskGroupEntity: TaskGroupEntity | null;
  refreshTaskGroupEntity: () => Promise<void>;
}) => {
  const [addOrderNoteMode, setAddOrderNoteMode] = useState<boolean>(false);

  const isFlips = taskGroupEntity?.applicationId === 1;

  return (
    <>
      <Stack direction="row" marginBottom={3}>
        <Card sx={{ width: "100%", padding: 0 }}>
          <CardContent>
            <Stack direction="row" justifyContent="space-between">
              <Stack direction="row" spacing={14}>
                <Order />
                <Properties />
                {isFlips && <SpreadAmount taskGroupEntity={taskGroupEntity} />}
              </Stack>
              <Stack direction="row" spacing={1}>
                {taskGroupEntity && (
                  <Box>
                    <IconButton onClick={() => setAddOrderNoteMode(true)}>
                      <StyledBadge badgeContent={taskGroupEntity?.notes?.length} color="error">
                        <Iconify icon="eva:file-add-outline" />
                      </StyledBadge>
                    </IconButton>
                  </Box>
                )}
                {!taskGroupEntity && <Skeleton variant="circular" width={36} height={36} />}
              </Stack>
            </Stack>
          </CardContent>
        </Card>
      </Stack>
      {addOrderNoteMode && (
        <OrderNotesModal
          taskGroupEntity={taskGroupEntity}
          closeModal={() => setAddOrderNoteMode(false)}
          refresh={refreshTaskGroupEntity}
        />
      )}
    </>
  );
};

export default TaskOrderInfo;
