// @mui
import { Button, Grid } from "@mui/material";
import { Dispatch, SetStateAction, useContext } from "react";
import { WritableDraft } from "immer/dist/internal";
import produce from "immer";
import { Address, Party } from "../types/Party";
import { OrderContext } from "../../contexts/OrderDataContext";
import JointParty from "./JointParty";
import SingleParty from "./SingleParty";
import OrganizationParty from "./OrganizationParty";
import { PropertyInfo } from "../types/Property";
import Iconify from "../../../minimals/components/iconify";
import PhonebookContactAutocomplete from "../../phonebook/phonebookAutocomplete/PhonebookContactAutocomplete";
import { Contact } from "../types/Contact";

// ----------------------------------------------------------------------

const getEmptyParty = (): Party => {
  return {
    isJoint: true,
    sameAsPropertyAddress: true,
    type: "Individual",
    firstName1: null,
    middleName1: null,
    lastName1: null,
    gender1: "Male",
    firstName2: null,
    middleName2: null,
    lastName2: null,
    gender2: "Female",
    organizationType: null,
    organizationName: null,
    organizationPayeeName: null,
    organizationPayeeSameAsName: false,
    relationshipType: null,
    address: {} as Address,
    vesting: null
  };
};

export default function Parties(prop: {
  isSeller: boolean;
  sameAsPropertyAddress: boolean;
  parties: Party[];
  setParties: React.Dispatch<React.SetStateAction<Party[]>>;
  partyAttorney: Contact | null;
  setPartyAttorney: Dispatch<SetStateAction<Contact | null>>;
  partyParalegal: Contact | null;
  setPartyParalegal: Dispatch<SetStateAction<Contact | null>>;
}) {
  const {
    selectedClientPointOfContactType,
    transactionType,
    errorMode,
    errors,
    setBuyersParalegalEmail,
    setSellersParalegalEmail,
    searchForPatriotsAndBlackList,
    getFullNameForBlacklistAndPatriotSearching
  } = useContext(OrderContext);

  const {
    isSeller,
    sameAsPropertyAddress,
    parties,
    setParties,
    partyAttorney,
    setPartyAttorney,
    partyParalegal,
    setPartyParalegal
  } = prop;
  const getPartyComponent = (party: Party, index: number, multiple: boolean) => {
    if (party.type === "Individual") {
      if (party.isJoint) {
        return (
          <JointParty
            party={party}
            sameAsPropertyAddress={sameAsPropertyAddress}
            parties={parties}
            setType={setType}
            index={index}
            setIsJoint={setIsJoint}
            setFirstName1={setFirstName1}
            setMiddleName1={setMiddleName1}
            setLastName1={setLastName1}
            setGender1={setGender1}
            setFirstName2={setFirstName2}
            setMiddleName2={setMiddleName2}
            setLastName2={setLastName2}
            setGender2={setGender2}
            setRelationshipType={setRelationshipType}
            multiple={multiple}
            removeParty={() => removeParty(index)}
            isSeller={isSeller}
            setContactInfo={setContactInfo}
            setFullContactInfo={setFullContactInfo}
            setAddressSameAsProperty={setAddressSameAsProperty}
          />
        );
      } else {
        return (
          <SingleParty
            party={party}
            sameAsPropertyAddress={sameAsPropertyAddress}
            parties={parties}
            setType={setType}
            index={index}
            setIsJoint={setIsJoint}
            setFirstName1={setFirstName1}
            setMiddleName1={setMiddleName1}
            setLastName1={setLastName1}
            setGender1={setGender1}
            multiple={multiple}
            removeParty={() => removeParty(index)}
            isSeller={isSeller}
            setContactInfo={setContactInfo}
            setFullContactInfo={setFullContactInfo}
            setAddressSameAsProperty={setAddressSameAsProperty}
          />
        );
      }
    } else {
      return (
        <OrganizationParty
          party={party}
          sameAsPropertyAddress={sameAsPropertyAddress}
          parties={parties}
          setType={setType}
          index={index}
          setOrganizationName={setOrganizationName}
          setOrganizationType={setOrganizationType}
          setOrganizationPayeeName={setOrganizationPayeeName}
          setOrganizationPayeeSameAsName={setOrganizationPayeeSameAsName}
          multiple={multiple}
          removeParty={() => removeParty(index)}
          isSeller={isSeller}
          setContactInfo={setContactInfo}
          setFullContactInfo={setFullContactInfo}
          setVesting={setVesting}
          setAddressSameAsProperty={setAddressSameAsProperty}
        />
      );
    }
  };

  const setPartyProp = (index: number, cb: (loan: WritableDraft<Party>) => void) => {
    const newParties = produce(parties, (draft) => {
      const party = draft[index];
      cb(party);
    });
    setParties(newParties);
  };

  const setContactInfo = (property: string, value: string | PropertyInfo[], index: number) => {
    const newParties = produce(parties, (draft) => {
      const party = draft[index];
      // @ts-ignore
      party.address[property] = value;
    });
    setParties(newParties);
  };

  const setFullContactInfo = (value: PropertyInfo, index: number) => {
    const newParties = produce(parties, (draft) => {
      const party = draft[index];
      // @ts-ignore
      party.address.propertyInfo = value;
    });
    setParties(newParties);
  };

  const setType = (type: "Individual" | "Organization", index: number) =>
    setPartyProp(index, (party) => (party.type = type));

  const setIsJoint = (isJoint: boolean, index: number) => setPartyProp(index, (party) => (party.isJoint = isJoint));

  const setFirstName1 = (firstName1: string, index: number) =>
    setPartyProp(index, (party) => (party.firstName1 = firstName1));

  const setMiddleName1 = (middleName1: string, index: number) =>
    setPartyProp(index, (party) => (party.middleName1 = middleName1));

  const setLastName1 = (lastName1: string, index: number) =>
    setPartyProp(index, (party) => (party.lastName1 = lastName1));

  const setGender1 = (gender1: "Male" | "Female", index: number) =>
    setPartyProp(index, (party) => (party.gender1 = gender1));

  const setFirstName2 = (firstName2: string, index: number) =>
    setPartyProp(index, (party) => (party.firstName2 = firstName2));

  const setMiddleName2 = (middleName2: string, index: number) =>
    setPartyProp(index, (party) => (party.middleName2 = middleName2));

  const setLastName2 = (lastName2: string, index: number) =>
    setPartyProp(index, (party) => (party.lastName2 = lastName2));

  const setGender2 = (gender2: "Male" | "Female", index: number) =>
    setPartyProp(index, (party) => (party.gender2 = gender2));

  const setOrganizationName = (organizationName: string, index: number) =>
    setPartyProp(index, (party) => (party.organizationName = organizationName));

  const setOrganizationType = (organizationType: string | null, index: number) =>
    setPartyProp(index, (party) => (party.organizationType = organizationType));

  const setRelationshipType = (relationshipType: string | null, index: number) =>
    setPartyProp(index, (party) => (party.relationshipType = relationshipType));

  const setOrganizationPayeeName = (organizationPayeeName: string, index: number) =>
    setPartyProp(index, (party) => (party.organizationPayeeName = organizationPayeeName));

  const setOrganizationPayeeSameAsName = (organizationPayeeSameAsName: boolean, index: number) =>
    setPartyProp(index, (party) => (party.organizationPayeeSameAsName = organizationPayeeSameAsName));

  const setAddressSameAsProperty = (sameAsPropertyAddress: boolean, index: number) => {
    setPartyProp(index, (party) => (party.sameAsPropertyAddress = sameAsPropertyAddress));
  };

  const setVesting = (vesting: string | null, index: number) =>
    setPartyProp(index, (party) => (party.vesting = vesting));

  const multiple = parties.length > 1;

  const removeParty = (index: number) => {
    var partiesCopy = [...parties];
    partiesCopy.splice(index, 1);
    setParties(partiesCopy);
  };

  const getIsAttorneyRequired = () => {
    if (isSeller && selectedClientPointOfContactType?.name === "Sellers Attorney") {
      return true;
    } else {
      return false;
    }
  };

  const isAttorneyRequired = getIsAttorneyRequired();

  return (
    <>
      <Grid container spacing={3} alignItems="center" id={isSeller ? "sellersAttorney" : "buyersAttorney"}>
        <Grid item md={6} xs={12}>
          <PhonebookContactAutocomplete
            contact={partyAttorney}
            setContact={async (attorney) => {
              if (typeof attorney === "string" || !attorney) {
                setPartyAttorney(null);
              } else {
                setPartyAttorney(attorney);
                if (typeof attorney === "object") {
                  const { firstName = "", middleName = "", lastName = "", company, payeeName } = attorney;
                  searchForPatriotsAndBlackList([
                    getFullNameForBlacklistAndPatriotSearching({ firstName, middleName, lastName }),
                    company,
                    payeeName
                  ]);
                }
              }
            }}
            textboxLabel={
              isSeller
                ? "Seller's Attorney"
                : transactionType === "Refinance" || transactionType === "LastOwnerSearch"
                ? "Borrower's Attorney"
                : "Buyer's Attorney"
            }
            error={isAttorneyRequired && errorMode && isSeller && errors.sellersAttorney}
            required={isAttorneyRequired}
            textboxPlaceholder={""}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <PhonebookContactAutocomplete
            freeSolo
            contact={partyParalegal}
            onInputChange={(value) => {
              isSeller ? setSellersParalegalEmail(value || null) : setBuyersParalegalEmail(value || null);
            }}
            setContact={(attorney) => {
              if (typeof attorney === "object") {
                setPartyParalegal(attorney);
                isSeller
                  ? setSellersParalegalEmail(attorney?.email || null)
                  : setBuyersParalegalEmail(attorney?.email || null);
              } else {
                setPartyParalegal(null);
                isSeller ? setSellersParalegalEmail(null) : setBuyersParalegalEmail(null);
              }
            }}
            textboxLabel="Attorney's Paralegal"
          />
        </Grid>
        {parties.map((s: Party, i: number) => getPartyComponent(s, i, multiple))}
        <Grid item xs={12}>
          <Button
            size="small"
            startIcon={<Iconify icon={"eva:plus-fill"} />}
            onClick={() => setParties([...parties, getEmptyParty()])}
          >
            Add a{" "}
            {isSeller
              ? "Seller"
              : transactionType === "Refinance" || transactionType === "LastOwnerSearch"
              ? "Borrower"
              : "Buyer"}
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
