import axios from "axios";
import { useState, useEffect } from "react";
import { CityData, PropertyInfo } from "../../orderEntry/types/Property";
import { useContacts } from "../../contexts/ContactsContext";
import { Box, Typography } from "@mui/material";
import DebouncedAutoComplete from "../../components/DebouncedAutoComplete";
import { searchEstated, toTitleCase } from "../../utils/property";
import OrderEntryCreateContact from "../../phonebook/phonebookAutocomplete/OrderEntryCreateContact";

type ContactProperty = {
  propertyInfo: PropertyInfo;
  propertyList: PropertyInfo[];
  cityData: CityData;
  zipData: CityData;
  cityList: CityData[];
  zipList: CityData[];
  searchedText: string | null;
};

export default function ContactAddress() {
  const { setAllAddressFields, editContact } = useContacts();
  const [loading, setLoading] = useState(false);
  const [addressOpen, setAddressOpen] = useState(false);
  const [property, setProperty] = useState<ContactProperty | null>();

  const searchSelectedAddress = async (text: string, selected: string) => {
    if (!text) return;
    let { data } = await axios({
      method: "get",
      url: `/api/property/searchSelectedAddress?searchText=${encodeURIComponent(text)}&selected=${selected}`
    });
    data = data.suggestions.map(
      (d: any) =>
        ({
          entries: d.entries,
          aptNo: d.secondary,
          address: d.street_line,
          formattedAddress: d.formattedStreet_line,
          city: d.city,
          state: d.state,
          zipCode: d.zipcode,
          parcelIds: [""],
          lots: [""]
        } as PropertyInfo)
    );
    setProperty({ ...property, propertyList: data } as ContactProperty);
  };

  const searchFullAddressDetails = async (
    propertyInfo: string | PropertyInfo | null | undefined,
    propertyList: PropertyInfo[],
    searchedText: string | null
  ) => {
    if (propertyInfo == null || typeof propertyInfo !== "object") return;
    const estatedData = await searchEstated(propertyInfo);
    const updateObj = {
      ...property,
      propertyList,
      searchedText,
      propertyInfo: !estatedData
        ? propertyInfo
        : {
            ...propertyInfo,
            city: toTitleCase(estatedData.address.city.toLowerCase())
          }
    };

    setProperty(updateObj as ContactProperty);
  };

  const onPropertySearchTextChanged = async (text: string) => {
    setLoading(true);
    if (text != null && text != "") {
      let { data } = await axios({
        method: "get",
        url: `/api/property/search?searchText=${encodeURIComponent(text)}`
      });
      data = data.suggestions.map(
        (d: any) =>
          ({
            entries: d.entries,
            aptNo: d.secondary,
            address: d.street_line,
            formattedAddress: d.formattedStreet_line,
            city: d.city,
            state: d.state,
            zipCode: d.zipcode,
            parcelIds: [""],
            lots: [""]
          } as PropertyInfo)
      );
      {
        setProperty({ ...property, propertyList: data } as ContactProperty);
      }
    } else {
      setProperty({ ...property, propertyList: [] } as ContactProperty);
    }
    setLoading(false);
  };

  useEffect(() => {
    property?.propertyInfo && setAllAddressFields(property?.propertyInfo);
  }, [property?.propertyInfo]);

  return (
    <DebouncedAutoComplete
      open={addressOpen}
      required
      freeSolo={true}
      value={property?.propertyInfo}
      options={property?.propertyList || []}
      getOptionsLabel={(option) => option?.formattedAddress || property?.searchedText || ""}
      loadingProp={loading}
      noOptionsText={
        property?.searchedText && (
          <OrderEntryCreateContact
            inputText={property?.searchedText}
            text="Properties"
            onClick={() => setAddressOpen(false)}
          />
        )
      }
      renderOption={(props, option, { selected }) => (
        <li
          {...props}
          key={`${option?.formattedAddress}${option?.entries}${option?.aptNo}${option?.city}${option?.state}${option?.zipCode}`}
        >
          <div style={{ width: "100%" }}>
            <div>
              <Typography sx={{ display: "inline-block" }} variant="subtitle2">
                {option?.entries && option?.entries > 2
                  ? `${option?.formattedAddress} ${option?.aptNo ? " " + option?.aptNo : ""} (${
                      option?.entries
                    } more entries) ${option?.city}, ${option?.state} ${option?.zipCode}`
                  : `${option?.formattedAddress}${option?.aptNo ? " " + option?.aptNo : ""}, ${option?.city}, ${
                      option?.state
                    } ${option?.zipCode}`}
              </Typography>
            </div>
          </div>
        </li>
      )}
      onSelectedOptionChanged={(e, value) => {
        setAddressOpen(false);
        if (typeof value === "object" && value?.entries && value?.entries > 1) {
          searchSelectedAddress(
            property?.searchedText?.replace(" ", "+") || "",
            `${value.address}+${value.aptNo ? value.aptNo + "+" : ""}(${value.entries})+${value.city}+${value.state}+${
              value.zipCode
            }`
          );
        } else {
          searchFullAddressDetails(value, property?.propertyList || [], property?.searchedText || "");
        }
      }}
      textboxLabel="Personal Street Address"
      textboxPlaceholder="Start typing an address..."
      onDebouncedTextChanged={(text) => onPropertySearchTextChanged(text)}
      additionalOnChange={(text) => {
        setAddressOpen(true);
        editContact("address1", text);
      }}
      onBlur={() => {
        setAddressOpen(false);
      }}
      onClick={() => setAddressOpen(true)}
    />
  );
}
