export interface StatusType {
  id: number;
  description: string;
  statusType: "NotYetDone" | "Pending" | "Error" | "Completed" | "NA";
}

export interface StatusesObj {
  [key: number | string]: string;
}

export interface BasicTask {
  id: number;
  applicationId: number;
  orderNumber: string;
  description: string;
  dateCreated: Date;
  createdBy: string;
  isDeleted?: boolean;
  assignedToName: string;
  assignedToEmail: string;
  taskItemStatusId: number;
  documents: TaskDocument[];
  parentTaskId?: number;
  parentTask?: BasicTask;
  subTasks: BasicTask[];
  notes: TaskItemNote[];
  statusUpdateHistory: TaskItemStatusUpdateHistory[];
  contactCode: string;
  selectRequirementNumber: string;
  selectRequirementCode: string;
  originalSelectRequirementText: string;
  documentRequiredToMarkAsCompleted: boolean;
  quickNote: string;
  sortIndex: number;
}

interface TaskDocument {
  id: number;
  basicTaskId: number;
  momentumDocumentId: number;
  uploadedDate: Date;
  uploadedBy: string;
}

interface TaskItemNote {
  id: number;
  basicTaskId: number;
  text: string;
  dateCreated: Date;
  createdByUser: string;
}

export interface TaskItemStatusUpdateHistory {
  id: number;
  basicTaskId: number;
  taskItemStatusId: number;
  username: string;
  dateTime: Date;
  eventType: number;
  note: string;
}

export interface EventType {
  id: number;
  description: string;
}

export interface TaskNote {
  id: number;
  basicTaskId: number;
  text: string;
  dateCreated: Date;
  createdByUser: string;
}

export interface OrderNote {
  id: number;
  basicTaskId: number;
  text: string;
  dateCreated: Date;
  createdByUser: string;
  isCritical: boolean;
}

export interface DocumentCategory {
  name: string;
  sortOrder: number | null;
  subCategories: DocumentSubCategory[];
}

export interface DocumentSubCategory {
  id: string;
  categoryName: string;
  name: string;
  sortOrder: number | null;
  descriptions: string[];
}

export interface ConcatenatedDocumentTypes {
  fullPath: string;
  subcategoryId: string;
  description: string;
}

export interface Document {
  id: number;
  basicTaskId: number;
  momentumDocumentId: number;
  uploadedDate: string;
  uploadedBy: string;
  name: string;
}

export interface FlipChecklistTaskInfo {
  id: string;
  description: string;
  status: string;
  dueDate: Date | null;
  assignedTo: string;
  completedBy: string | null;
}

export interface TaskGroupEntity {
  id: number;
  applicationId: number;
  orderNumber: string;
  markedAsCompletedBy: string;
  markedAsCompletedAt?: Date | null;
  dateCreated: Date;
  createdBy: string;
  tasks: BasicTask[];
  notes: OrderNote[];
}

export interface TaskGroupEntityWithStatusInfo extends TaskGroupEntity {
  checklistTaskStatus: ChecklistTaskStatus;
  selectStatus: string;
  approvedBy: string;
  approvedAt?: Date;
  passedAudit?: boolean;
  settlementDate?: Date;
  intermediatePrice?: number;
  endBuyerPrice?: number;
}

export enum ChecklistTaskStatus {
  InReview = "In Review",
  ReviewCompleted = "Review Completed",
  Approved = "Approved",
  Rejected = "Rejected",
  UnknownStatus = "Unknown Status"
}

export const statusColorMap: Record<ChecklistTaskStatus, string> = {
  [ChecklistTaskStatus.InReview]: "warning.main",
  [ChecklistTaskStatus.ReviewCompleted]: "#000",
  [ChecklistTaskStatus.Approved]: "success.main",
  [ChecklistTaskStatus.Rejected]: "error.main",
  [ChecklistTaskStatus.UnknownStatus]: "#c1c1c1"
};

export interface SortConfig {
  key: string;
  direction: "asc" | "desc";
  accessor: (task: BasicTask, statusTypes: StatusType[]) => string | Date | undefined;
}

export interface SelectedTasks {
  [key: number | string]: boolean;
}

export type FlipSpreadInfo = {
  intermediatePrice?: number;
  endBuyerPrice?: number;
  percentage?: number;
};
