import { Container, Grid, Stack, Typography } from "@mui/material";
import { useContext } from "react";
import { useSettingsContext } from "../../../contexts/SettingsContext";
import { Helmet } from "react-helmet-async";
import GoBackButton from "../../shared/create/GoBackButton";
import { AddEditCompanyContext } from "./AddEditCompanyContext";
import CompanyInformation from "./CompanyForm";
import MembersToRelocate from "./MembersToRelocate";

export default function AddEditCompany() {
  const { editMode, showMembersToMoveModal } = useContext(AddEditCompanyContext);
  const { themeStretch } = useSettingsContext();

  return (
    <>
      <Helmet>
        <title>{editMode ? "Edit Company" : "Create a Company"} | TitleQ</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : "lg"}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" marginBottom={4}>
          <Typography variant="h4">{editMode ? "Edit Company" : "Create a Company"}</Typography>
          <GoBackButton />
        </Stack>
        <Grid container marginTop={5} columnSpacing={3}>
          <Grid item xs={12} md={8}>
            <CompanyInformation />
          </Grid>
          <Grid item xs={4}></Grid>
        </Grid>
        {showMembersToMoveModal && <MembersToRelocate />}
      </Container>
    </>
  );
}
