import { Card, CardHeader, Link, Stack, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useContacts } from "../../contexts/ContactsContext";
import Iconify from "../../../minimals/components/iconify";

const IconStyle = styled(Iconify)(({ theme }) => ({
  width: 20,
  height: 20,
  marginTop: 1,
  flexShrink: 0,
  marginRight: theme.spacing(2)
}));

export default function ProfileCard() {
  const { currentContact, newContact, isEditMode } = useContacts();
  const contact = isEditMode ? currentContact : newContact;
  return (
    <Card sx={{ marginBottom: 2 }}>
      <CardHeader title={contact?.name} />

      <Stack spacing={2} sx={{ p: 3 }}>
        {contact?.role && (
          <Typography variant="body2">
            {contact?.role} at <span style={{ fontWeight: 600 }}> {contact?.company}</span>
          </Typography>
        )}

        <Stack direction="row">
          <IconStyle icon={"eva:pin-fill"} />
          <Typography sx={{ fontWeight: 400 }}>
            <Link component="span" variant="subtitle2" color="text.primary">
              {contact?.company}
              <br />
              {contact?.address1}
              <br />
              {contact?.address2 && <div>{contact?.address2}</div>}
              {contact?.city} {contact?.state} {contact?.zip}
            </Link>
          </Typography>
        </Stack>

        {contact?.email && (
          <Stack direction="row">
            <IconStyle icon={"eva:email-fill"} />
            <Typography variant="body2">{contact?.email}</Typography>
          </Stack>
        )}

        {contact?.phone && (
          <Stack direction="row">
            <IconStyle icon={"carbon:phone-filled"} />
            <Typography variant="body2">
              <Link component="span" color="text.primary">
                {contact?.phone}
              </Link>
            </Typography>
          </Stack>
        )}

        {contact?.cell && (
          <Stack direction="row">
            <IconStyle icon={"carbon:phone-filled"} />
            <Typography variant="body2">
              <Link component="span" color="text.primary">
                {contact?.cell}
              </Link>
            </Typography>
          </Stack>
        )}
      </Stack>
    </Card>
  );
}
