import { Dispatch, ReactNode, SetStateAction, createContext, useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  Company,
  CompanyBranch,
  EditCompanyData,
  EditCompanyDataBranchesToAdd,
  EditCompanyDataBranchesToEdit,
  EditCompanyDataCompany,
  MemberData
} from "../../types";
import { Updater, useImmer } from "use-immer";
import { postMessageToParentWindow } from "../../../utils/iframeUtils";
import { useSnackbar } from "notistack";

// ----------------------------------------------------------------------

interface ICompanyContext {
  company: Company;
  setCompany: Updater<Company>;
  deletedBranches: CompanyBranch[];
  setDeletedBranches: Updater<CompanyBranch[]>;
  members: MemberData[];
  membersToMove: MemberData[];
  selectedBranchIndexToMoveMember: number | null;
  setSelectedBranchIndexToMoveMember: Dispatch<SetStateAction<number | null>>;
  setShowMembersToMoveModal: Dispatch<SetStateAction<boolean>>;
  submitting: boolean;
  editMode: boolean;
  showMembersToMoveModal: boolean;
  submit: (isRedirectAfterSubmit: boolean) => void;
  editPayloadData: EditCompanyData;
  setEditPayloadData: Updater<EditCompanyData>;
}

const AddEditCompanyContext = createContext<ICompanyContext>({} as ICompanyContext);

type ProviderProps = {
  children: ReactNode;
};

function AddEditCompanyProvider({ children }: ProviderProps) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const searchParams = new URLSearchParams(location.search);
  const id = searchParams.get("id");
  const [editPayloadData, setEditPayloadData] = useImmer<EditCompanyData>({
    company: {
      id
    } as unknown as EditCompanyDataCompany
  } as EditCompanyData);
  const [company, setCompany] = useImmer<Company>({} as Company);
  const [editMode, setEditMode] = useState<boolean>(window.location.pathname.includes("edit-company"));
  const [deletedBranches, setDeletedBranches] = useImmer<CompanyBranch[]>([]);
  const [members, setMembers] = useState<MemberData[]>([]);
  const [membersToMove, setMembersToMove] = useState<MemberData[]>([]);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [showMembersToMoveModal, setShowMembersToMoveModal] = useState<boolean>(false);
  const [selectedBranchIndexToMoveMember, setSelectedBranchIndexToMoveMember] = useState<number | null>(null);

  const getAndSetCompany = () => {
    if (!id) {
      setCompany({} as Company);
      return;
    }
    axios.get(`/api/clientphonebook/companies/get/${id}`).then((response) => setCompany(response.data));
  };

  const getAndSetMembers = async () => {
    const { data } = await axios.get(`/api/clientphonebook/companies/getContacts?id=${id}`);
    setMembers(data);
  };

  const reset = () => {
    setShowMembersToMoveModal(false);
    setSelectedBranchIndexToMoveMember(null);
    setDeletedBranches([]);
    setSubmitting(false);
  };

  const setAndCheckMembersToMoved = () => {
    const filteredMembersToMove = members.filter((m) => deletedBranches.some((b) => b.id === m.branchId));
    setMembersToMove(filteredMembersToMove);
    return filteredMembersToMove.length > 0;
  };

  const validateHeadquarters = (company: Company) => {
    if (!company?.branches) return true; ////If there are no branches you don't have to have a headquarters

    const amounfOfHeadquarterBranches = company.branches.filter((b) => b.isHeadquarters).length;
    if (amounfOfHeadquarterBranches == 0) {
      enqueueSnackbar("Must select a headquarters branch", {
        variant: "error",
        autoHideDuration: 3500,
        anchorOrigin: { vertical: "bottom", horizontal: "center" }
      });
      return false;
    }
    if (amounfOfHeadquarterBranches > 1) {
      enqueueSnackbar("Only one headquarters branch can be selected", {
        variant: "error",
        autoHideDuration: 3500,
        anchorOrigin: { vertical: "bottom", horizontal: "center" }
      });
      return false;
    }
    return true;
  };

  const submit = async (isRedirectAfterSubmit: boolean) => {
    const isValidHeadquarters = validateHeadquarters(company);
    if (!isValidHeadquarters) return false;

    const userMovedMembers = company.branches.some((b) => b.isReplacementBranch);
    if (!userMovedMembers) {
      const membersNeedToBeMoved = setAndCheckMembersToMoved();
      if (membersNeedToBeMoved) {
        setShowMembersToMoveModal(true);
        return;
      }
    }
    setSubmitting(true);
    const payload = {
      company: {
        id: company.id,
        name: company.name,
        website: company.website,
        companyTypeId: company.companyTypeId,
        dynamicsGuid: company.dynamicsGuid,
        primaryContactId: company.primaryContactId,
        tempLookupCodesFromImport: company.tempLookupCodesFromImport,
        phone: company.phone,
        phoneCountryCode: company.phoneCountryCode,
        fax: company.fax,
        faxCountryCode: company.faxCountryCode,
        email: company.email,
        emailPolicyTo: company.emailPolicyTo,
        lookupCode: company.lookupCode
      } as EditCompanyDataCompany,
      branchesToAdd: company.branches
        .filter((b) => !b.id)
        .map(
          (b) =>
            ({
              isReplacementBranch: b.isReplacementBranch,
              companyId: company.id,
              isHeadquarters: b.isHeadquarters,
              email: b.email,
              phone: b.phone,
              phoneCountryCode: b.phoneCountryCode,
              phoneExtension: b.phoneExtension,
              fax: b.fax,
              faxCountryCode: b.faxCountryCode,
              faxExtension: b.faxExtension,
              address1: b.address1,
              address2: b.address2,
              city: b.city,
              state: b.state,
              zip: b.zip,
              country: b.country,
              tempLookupCodesFromImport: null,
              website: b.website
            } as EditCompanyDataBranchesToAdd)
        ),
      branchesToEdit: company.branches
        .filter((b) => b.id)
        .map(
          (b) =>
            ({
              id: b.id,
              isReplacementBranch: b.isReplacementBranch,
              companyId: company.id,
              isHeadquarters: b.isHeadquarters,
              email: b.email,
              phone: b.phone,
              phoneCountryCode: b.phoneCountryCode,
              phoneExtension: b.phoneExtension,
              fax: b.fax,
              faxCountryCode: b.faxCountryCode,
              faxExtension: b.faxExtension,
              address1: b.address1,
              address2: b.address2,
              city: b.city,
              state: b.state,
              zip: b.zip,
              country: b.country,
              tempLookupCodesFromImport: null,
              website: b.website
            } as EditCompanyDataBranchesToEdit)
        ),
      branchesToDelete: deletedBranches.map((db) => db.id)
    } as EditCompanyData;

    const { data } = await axios.put(`/api/clientphonebook/companies/update`, payload);
    postMessageToParentWindow({ eventType: "editCompany", phonebookId: company.id });
    enqueueSnackbar("Saved!");
    resetPageData();

    if (isRedirectAfterSubmit) {
      setCompany({} as Company);
      navigate(`../phonebook/company/profile?id=${company.id}`);
    }

    return true;
  };

  const resetPageData = () => {
    reset();
    getAndSetCompany();
    getAndSetMembers();
  };

  useEffect(() => {
    setEditMode(window.location.pathname.includes("edit-company"));
  }, [window.location.pathname]);

  useEffect(() => {
    if (editMode && id) {
      resetPageData();
    }
  }, [id, editMode]);

  return (
    <AddEditCompanyContext.Provider
      value={{
        company,
        setCompany,
        submitting,
        submit,
        editMode,
        deletedBranches,
        setDeletedBranches,
        members,
        membersToMove,
        showMembersToMoveModal,
        selectedBranchIndexToMoveMember,
        setSelectedBranchIndexToMoveMember,
        setShowMembersToMoveModal,
        editPayloadData,
        setEditPayloadData
      }}
    >
      {children}
    </AddEditCompanyContext.Provider>
  );
}

export { AddEditCompanyProvider, AddEditCompanyContext };
