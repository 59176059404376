import { useContext } from "react";
import { LoadingButton } from "@mui/lab";
import { Grid, LinearProgress, Stack, Tooltip } from "@mui/material";
import { OrderContext } from "../contexts/OrderDataContext";
import ProofOfOrderPlacement from "./ProofOfOrderPlacement";
import Transaction from "./Transaction";
import Contacts from "./additionalContacts/Contacts";
import FormCard from "./FormCard";
import MatchedOrders from "./matchedOrders/MatchedOrders";
import Notes from "./Notes";
import OrderType from "./OrderType";
import PointOfContact from "./PointOfContact";
import PriorPolicy from "./PriorPolicy";
import Parties from "./sellerAndBuyer/Parties";
import TitleAgentAndUnderwriter from "./TitleAgentAndUnderwriter";
// import PropertiesOld from './PropertiesOld'
import OrderPageContainer from "./OrderPageContainer";
import OrderSuccess from "./OrderSuccess";
import ProfilePreview from "./ProfilePreview";
// import Properties from './Properties';
import PropertiesNew from "./PropertiesNew";
import BuyerSellerPrincipal from "./BuyerSellerPrincipal";
import OrderWarningsBanner from "./OrderWarningsBanner";
import OrderWarningsModal from "./OrderWarningsModal";
// ----------------------------------------------------------------------

export default function CreateOrder() {
  const {
    loading,
    orderNumber,
    identifier,
    submitOrder,
    sellers,
    setSellers,
    sellersAttorney,
    setSellersAttorney,
    buyers,
    setBuyers,
    buyersAttorney,
    setBuyersAttorney,
    transactionType,
    sellersParalegal,
    setSellersParalegal,
    buyersParalegal,
    setBuyersParalegal,
    notes,
    setNotes,
    properties,
    didFileUploadSucceed,
    currentBlacklistSearchRequestsCount
  } = useContext(OrderContext);

  const orderType = properties[0]?.propertyInfo?.propertyUse?.toUpperCase();

  return (
    <OrderPageContainer title="Order Placement" activeStep={0}>
      <>
        <Grid container spacing={5}>
          <Grid item xs={12} md={8}>
            <Stack spacing={3}>
              {/* <FormCard title='4. Property'>
                <PropertiesNew />
              </FormCard> */}

              <FormCard title="1. Order Type">
                <OrderType />
              </FormCard>
              <FormCard title="2. Proof of Order Placement">
                <ProofOfOrderPlacement />
              </FormCard>
              <FormCard title="3a. Related Files">
                <PriorPolicy />
              </FormCard>

              <FormCard title="4. Property">
                <PropertiesNew />
              </FormCard>
              {(transactionType === "Purchase" || transactionType === "LastOwnerSearch") && (
                <FormCard title="5. Seller">
                  <Parties
                    isSeller
                    sameAsPropertyAddress
                    parties={sellers}
                    setParties={setSellers}
                    partyAttorney={sellersAttorney}
                    setPartyAttorney={setSellersAttorney}
                    partyParalegal={sellersParalegal}
                    setPartyParalegal={setSellersParalegal}
                  />
                </FormCard>
              )}
              <FormCard
                title={
                  transactionType === "Refinance" || transactionType === "LastOwnerSearch"
                    ? "5. Borrower"
                    : transactionType === "Purchase"
                    ? "6. Buyer"
                    : "5. Buyer"
                }
              >
                <Parties
                  isSeller={false}
                  sameAsPropertyAddress={true}
                  parties={buyers}
                  setParties={setBuyers}
                  partyAttorney={buyersAttorney}
                  setPartyAttorney={setBuyersAttorney}
                  partyParalegal={buyersParalegal}
                  setPartyParalegal={setBuyersParalegal}
                />
              </FormCard>
              {transactionType != "LastOwnerSearch" && (
                <FormCard title={(transactionType === "Purchase" ? "7. " : "6. ") + "Transaction"}>
                  <Transaction />
                </FormCard>
              )}
              <FormCard
                title={
                  (transactionType === "Purchase" ? "8. " : transactionType === "LastOwnerSearch" ? "6." : "7. ") +
                  "Title agent & Underwriter"
                }
              >
                <TitleAgentAndUnderwriter />
              </FormCard>
              <FormCard
                title={
                  (transactionType === "Purchase" ? "9. " : transactionType === "LastOwnerSearch" ? "7." : "8. ") +
                  (transactionType === "Refinance" ? "Borrower Principal" : "Buyer & Seller Principal")
                }
              >
                <BuyerSellerPrincipal />
              </FormCard>

              <FormCard
                title={
                  (transactionType === "Purchase" ? "10. " : transactionType === "LastOwnerSearch" ? "8." : "9. ") +
                  "Marketing Source"
                }
              >
                <PointOfContact />
              </FormCard>
              <FormCard
                title={
                  (transactionType === "Purchase" ? "11. " : transactionType === "LastOwnerSearch" ? "9." : "10. ") +
                  "Additional Contacts"
                }
              >
                <Contacts />
              </FormCard>
              <FormCard
                title={
                  (transactionType === "Purchase" ? "12. " : transactionType === "LastOwnerSearch" ? "10." : "11. ") +
                  "Notes"
                }
              >
                <Notes notes={notes} onChange={setNotes} />
              </FormCard>
            </Stack>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              position: "sticky",
              top: 62,
              maxHeight: "90vh",
              overflowY: "scroll"
            }}
          >
            <MatchedOrders />
            <ProfilePreview />
          </Grid>
          <Grid item xs={12} md={8}>
            <OrderWarningsBanner />
            <OrderWarningsModal />
            <Stack alignItems="flex-end">
              {!orderNumber && (
                <Tooltip
                  title={
                    currentBlacklistSearchRequestsCount > 0 ? "Cannot submit while blacklist search is ongoing." : ""
                  }
                  arrow
                  disableHoverListener={!(currentBlacklistSearchRequestsCount > 0)}
                >
                  <span>
                    <LoadingButton
                      onClick={submitOrder}
                      variant="contained"
                      disabled={loading || currentBlacklistSearchRequestsCount > 0}
                    >
                      {!loading ? "Submit Order" : "Submitting..."}
                    </LoadingButton>
                  </span>
                </Tooltip>
              )}
            </Stack>
            {loading && <LinearProgress sx={{ marginTop: 4 }} />}
            {orderNumber && (
              <OrderSuccess
                orderNumber={orderNumber}
                identifier={identifier}
                orderType={orderType}
                didFileUploadSucceed={didFileUploadSucceed}
              />
            )}
          </Grid>
        </Grid>
      </>
    </OrderPageContainer>
  );
}
