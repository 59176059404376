import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Container,
  FormControlLabel,
  IconButton,
  Link,
  MenuItem,
  Select,
  Stack,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { ClientOrder, Contact } from "../types/ClientOrder";
import axios from "axios";
import { useSettingsContext } from "../../contexts/SettingsContext";
import Iconify from "../../../minimals/components/iconify";

export default function ClientOnFileTable() {
  const { themeStretch } = useSettingsContext();
  const [clientOrders, setClientOrders] = useState<ClientOrder[]>([]);
  const defaultTo = new Date();
  const defaultFrom = new Date();
  defaultFrom.setDate(defaultTo.getDate() - 7);
  const [showMissingClients, setShowMissingClients] = useState(true);
  const [dateRange, setDateRange] = useState("Past Week");
  const [isSaving, setIsSaving] = useState(false);
  const [savingRowNumbers, setSavingRowNumbers] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [hasMoreResults, setHasMoreResults] = useState(true);
  const [loading, setLoading] = useState(false);

  const [from, setFrom] = useState<string>(defaultFrom.toISOString().split("T")[0]);
  const [to, setTo] = useState<string>(defaultTo.toISOString().split("T")[0]);
  const [pageNumber, setPageNumber] = useState(1);
  const pageSize = 100;

  const setUpClientOrders = async (
    from: string,
    to: string,
    pageNumber: number,
    pageSize: number,
    orderNumber: string | null
  ): Promise<void> => {
    const params = orderNumber ? { orderNumber, pageNumber, pageSize } : { from, to, pageNumber, pageSize };
    setLoading(true);
    const { data } = await axios.get<ClientOrder[]>("/api/orders/getClientOrders", {
      params
    });
    setClientOrders(data);
    setHasMoreResults(data.length === pageSize);
    setLoading(false);
  };

  useEffect(() => {
    setUpClientOrders(from, to, pageNumber, pageSize, "");
  }, []);

  const handleToggleChange = () => {
    setShowMissingClients((prev) => !prev);
  };

  const handleDateRangeChange = (event: any) => {
    setDateRange(event.target.value);
    const newTo = new Date();
    const newFrom = new Date();

    switch (event.target.value) {
      case "Past Week":
        newFrom.setDate(newTo.getDate() - 7);
        break;
      case "Past Two Weeks":
        newFrom.setDate(newTo.getDate() - 14);
        break;
      case "Past Month":
        newFrom.setMonth(newTo.getMonth() - 1);
        break;
      case "Past 3 Months":
        newFrom.setMonth(newTo.getMonth() - 3);
        break;
      case "Past 6 Months":
        newFrom.setMonth(newTo.getMonth() - 6);
        break;
      default:
        break;
    }

    const updatedFrom = newFrom.toISOString().split("T")[0];
    const updatedTo = newTo.toISOString().split("T")[0];
    setFrom(updatedFrom);
    setTo(updatedTo);
    setUpClientOrders(updatedFrom, updatedTo, pageNumber, pageSize, "");
  };

  const handleContactChange = (number: string, contacts: Contact[]) => {
    const updatedOrders = clientOrders.map((o) => {
      if (o.number === number) {
        return {
          ...o,
          primaryContactLookupCodes: contacts.map((c) => c.lookupCode)
        };
      } else {
        return o;
      }
    });
    setClientOrders(updatedOrders);
    setSavingRowNumbers((numbers) => [...numbers, number]);
  };

  const findPrimaryContact = (contacts: Contact[], primaryContactLookupCodes: string[]) => {
    return contacts.filter((contact) => primaryContactLookupCodes?.find((c) => c === contact.lookupCode));
  };

  const setPrimaryContactOnOrder = async (clientOrder: ClientOrder) => {
    setIsSaving(true);
    setSavingRowNumbers((numbers) => [...numbers, clientOrder.number]);
    const payload = {
      orderNumber: clientOrder.number,
      clientLookupCodes: clientOrder.primaryContactLookupCodes
    };
    await axios.post<ClientOrder[]>("/api/orders/setClientOnOrder", payload);
    setIsSaving(false);
    setSavingRowNumbers((numbers) => numbers.filter((n) => n !== clientOrder.number));
  };

  const filteredOrders = showMissingClients
    ? clientOrders.filter((o) => !o.primaryContactLookupCodes || savingRowNumbers.some((n) => n === o.number))
    : clientOrders.filter((o) => o.primaryContactLookupCodes || savingRowNumbers.some((n) => n !== o.number));

  const handleSearch = () => {
    setUpClientOrders(from, to, 1, pageSize, searchTerm);
    resetPagination();
  };

  const handleNextPage = () => {
    if (hasMoreResults) {
      setPageNumber((prevPage) => prevPage + 1);
      setUpClientOrders(from, to, pageNumber + 1, pageSize, searchTerm);
    }
  };

  const handlePreviousPage = () => {
    setPageNumber((prevPage) => (prevPage > 1 ? prevPage - 1 : 1));
    setUpClientOrders(from, to, pageNumber - 1, pageSize, searchTerm);
  };

  const resetPagination = () => {
    setPageNumber(1);
  };

  return (
    <Container maxWidth={themeStretch ? false : "lg"}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: "10px"
        }}
      >
        <Select value={dateRange} onChange={handleDateRangeChange}>
          <MenuItem value="Past Week">Past Week</MenuItem>
          <MenuItem value="Past Two Weeks">Past Two Weeks</MenuItem>
          <MenuItem value="Past Month">Past Month</MenuItem>
          <MenuItem value="Past 3 Months">Past 3 Months</MenuItem>
          <MenuItem value="Past 6 Months">Past 6 Months</MenuItem>
        </Select>
        <Stack direction="row" spacing={1} alignItems="center">
          <TextField
            label="Order Number"
            variant="outlined"
            value={searchTerm}
            onChange={(e) => {
              setSearchTerm(e.target.value);
              if (e.target.value === "") {
                setUpClientOrders(from, to, pageNumber, pageSize, "");
              }
            }}
          />
          <Box>
            <Button variant="contained" color="primary" size="small" onClick={handleSearch}>
              Search
            </Button>
          </Box>
        </Stack>
        <FormControlLabel
          control={
            <Switch checked={showMissingClients} onChange={handleToggleChange} name="missingClients" color="primary" />
          }
          label="Missing Clients"
        />
      </div>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>File Number</TableCell>
              <TableCell>Contact</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
                <TableCell colSpan={3} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : (
              filteredOrders.map((row, index) => {
                const isSavingRow = savingRowNumbers.some((n) => n === row.number);
                return (
                  <TableRow key={row.number}>
                    <TableCell width={150}>
                      <Link href={`https://internal.mcres.com/new-home/${row.number}`} target="_blank">
                        {row.number}
                      </Link>
                    </TableCell>
                    <TableCell width={600}>
                      <Autocomplete
                        multiple
                        fullWidth
                        size="medium"
                        options={row.contacts}
                        getOptionLabel={(option) =>
                          `${option.contactType} - ${option.name} ${`${option.isMarketingSource ? "⭐" : ""}`}`
                        }
                        renderInput={(params) => <TextField {...params} label="Select Contact" />}
                        onChange={(event, value) => handleContactChange(row.number, value)}
                        defaultValue={findPrimaryContact(row.contacts, row?.primaryContactLookupCodes)}
                      />
                      {/* <Stack direction="row" alignItems="center" spacing={1}>
                        <Autocomplete
                          multiple
                          fullWidth
                          size="medium"
                          options={row.contacts}
                          getOptionLabel={(option) =>
                            `${option.contactType} - ${option.name} ${`${option.isMarketingSource ? "⭐" : ""}`}`
                          }
                          renderInput={(params) => <TextField {...params} label="Select Contact" />}
                          onChange={(event, value) => handleContactChange(row.number, value)}
                          defaultValue={findPrimaryContact(row.contacts, row?.primaryContactLookupCodes)}
                        />
                        <Box>
                          <IconButton>
                            <Iconify icon="eva:plus-fill" />
                          </IconButton>
                        </Box>
                      </Stack> */}
                    </TableCell>
                    <TableCell>
                      {isSavingRow && (
                        <Button
                          variant="contained"
                          color="primary"
                          onClick={() => setPrimaryContactOnOrder(row)}
                          sx={{ marginRight: 1 }}
                        >
                          {isSaving && isSavingRow ? "Saving" : "Save"}
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                );
              })
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {!loading && (
        <Box display="flex" justifyContent="space-between" mt={2}>
          <Button variant="contained" color="primary" disabled={pageNumber === 1} onClick={handlePreviousPage}>
            Previous
          </Button>
          <span>Page {pageNumber}</span>
          <Button variant="contained" color="primary" onClick={handleNextPage} disabled={!hasMoreResults}>
            Next
          </Button>
        </Box>
      )}
    </Container>
  );
}
