import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography
} from "@mui/material";
import { useContext } from "react";
import { AddEditCompanyContext } from "./AddEditCompanyContext";

export default function MembersToRelocate() {
  const {
    company,
    setCompany,
    deletedBranches,
    membersToMove,
    showMembersToMoveModal,
    submit,
    submitting,
    setShowMembersToMoveModal
  } = useContext(AddEditCompanyContext);

  const branches = company.branches?.filter((b) => deletedBranches.some((db) => db.id === b.id) === false);

  const cancel = () => {
    //reset property isReplacementBranch of branches to all be false;
    setCompany((draft) => {
      draft.branches.forEach((b, index) => (b.isReplacementBranch = false));
    });

    setShowMembersToMoveModal(false);
  };

  return (
    <Dialog open={showMembersToMoveModal} fullWidth>
      <DialogContent sx={{ padding: 4 }}>
        <Typography variant="h6" color="primary" marginBottom={3}>
          These members will be moved:
        </Typography>
        <Table sx={{ width: 300 }}>
          <TableBody>
            {membersToMove.map((m) => (
              <TableRow sx={{ padding: 1 }}>
                <TableCell sx={{ padding: 0.5, paddingLeft: 2 }}>
                  <Typography variant="subtitle2">{m.name}</Typography>
                </TableCell>
                <TableCell sx={{ padding: 0.5, paddingLeft: 2 }}>
                  <Typography variant="body2">{m.role}</Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <Typography variant="h6" color="primary" marginBottom={3} marginTop={3}>
          Choose a destination:
        </Typography>
        <Stack>
          {branches?.map((b, i) => (
            <Stack
              key={b.id}
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              sx={
                b.isReplacementBranch
                  ? {
                      borderStyle: "dashed",
                      borderColor: "primary.dark",
                      borderRadius: 1,
                      backgroundColor: "primary.lighter"
                    }
                  : null
              }
              padding={2}
            >
              <Box>
                <Typography variant="body2">
                  {b.city}, {b.state}
                </Typography>
                <Typography variant="caption" display="block" color="text.disabled">
                  {b.address1} {b.address2}
                </Typography>
                <Typography variant="caption" display="block" color="text.disabled">
                  {b.city}, {b.state}, {b.zip}
                </Typography>
              </Box>
              <Button
                onClick={() =>
                  setCompany((draft) => {
                    draft.branches.forEach((b, index) => (b.isReplacementBranch = index === i ? true : false));
                  })
                }
                variant="contained"
                color="primary"
                size="small"
                sx={
                  b.isReplacementBranch
                    ? {
                        backgroundColor: "primary.dark"
                      }
                    : null
                }
              >
                Move here
              </Button>
            </Stack>
          ))}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" color="inherit" onClick={cancel}>
          Cancel
        </Button>
        <Button
          variant="contained"
          disabled={company.branches.every((b) => !b.isReplacementBranch) || submitting}
          onClick={() => submit(false)}
        >
          {submitting ? "Saving..." : "Save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
