import { Button, Card, CardContent, Grid, Stack } from "@mui/material";
import { useContext } from "react";
import { AddEditCompanyContext } from "./AddEditCompanyContext";
import BranchInformation from "./BranchInformation";
import { useNavigate } from "react-router-dom";
import { CompanyBranch } from "../../types";
import Iconify from "../../../../minimals/components/iconify/Iconify";
import { logEvent } from "../../../utils/analyticsLogger";
import { useSettingsContext } from "../../../contexts/SettingsContext";
import MainCompanyForm from "./MainCompanyForm";

export default function CompanyInformation() {
  const { company, setCompany, submit, submitting, editMode } = useContext(AddEditCompanyContext);
  const { isAppInIFrame } = useSettingsContext();
  const navigate = useNavigate();

  const addBranch = () => {
    setCompany((draft) => {
      draft.branches.push({
        companyId: company.id,
        isHeadquarters: false
      } as CompanyBranch);
    });
  };

  return (
    <Card>
      <CardContent>
        <Grid container columnSpacing={2} rowSpacing={3}>
          <MainCompanyForm company={company} setCompany={setCompany} />
        </Grid>
        <Stack spacing={3} marginTop={3}>
          {company?.branches?.map((branch, index) => (
            <BranchInformation company={company} branch={branch} index={index} />
          ))}
        </Stack>
        <Button startIcon={<Iconify icon="eva:plus-fill" />} sx={{ marginTop: 2 }} onClick={addBranch}>
          Add Additional Location
        </Button>
        <Stack direction="row" justifyContent="flex-end" spacing={2} marginTop={3}>
          <Button
            color="inherit"
            variant="contained"
            onClick={() => {
              navigate(editMode ? `../phonebook/company/profile?id=${company.id}` : "../phonebook/contacts/companies");
            }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              submit(true);
              logEvent("Phonebook", `${editMode ? "Edit new company" : "Add new company"}`);
            }}
            disabled={submitting}
          >
            {submitting ? "Saving..." : !isAppInIFrame ? "Save" : "Save & Add to Order"}
          </Button>
        </Stack>
      </CardContent>
    </Card>
  );
}
