import { Alert, LoadingButton } from "@mui/lab";
import { useContacts } from "../../contexts/ContactsContext";

export default function ClientLoaderButton({
  onNextClick,
  submitAll = false,
  submitButtonText
}: {
  onNextClick: () => void;
  submitAll?: boolean;
  submitButtonText?: string;
}) {
  const { isEditMode, isLoading } = useContacts();
  const nextButtonText = !submitAll ? "Next" : submitButtonText ? submitButtonText : "Submit";

  return (
    <div>
      <LoadingButton onClick={onNextClick} variant="contained" disabled={isLoading ? true : false}>
        {!isLoading ? (isEditMode ? "Save Changes" : nextButtonText) : "Submitting..."}
      </LoadingButton>
    </div>
  );
}
