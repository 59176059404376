import { Box, Button, Container, Grid, Stack, Typography } from "@mui/material";
import { Helmet } from "react-helmet-async";
import { useImmer } from "use-immer";
import { useSettingsContext } from "../../contexts/SettingsContext";
import MainCompanyForm from "../company/addEditCompany/MainCompanyForm";
import GoBackButton from "../shared/create/GoBackButton";
import { Company } from "../types";
import { useEffect, useMemo, useState } from "react";
import axios from "axios";
import Branches from "../contact/company/Branches";
import CompanyInfoCard from "./CompanyInfoCard";
import { logEvent } from "../../utils/analyticsLogger";
import { useNavigate } from "react-router";

export default function MergeCompanies() {
  const { themeStretch } = useSettingsContext();
  const searchParams = new URLSearchParams(location.search);
  const companyIdsToMerge = useMemo(() => searchParams.getAll("ids"), [location.search]);

  const [company, setCompany] = useImmer<Company>({} as Company);
  const [companies, setCompanies] = useImmer<Company[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const { data: companyIdOfMergedToCompany } = await axios.post<number>(`/api/clientphonebook/companies/merge`, {
      companies,
      updatesToCompany: company
    });
    setIsSubmitting(false);

    if (companyIdOfMergedToCompany) {
      navigate(`../phonebook/company/profile?id=${companyIdOfMergedToCompany}`);
    }
  };

  useEffect(() => {
    if (companyIdsToMerge?.length) {
      getAndSetCompanies(companyIdsToMerge);
    }
  }, [companyIdsToMerge]);

  const getAndSetCompanies = async (ids: string[]) => {
    setIsLoading(true);
    const queryParams = ids.map((id) => `ids=${id}`).join("&");
    const { data } = await axios.get(`/api/clientphonebook/companies/getByIds?${queryParams}`);
    setCompanies(data);
    setIsLoading(false);
  };

  if (companyIdsToMerge.length < 2) {
    return <>Must have at least 2 companies to merge</>;
  }

  return (
    <>
      <Helmet>
        <title> Merge Companies | TitleQ</title>
      </Helmet>
      <Container maxWidth={themeStretch ? false : "lg"}>
        <Stack direction="row" justifyContent="space-between" alignItems="center" marginBottom={4}>
          <Typography variant="h4">Merge Companies</Typography>
          <GoBackButton />
        </Stack>
        <Grid container marginTop={5} columnSpacing={3}>
          <Grid item xs={12} md={8}>
            <MainCompanyForm company={company} setCompany={setCompany} />
            <Box marginBottom={4} marginTop={2}>
              <Grid container spacing={2}>
                {companies.map((company) => (
                  <Grid item xs={4} key={company.id}>
                    <CompanyInfoCard company={company} />
                  </Grid>
                ))}
              </Grid>
            </Box>
            <Box marginBottom={4} marginTop={2}>
              <Grid container spacing={2}>
                {companies.map((company) => (
                  <Grid item xs={4} key={company.id}>
                    <Branches company={company} />
                  </Grid>
                ))}
              </Grid>
            </Box>

            <Stack direction="row" justifyContent="flex-end" spacing={2} marginTop={3}>
              <Button
                color="inherit"
                variant="contained"
                onClick={() => {
                  navigate(-1);
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  handleSubmit();
                  logEvent("Phonebook", "Merge companies");
                }}
                disabled={isSubmitting}
              >
                {isSubmitting ? "Saving..." : "Save"}
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={12} md={4}></Grid>
        </Grid>
      </Container>
    </>
  );
}
