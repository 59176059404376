import axios from "axios";
import qs from "qs";
import React, { Dispatch, SetStateAction, useContext, useEffect, useMemo, useState } from "react";
import { DraftFunction, Updater, useImmer } from "use-immer";
import { BlackListResult, PatriotAndBlacklistSearchResult } from "../components/BlacklistNameSearch/types";
import { MatchedOldOrder } from "../orderEntry/matchedOrders/MatchedOldOrder";
import { MatchedOrder, MatchedOrderRequest } from "../orderEntry/matchedOrders/MatchedOrder";
import { AutoFilled } from "../orderEntry/types/AutoFilled";
import { ClientType } from "../orderEntry/types/ClientType";
import { Contact } from "../orderEntry/types/Contact";
import { ContactType } from "../orderEntry/types/ContactType";
import { Errors } from "../orderEntry/types/Errors";
import { Loan } from "../orderEntry/types/Loan";
import { Address, Party } from "../orderEntry/types/Party";
import { PriorPolicyUnderwriter } from "../orderEntry/types/PriorPolicyUnderwriter";
import { CityData, Property } from "../orderEntry/types/Property";
import { PropertyUse } from "../orderEntry/types/PropertyUse";
import { RelatedOrder } from "../orderEntry/types/RelatedOrder";
import { SettlementAgent } from "../orderEntry/types/SettlementAgent";
import { SettlementStatementType } from "../orderEntry/types/SettlementStatementType";
import { TitleCompany } from "../orderEntry/types/TitleCompany";
import { TransactionType } from "../orderEntry/types/TransactionType";
import { Underwriter } from "../orderEntry/types/Underwriter";
import { convertInternalUserToContact, removeMiddleInitial } from "../orderEntry/utils/helpers";
import { FileWithType, MSGExtractFileWithType } from "../shared/types/FileWithType";
import { InternalUser } from "../types/app";
import { logEvent } from "../utils/analyticsLogger";
import { isDevelopment } from "../utils/envChecker";
import { msgAttachmentToBase64, stripBase64LeadingChars, toBase64 } from "../utils/helpers";
import isBlank from "../utils/isBlank";
import { getDefaultPropertyInfoObj, isNJorNY } from "../utils/property";
import { validateZipcode } from "../utils/validation";
import { ApiInterceptorContext } from "./ApiInterceptorContext";
import { UserContext } from "./UserContext";

type OrderData = {
  loading: boolean;
  isRush: boolean;
  isConfidential: boolean;
  projectName: string | null;
  orderFiles: FileWithType[];
  selectedClient: Contact | null;
  selectedTeam: string | null;
  salesSuffix: string;
  selectedMarketingRep1: InternalUser | null;
  selectedMarketingRep2: InternalUser | null;
  selectedMarketingRep3: InternalUser | null;
  selectedFunder: InternalUser | null;
  selectedAccountRep: InternalUser | null;
  selectedClientPointOfContactType: ClientType | null;
  loans: Loan[];
  purchasePrice: number | null;
  orderNumber: string | undefined;
  identifier: string | undefined;
  transactionType: TransactionType | undefined;
  productTypeList: string[];
  selectedProductType: string | null;
  settlementStatementType: SettlementStatementType | undefined;
  propertyUse: PropertyUse | null;
  properties: Property[];
  errorMessage: string | null;
  relatedOrders: RelatedOrder[];
  priorPolicyFiles: File[];
  priorPolicyAmount: number | null;
  priorPolicyDate: Date | null;
  priorPolicyUnderwriterList: PriorPolicyUnderwriter[];
  underwriterList: Underwriter[];
  titleCompanyList: TitleCompany[];
  settlementAgentList: SettlementAgent[];
  selectedPriorPolicyUnderwriter: PriorPolicyUnderwriter | null;
  selectedUnderwriter: Underwriter | null;
  selectedTitleCompany: TitleCompany | null;
  settlementDate: Date | null;
  selectedSettlementAgent: SettlementAgent | null;
  matchedOrders: MatchedOrder[];
  matchedOldOrders: MatchedOldOrder[];
  matchedOrdersIsLoading: boolean;
  notes: string | null;
  relationshipTypeList: string[];
  vestingList: string[];
  contactTypeList: ContactType[];
  sellers: Party[];
  sellersAttorney: Contact | null;
  sellersParalegal: Contact | null;
  buyers: Party[];
  buyersAttorney: Contact | null;
  buyersParalegal: Contact | null;
  additionalContacts: Contact[];
  submissionAttempted: boolean;
  errorMode: boolean;
  errors: Errors;
  autoFilled: AutoFilled;
  totalRelatedOrdersCharLength: number;
  totalCharCount: number;
  orderPrincipal: Contact | null;
  orderType: string | null;
  selectedBuyerPrincipals: Contact[];
  dealOwner: string | null;
  setLoading: Dispatch<SetStateAction<boolean>>;
  setIsRush: Dispatch<SetStateAction<boolean>>;
  setIsConfidential: Dispatch<SetStateAction<boolean>>;
  setProjectName: React.Dispatch<React.SetStateAction<string | null>>;
  setOrderFiles: Dispatch<SetStateAction<FileWithType[]>>;
  setSelectedTeam: Dispatch<SetStateAction<string | null>>;
  setSalesSuffix: (suffix: string) => void;
  setSelectedClient: Dispatch<SetStateAction<Contact | null>>;
  setSelectedMarketingRep1: Dispatch<SetStateAction<InternalUser | null>>;
  setSelectedMarketingRep2: Dispatch<SetStateAction<InternalUser | null>>;
  setSelectedMarketingRep3: Dispatch<SetStateAction<InternalUser | null>>;
  setSelectedFunder: Dispatch<SetStateAction<InternalUser | null>>;
  setSelectedAccountRep: React.Dispatch<React.SetStateAction<InternalUser | null>>;
  setSelectedClientPointOfContactType: Dispatch<SetStateAction<ClientType | null>>;
  setLoans: Updater<Loan[]>;
  setOrderNumber: Dispatch<SetStateAction<undefined>>;
  setIdentifier: Dispatch<SetStateAction<undefined>>;
  setTransactionType: React.Dispatch<React.SetStateAction<TransactionType>>;
  setSelectedProductType: React.Dispatch<React.SetStateAction<string | null>>;
  setSettlementStatementType: React.Dispatch<React.SetStateAction<SettlementStatementType>>;
  setPurchasePrice: React.Dispatch<React.SetStateAction<number | null>>;
  setPropertyUse: React.Dispatch<React.SetStateAction<PropertyUse | null>>;
  setProperties: React.Dispatch<React.SetStateAction<Property[]>>;
  setRelatedOrders: React.Dispatch<React.SetStateAction<RelatedOrder[]>>;
  setPriorPolicyFiles: Dispatch<SetStateAction<File[]>>;
  setPriorPolicyAmount: React.Dispatch<React.SetStateAction<number | null>>;
  setPriorPolicyDate: React.Dispatch<React.SetStateAction<Date | null>>;
  setSelectedPriorPolicyUnderwriter: Dispatch<SetStateAction<PriorPolicyUnderwriter | null>>;
  setSelectedUnderwriter: Dispatch<SetStateAction<Underwriter | null>>;
  setSelectedTitleCompany: Dispatch<SetStateAction<TitleCompany | null>>;
  setSelectedSettlementAgent: Dispatch<SetStateAction<SettlementAgent | null>>;
  setSettlementDate: Dispatch<SetStateAction<Date | null>>;
  setSettlementAgentList: Dispatch<SetStateAction<SettlementAgent[]>>;
  setVestingList: Dispatch<SetStateAction<string[]>>;
  setNotes: Dispatch<SetStateAction<string | null>>;
  setSellers: React.Dispatch<React.SetStateAction<Party[]>>;
  setSellersAttorney: Dispatch<SetStateAction<Contact | null>>;
  setSellersParalegal: Dispatch<SetStateAction<Contact | null>>;
  setSellersParalegalEmail: Dispatch<SetStateAction<string | null>>;
  setBuyers: React.Dispatch<React.SetStateAction<Party[]>>;
  setBuyersAttorney: Dispatch<SetStateAction<Contact | null>>;
  setBuyersParalegal: Dispatch<SetStateAction<Contact | null>>;
  setBuyersParalegalEmail: Dispatch<SetStateAction<string | null>>;
  setAdditionalContacts: Dispatch<SetStateAction<Contact[]>>;
  setMatchedOrders: Dispatch<SetStateAction<MatchedOrder[]>>;
  setErrorMessage: Dispatch<SetStateAction<string | null>>;
  setAutoFilled: Dispatch<SetStateAction<AutoFilled>>;
  submitOrder: () => void;
  getAndSetMatchedOrders: (request: MatchedOrderRequest) => Promise<void>;
  getAndSetMatchedOrdersByOrderNumber: (orderNumber: string) => Promise<void>;
  setOrderPrincipal: Dispatch<SetStateAction<Contact | null>>;
  getContact: (lookupCode: string) => Promise<Contact>;
  setOrderType: React.Dispatch<React.SetStateAction<string>>;
  didFileUploadSucceed: boolean;
  patriotSearchResult: string[];
  searchForPatriotsAndBlackList: (names: (string | null | undefined)[]) => Promise<void>;
  blackListSearchResults: BlackListResult[];
  setSelectedBuyerPrincipals: Dispatch<SetStateAction<Contact[]>>;
  onContactSearchTextChanged: (text: string, setContactList: Dispatch<SetStateAction<Contact[]>>) => Promise<void>;
  selectedSellerPrincipals: Contact[];
  setSelectedSellerPrincipals: Dispatch<SetStateAction<Contact[]>>;
  selectedBuyerSellerPrincipalList: Contact[];
  setSelectedBuyerSellerPrincipalList: Dispatch<SetStateAction<Contact[]>>;
  setDealOwner: React.Dispatch<React.SetStateAction<"Buyer" | "Seller" | null>>;
  isOpenWarningModal: boolean;
  setIsOpenWarningModal: React.Dispatch<React.SetStateAction<boolean>>;
  getFullNameForBlacklistAndPatriotSearching: ({
    firstName,
    middleName,
    lastName
  }: {
    firstName: string | null;
    middleName: string | null;
    lastName: string | null;
  }) => string | undefined;
  currentBlacklistSearchRequestsCount: number;
  openAsSubfile: boolean;
  setOpenAsSubfile: React.Dispatch<React.SetStateAction<boolean>>;
  subfileSuffix: string;
  setSubfileSuffix: React.Dispatch<React.SetStateAction<string>>;
};

const OrderContext = React.createContext<OrderData>({} as OrderData);

function OrderContextProvider({ children }: { children: JSX.Element }) {
  const { errorMessage, errorMode, loading, setErrorMode, setErrorMessage, setLoading } =
    useContext(ApiInterceptorContext);
  const [isRush, setIsRush] = useState<boolean>(false);
  const [isConfidential, setIsConfidential] = useState<boolean>(false);
  const [projectName, setProjectName] = useState<string | null>(null);
  const [matchedOrders, setMatchedOrders] = useState<MatchedOrder[]>([]);
  const [matchedOldOrders, setMatchedOldOrders] = useState<MatchedOldOrder[]>([]);
  const [contactTypeList, setContactTypeList] = useState<ContactType[]>([]);
  const [matchedOrdersIsLoading, setMatchedOrdersIsLoading] = useState<boolean>(false);
  const [orderFiles, setOrderFiles] = useState<FileWithType[]>([]);
  const [selectedClient, setSelectedClient] = useState<Contact | null>(null);
  const [selectedTeam, setSelectedTeam] = useState<string | null>(null);
  const [salesSuffix, setSalesSuffix] = useState("");
  const { user } = useContext(UserContext);
  const [selectedMarketingRep1, setSelectedMarketingRep1] = useState<InternalUser | null>(null);
  const [selectedMarketingRep2, setSelectedMarketingRep2] = useState<InternalUser | null>(null);
  const [selectedMarketingRep3, setSelectedMarketingRep3] = useState<InternalUser | null>(null);
  const [selectedAccountRep, setSelectedAccountRep] = useState<InternalUser | null>(null);
  const [selectedFunder, setSelectedFunder] = useState<InternalUser | null>(null);

  const [loans, setLoans] = useImmer<Loan[]>([
    {
      loanAmount: null,
      lender: { name: "TBD", isIndividual: true, lookupCode: "TBD" } as Contact,
      lendersAttorney: null,
      mortgageBroker: null,
      lendersList: [],
      lendersAttorneyList: [],
      loanNumber: "",
      isConstructionLoan: false,
      lendersBranch: null,
      lenderBranchesList: []
    }
  ]);

  const [orderNumber, setOrderNumber] = useState();
  const [identifier, setIdentifier] = useState();
  const [didFileUploadSucceed, setDidFileUploadSucceed] = useState(true);
  const [purchasePrice, setPurchasePrice] = useState<number | null>(null);
  const [productTypeList, setProductTypeList] = useState<string[]>([]);
  const [selectedProductType, setSelectedProductType] = useState<string | null>(
    "Last Owner Open Mortgages Judgments and Liens"
  );
  const [transactionType, setTransactionType] = useState<TransactionType>("Purchase");
  const [settlementStatementType, setSettlementStatementType] = useState<SettlementStatementType>("CDF");
  const [propertyUse, setPropertyUse] = useState<PropertyUse | null>(null);
  const [properties, setProperties] = useState<Property[]>([
    {
      propertyInfo: getDefaultPropertyInfoObj(),
      cityData: {} as CityData,
      zipData: {} as CityData
    }
  ]);

  const [relatedOrders, setRelatedOrders] = useState<RelatedOrder[]>([{} as RelatedOrder]);
  const [priorPolicyFiles, setPriorPolicyFiles] = useState<File[]>([]);
  const [priorPolicyAmount, setPriorPolicyAmount] = useState<number | null>(null);
  const [priorPolicyDate, setPriorPolicyDate] = useState<Date | null>(null);
  const [selectedPriorPolicyUnderwriter, setSelectedPriorPolicyUnderwriter] = useState<PriorPolicyUnderwriter | null>(
    null
  );
  const [selectedUnderwriter, setSelectedUnderwriter] = useState<Underwriter | null>(null);
  const [selectedTitleCompany, setSelectedTitleCompany] = useState<TitleCompany | null>(null);
  const [selectedSettlementAgent, setSelectedSettlementAgent] = useState<SettlementAgent | null>(null);
  const [selectedBuyerPrincipals, setSelectedBuyerPrincipals] = useState<Contact[]>([{} as Contact]);
  const [selectedSellerPrincipals, setSelectedSellerPrincipals] = useState<Contact[]>([{} as Contact]);
  const [selectedBuyerSellerPrincipalList, setSelectedBuyerSellerPrincipalList] = useState<Contact[]>([]);
  const [settlementDate, setSettlementDate] = useState<Date | null>(null);
  const [sellersAttorney, setSellersAttorney] = useState<Contact | null>(null);
  const [sellersParalegal, setSellersParalegal] = useState<Contact | null>(null);
  const [buyersParalegalEmail, setBuyersParalegalEmail] = useState<string | null>(null);
  const [sellersParalegalEmail, setSellersParalegalEmail] = useState<string | null>(null);
  const [sellers, setSellers] = useState<Party[]>([
    {
      isJoint: false,
      sameAsPropertyAddress: true,
      type: "Organization",
      firstName1: "Certify as Found",
      middleName1: null,
      lastName1: null,
      gender1: "Male",
      firstName2: "Certify as Found",
      middleName2: null,
      lastName2: null,
      gender2: "Female",
      organizationPayeeSameAsName: true,
      organizationName: "Certify as Found",
      organizationPayeeName: null,
      organizationType: null,
      relationshipType: "husband and wife",
      address: {} as Address
    }
  ]);
  const [buyersAttorney, setBuyersAttorney] = useState<Contact | null>(null);
  const [buyersParalegal, setBuyersParalegal] = useState<Contact | null>(null);
  const [buyers, setBuyers] = useState<Party[]>([
    {
      isJoint: false,
      sameAsPropertyAddress: true,
      type: "Individual",
      firstName1: "TBD",
      middleName1: null,
      lastName1: null,
      gender1: "Male",
      firstName2: "TBD",
      middleName2: null,
      lastName2: null,
      gender2: "Female",
      organizationPayeeSameAsName: true,
      organizationName: "TBD",
      organizationPayeeName: null,
      organizationType: null,
      relationshipType: "husband and wife",
      address: {} as Address
    }
  ]);
  const [additionalContacts, setAdditionalContacts] = useState<Contact[]>([]);
  const [notes, setNotes] = useState<string | null>(null);
  const [submissionAttempted, setSubmissionAttempted] = useState(false);

  const [priorPolicyUnderwriterList, setPriorPolicyUnderwriterList] = useState<PriorPolicyUnderwriter[]>([]);
  const [underwriterList, setUnderwriterList] = useState<Underwriter[]>([]);
  const [titleCompanyList, setTitleCompanyList] = useState<TitleCompany[]>([]);
  const [settlementAgentList, setSettlementAgentList] = useState<SettlementAgent[]>([]);
  const [orderPrincipal, setOrderPrincipal] = useState<Contact | null>(null);

  const [relationshipTypeList, setRelationshipTypeList] = useState<string[]>([]);
  const [vestingList, setVestingList] = useState<string[]>([]);

  const ordersWithNotesCount = relatedOrders.filter((o) => o.notes).length;
  const totalCharCount = 100 - ordersWithNotesCount * 4 - (relatedOrders.length - ordersWithNotesCount) * 3 + 1;
  const [orderType, setOrderType] = useState<string>("");
  const [patriotSearchResult, setPatriotSearchResult] = useState<string[]>([]);
  const [blackListSearchResults, setBlackListSearchResults] = useState<BlackListResult[]>([]);
  const [isOpenWarningModal, setIsOpenWarningModal] = useState<boolean>(false);

  const [currentBlacklistSearchRequestsCount, setCurrentBlacklistSearchRequestsCount] = useState(0);
  const [openAsSubfile, setOpenAsSubfile] = useState(false);
  const [subfileSuffix, setSubfileSuffix] = useState("");
  const [selectedClientPointOfContactType, setSelectedClientPointOfContactType] = useState<ClientType | null>({
    apiName: "Attorney",
    name: transactionType === "Refinance" ? "Borrowers Attorney" : "Buyers Attorney",
    enum: "buyersAttorney",
    hasOther: false
  });
  const [dealOwner, setDealOwner] = useState<"Buyer" | "Seller" | null>(null);
  const getAndSetProductTypeList = async () => {
    const { data } = await axios.get("/proxy/api/orders/getproducttypes");
    setProductTypeList(data);
  };

  const getAndSetPriorPolicyUnderwriterList = async () => {
    const { data } = await axios.get("/proxy/api/underwriters/getPriorPolicyUnderwriters");
    setPriorPolicyUnderwriterList(data);
  };

  const setDealOwnerOnOrder = async (orderNumber: string) => {
    const dealOwnerValue = dealOwner === "Buyer" ? 0 : dealOwner === "Seller" ? 1 : null;
    await axios.post("/api/orders/setdealowner", {
      orderNumber,
      dealOwner: dealOwnerValue
    });
  };

  const onContactSearchTextChanged = async (text: string, setContactList: Dispatch<SetStateAction<Contact[]>>) => {
    if (!text) {
      setContactList([]);
      return;
    }
    const { data } = await axios.get(`/api/clientphonebook/contacts/getOrderEntryContacts?searchText=${text || ""}`);
    //Add this to url above to filter by ContactTypes.
    //&contactType=${contactType?.name || ""}
    const dataWithEmails = data.map((c: Contact) => ({
      ...c,
      emailsSplit: c.email ? c.email.split(";") : []
    }));
    setContactList(dataWithEmails);
  };

  const searchForPatriotsAndBlackList = async (names: (string | null | undefined)[]) => {
    const namesToSearch = names.filter(Boolean) as string[]; // Remove any undefined or null entries
    const uniqueNamesToSearch = [...new Set(namesToSearch)]; // Uniquify the results

    if (uniqueNamesToSearch.length == 0) return;
    setCurrentBlacklistSearchRequestsCount((prevCount) => prevCount + 1);

    try {
      const searchPromises = uniqueNamesToSearch.map(async (name) => {
        const { data } = await axios.get<PatriotAndBlacklistSearchResult>(
          `/api/clientphonebook/contacts/searchForPatriotsAndBlackList?fullName=${removeMiddleInitial(name)}`
        );

        return {
          patriotSearchResults: data.patriotSearchResult?.names || [],
          blackListSearchResults: data.blackListResults || []
        };
      });

      const resultsArray = await Promise.all(searchPromises);

      const allPatriotResults = resultsArray.flatMap((result) => result.patriotSearchResults);
      const uniquePatriotResults = (previousResults: string[]) =>
        allPatriotResults.filter((result) => !previousResults.includes(result));

      const allBlacklistResults = resultsArray.flatMap((result) => result.blackListSearchResults);
      const uniqueBlacklistResults = (previousResults: any[]) => {
        const previousFoundIds = previousResults.map((r) => r.id);
        return allBlacklistResults.filter((result) => !previousFoundIds.includes(result.id));
      };

      setPatriotSearchResult((previousResults) => {
        const newPatriotResults = uniquePatriotResults(previousResults);
        if (newPatriotResults.length) {
          setIsOpenWarningModal(true);
        }
        return [...previousResults, ...newPatriotResults];
      });

      setBlackListSearchResults((previousResults) => {
        const newBlacklistResults = uniqueBlacklistResults(previousResults);
        if (newBlacklistResults.length) {
          setIsOpenWarningModal(true);
        }
        return [...previousResults, ...newBlacklistResults];
      });
    } finally {
      setCurrentBlacklistSearchRequestsCount((prevCount) => prevCount - 1);
    }
  };

  const getFullNameForBlacklistAndPatriotSearching = ({
    firstName,
    middleName,
    lastName
  }: {
    firstName: string | null;
    middleName: string | null;
    lastName: string | null;
  }) => {
    if (!firstName || firstName == "TBD" || !lastName) {
      return;
    }
    const name = [firstName, middleName, lastName].filter(Boolean).join(" ").trim();
    return name;
  };

  const getAndSetUnderwriterList = async (titleCompany: string) => {
    let { data } = await axios.get(`/proxy/api/underwriters/getbytitlecompany?titlecompany=${titleCompany}`);
    // Not sure if every Underwriter comes back with a lookupCode therefore applying below filter
    data = data.filter((d: Underwriter) => d.lookupCode);
    setUnderwriterList(data);
    if (selectedPriorPolicyUnderwriter?.suffix && selectedPriorPolicyUnderwriter?.suffix != "") {
      const defaultUnderwriter: Underwriter | null = data.find(
        (d: Underwriter) =>
          d.underwriterLookupCode === `${selectedTitleCompany?.lookupCode}-${selectedPriorPolicyUnderwriter.suffix}`
      );
      setSelectedUnderwriter(defaultUnderwriter);
      setAutoFilled({
        ...autoFilled,
        underwriter: {
          ...autoFilled.underwriter,
          autoFilled: true,
          reason: "Autofilled as default"
        }
      });
    }
  };

  const updateMarketingSourceClient = (selectedClientPointOfContactType: ClientType | null) => {
    const contactType = selectedClientPointOfContactType?.enum;
    if (contactType === "principalBuyer") {
      setSelectedClient(selectedBuyerPrincipals[0] || null);
    }
    if (contactType === "principalSeller") {
      setSelectedClient(selectedSellerPrincipals[0] || null);
    }
    if (contactType === "buyersAttorney") {
      setSelectedClient(buyersAttorney);
    }
    if (contactType === "sellersAttorney") {
      setSelectedClient(sellersAttorney);
    }

    if (contactType === "lender") {
      setSelectedClient(loans[0]?.lender || null);
    }
    if (contactType === "lendersAttorney") {
      setSelectedClient(loans[0]?.lendersAttorney || null);
    }
    if (contactType === "mortgageBroker") {
      setSelectedClient(null);
    }
    if (contactType === "other") {
      setSelectedClient(null);
    }
  };

  //// NOTE: MARKETING SOURCE FUNCTIONS
  ////  In the following functions, I am hijacking the setState Functions (e.g. setSelectedBuyerPrincipal function)
  ////  to also set the marketing source but I want to keep the type of the function as the React.setState
  const setSelectedBuyerPrincipalsAndMarketingSource: Dispatch<SetStateAction<Contact[]>> = (value) => {
    const newBuyerPrincipals =
      typeof value === "function" ? (value as (prevState: Contact[]) => Contact[])(selectedBuyerPrincipals) : value;

    setSelectedBuyerPrincipals(newBuyerPrincipals);

    if (selectedClientPointOfContactType?.enum === "principalBuyer") {
      setSelectedClient(newBuyerPrincipals[0] || null);
    }
  };

  //// SEE NOTE: MARKETING SOURCE FUNCTIONS
  const setSelectedSellerPrincipalsAndMarketingSource: Dispatch<SetStateAction<Contact[]>> = (value) => {
    const newSellersPrincipals =
      typeof value === "function" ? (value as (prevState: Contact[]) => Contact[])(selectedSellerPrincipals) : value;

    setSelectedSellerPrincipals(newSellersPrincipals);

    if (selectedClientPointOfContactType?.enum === "principalSeller") {
      setSelectedClient(newSellersPrincipals[0] || null);
    }
  };

  //// SEE NOTE: MARKETING SOURCE FUNCTIONS
  const setBuyersAttorneyAndMarketingSource: React.Dispatch<React.SetStateAction<Contact | null>> = (value) => {
    const newBuyersAttorney =
      typeof value === "function" ? (value as (prevState: Contact | null) => Contact | null)(buyersAttorney) : value;

    setBuyersAttorney(newBuyersAttorney);
    if (selectedClientPointOfContactType?.enum == "buyersAttorney") {
      setSelectedClient(newBuyersAttorney);
    }
  };

  //// SEE NOTE: MARKETING SOURCE FUNCTIONS
  const setSellersAttorneyAndMarketingSource: React.Dispatch<React.SetStateAction<Contact | null>> = (value) => {
    const newSellersAttorney =
      typeof value === "function" ? (value as (prevState: Contact | null) => Contact | null)(sellersAttorney) : value;

    setSellersAttorney(newSellersAttorney);
    if (selectedClientPointOfContactType?.enum == "sellersAttorney") {
      setSelectedClient(newSellersAttorney);
    }
  };

  //// SEE NOTE: MARKETING SOURCE FUNCTIONS
  const setSelectedClientPointOfContactTypeAndMarketingSource: React.Dispatch<
    React.SetStateAction<ClientType | null>
  > = (value) => {
    const newClientType =
      typeof value === "function"
        ? (value as (prevState: ClientType | null) => ClientType | null)(selectedClientPointOfContactType)
        : value;

    setSelectedClientPointOfContactType(newClientType);
    updateMarketingSourceClient(newClientType);
  };

  //// SEE NOTE: MARKETING SOURCE FUNCTIONS EXCEPT THIS IS ADAPTED FOR IMMER
  const setLoansAndMarketingSource = (value: Loan[] | DraftFunction<Loan[]>) => {
    let updatedLoans: Loan[] = [];
    setLoans((draft) => {
      if (typeof value === "function") {
        updatedLoans = (value as (prevState: Loan[]) => Loan[])(draft);
        return updatedLoans;
      } else {
        updatedLoans = value;
        return value;
      }
    });

    if (selectedClientPointOfContactType?.enum === "lender") {
      setSelectedClient(updatedLoans[0]?.lender || null);
    }
    if (selectedClientPointOfContactType?.enum === "lendersAttorney") {
      setSelectedClient(updatedLoans[0]?.lendersAttorney || null);
    }
  };

  const getAndSetTitleAgencyList = async (state: string) => {
    const { data } = await axios.get(`/proxy/api/titlecompanies?state=${state}`);
    setTitleCompanyList(data);
  };

  const getAndSetSettlementAgentList = async () => {
    const { data } = await axios.get("/proxy/api/clients/getDefaultSettlementAgent");
    setSettlementAgentList([data]);
    setSelectedSettlementAgent(data);
  };

  const getAndSetRelationshipTypeList = async () => {
    const { data } = await axios.get("/proxy/api/contacts/relationshiptypes");
    setRelationshipTypeList(data);
  };

  const getAndSetContactTypeList = async () => {
    const { data } = await axios.get<ContactType[]>("/proxy/api/contacts/availablecontacttypes");

    const uniqueData = data.reduce((accumulator: any, currentValue: ContactType) => {
      if (currentValue.name == "Funder") {
        return accumulator;
      }

      if (!accumulator[currentValue.name]) {
        accumulator[currentValue.name] = currentValue;
      }
      return accumulator;
    }, {});

    setContactTypeList(Object.values(uniqueData));
  };

  const getAndSetVestingList = async () => {
    const { data } = await axios.get("/proxy/api/orders/getvestings");
    setVestingList(data);
  };

  const getAndSetMatchedOrders = async (request: MatchedOrderRequest) => {
    if (!request.state) return;
    const updatedRequest =
      !request.block || !request.lot ? { ...request, block: null, lot: null, section: null } : request;

    setMatchedOrdersIsLoading(true);
    const { data } = await axios.get(`/proxy/api/orders/SearchRelatedOrders?${qs.stringify(updatedRequest)}`);
    var selectOrdersToAdd = data.selectOrders.filter(
      (d: MatchedOrder) => !matchedOrders.some((o) => o.identifier === d.identifier)
    );
    //@ts-ignore
    var selectOrdersToAdd = await getAndSetMatchedPriorPolicyByOrderNumber(selectOrdersToAdd);
    var oldOrdersToAdd = data.enterpriseOrders.filter(
      (d: MatchedOldOrder) => !matchedOldOrders.some((o) => o.orderNumber === d.orderNumber)
    );
    setMatchedOrders([...matchedOrders, ...selectOrdersToAdd]);
    setMatchedOldOrders([...matchedOldOrders, ...oldOrdersToAdd]);
    setMatchedOrdersIsLoading(false);
  };

  const getAndSetMatchedOrdersByOrderNumber = async (orderNumber: string) => {
    setMatchedOrdersIsLoading(true);
    const { data } = await axios.get(`/proxy/api/orders/getOrderInfo?orderNumber=${orderNumber}`);
    if (!matchedOrders.some((o) => o.identifier === data.identifier)) {
      setMatchedOrders([...matchedOrders, data]);
    }
    setMatchedOrdersIsLoading(false);
  };

  const getAndSetMatchedPriorPolicyByOrderNumber = async (orders: MatchedOrder[]) => {
    var matchedOrdersCopy: MatchedOrder[] = [];
    for await (const order of orders) {
      if (!order.priorPolicy) {
        const { data } = await axios.get(`/proxy/api/orders/getpolicy?ordernumber=${order.number}`);
        matchedOrdersCopy.push({ ...order, priorPolicy: data });
      } else {
        matchedOrdersCopy.push(order);
      }
    }
    return matchedOrdersCopy;
  };

  const postFiles = async (orderNumber: string) => {
    const orderUploadFiles: {
      name: string;
      base64Contents: string;
      documentType: string;
    }[] = await Promise.all(
      orderFiles
        .filter((_files) => _files.type !== "Prior Policy")
        .map(async (e) => {
          const file = e.file;
          if ("data" in file) {
            return {
              name: file.name,
              base64Contents: await msgAttachmentToBase64(file as MSGExtractFileWithType),
              documentType: e.type
            };
          } else {
            return {
              name: file.name,
              base64Contents: stripBase64LeadingChars(await toBase64(file as File)),
              documentType: e.type
            };
          }
        })
    );
    const priorPolicyUploadFiles = await Promise.all(
      priorPolicyFiles.map(async (file: any) => {
        if ("data" in file) {
          return {
            name: file.name,
            base64Contents: await msgAttachmentToBase64(file as MSGExtractFileWithType)
          };
        } else {
          return {
            name: file.name,
            base64Contents: stripBase64LeadingChars(await toBase64(file as File))
          };
        }
      })
    );

    // @ts-ignore
    const uploadOrderNumber = isDevelopment() ? "108000-test" : orderNumber;
    const uploadData = {
      orderNumber: uploadOrderNumber,
      orderFiles: orderUploadFiles,
      priorPolicyFiles: priorPolicyUploadFiles
    };

    const result = await axios.post("/api/file/uploadfiles", uploadData);
  };

  const getContact = async (lookupCode: string) => {
    const { data } = await axios<Contact>({
      method: "get",
      url: `/api/clientphonebook/Contacts/GetOrderEntryContactByLookupCode?lookupCode=${lookupCode || ""}`
    });
    return data;
  };

  const getBlacklistNotes = (patriotNames: string[], blacklistNames: string[]) => {
    let messages = [];

    if (patriotNames.length) {
      const patriotNotesMessage = `Patriot search matches found:  ${patriotNames.join(
        ", "
      )}. Do not continue transaction until confirmed to not be a match.`;
      messages.push(patriotNotesMessage);
    }

    if (blacklistNames.length) {
      const blackListMessage = `DO NOT PROCEED! Blacklist matches found:${blacklistNames.join(
        ", "
      )}. Do not continue transaction until confirmed in writing with your manager!`;
      messages.push(blackListMessage);
    }

    return messages;
  };

  const getSellers = () => {
    return sellers.map((s) => {
      if (s.type === "Individual") {
        if (s.isJoint) {
          return {
            sameAsPropertyAddress: s.sameAsPropertyAddress,
            individual: null,
            organization: null,
            joint: {
              relationshipType: s.relationshipType,
              jointOne: {
                firstName: s.firstName1,
                middleName: s.middleName1,
                lastName: s.lastName1,
                gender: s.gender1,
                attorneyLookupCode: sellersAttorney?.lookupCode,
                address: {
                  address1: s.address?.propertyInfo?.address,
                  address2: s.address?.propertyInfo?.aptNo,
                  city: s.address?.propertyInfo?.city,
                  state: s.address?.propertyInfo?.state,
                  zip: s.address?.propertyInfo?.zipCode,
                  email: s.address?.email,
                  phone: s.address?.phone
                }
              },
              jointTwo: {
                firstName: s.firstName2,
                middleName: s.middleName2,
                lastName: s.lastName2,
                gender: s.gender2,
                attorneyLookupCode: sellersAttorney?.lookupCode,
                address: {
                  address1: s.address?.propertyInfo?.address,
                  address2: s.address?.propertyInfo?.aptNo,
                  city: s.address?.propertyInfo?.city,
                  state: s.address?.propertyInfo?.state,
                  zip: s.address?.propertyInfo?.zipCode,
                  email: s.address?.email,
                  phone: s.address?.phone
                }
              }
            }
          };
        } else {
          return {
            sameAsPropertyAddress: s.sameAsPropertyAddress,
            individual: {
              firstName: s.firstName1,
              middleName: s.middleName1,
              lastName: s.lastName1,
              gender: s.gender1,
              attorneyLookupCode: sellersAttorney?.lookupCode,
              address: {
                address1: s.address?.propertyInfo?.address,
                address2: s.address?.propertyInfo?.aptNo,
                city: s.address?.propertyInfo?.city,
                state: s.address?.propertyInfo?.state,
                zip: s.address?.propertyInfo?.zipCode,
                email: s.address?.email,
                phone: s.address?.phone
              }
            },
            organization: null,
            joint: null
          };
        }
      }
      if (s.type === "Organization") {
        return {
          sameAsPropertyAddress: s.sameAsPropertyAddress,
          individual: null,
          organization: {
            name: s.organizationName,
            organizationType: s.organizationType,
            payeeName: s.organizationPayeeSameAsName ? s.organizationName : s.organizationPayeeName,
            attorneyLookupCode: sellersAttorney?.lookupCode,
            address: {
              address1: s.address?.propertyInfo?.address,
              address2: s.address?.propertyInfo?.aptNo,
              city: s.address?.propertyInfo?.city,
              state: s.address?.propertyInfo?.state,
              zip: s.address?.propertyInfo?.zipCode,
              email: s.address?.email,
              phone: s.address?.phone
            },
            vesting: s.vesting
          },
          joint: null
        };
      }
    });
  };
  const getBuyers = () => {
    return buyers.map((b) => {
      if (b.type === "Individual") {
        if (b.isJoint) {
          return {
            sameAsPropertyAddress: b.sameAsPropertyAddress,
            individual: null,
            organization: null,
            joint: {
              relationshipType: b.relationshipType,
              jointOne: {
                firstName: b.firstName1,
                middleName: b.middleName1,
                lastName: b.lastName1,
                gender: b.gender1,
                attorneyLookupCode: buyersAttorney?.lookupCode,
                address: {
                  address1: b.address?.propertyInfo?.address,
                  address2: b.address?.propertyInfo?.aptNo,
                  city: b.address?.propertyInfo?.city,
                  state: b.address?.propertyInfo?.state,
                  zip: b.address?.propertyInfo?.zipCode,
                  email: b.address?.email,
                  phone: b.address?.phone
                }
              },
              jointTwo: {
                firstName: b.firstName2,
                middleName: b.middleName2,
                lastName: b.lastName2,
                gender: b.gender2,
                attorneyLookupCode: buyersAttorney?.lookupCode,
                address: {
                  address1: b.address?.propertyInfo?.address,
                  address2: b.address?.propertyInfo?.aptNo,
                  city: b.address?.propertyInfo?.city,
                  state: b.address?.propertyInfo?.state,
                  zip: b.address?.propertyInfo?.zipCode,
                  email: b.address?.email,
                  phone: b.address?.phone
                }
              }
            }
          };
        } else {
          return {
            sameAsPropertyAddress: b.sameAsPropertyAddress,
            individual: {
              firstName: b.firstName1,
              middleName: b.middleName1,
              lastName: b.lastName1,
              gender: b.gender1,
              attorneyLookupCode: buyersAttorney?.lookupCode,
              address: {
                address1: b.address?.propertyInfo?.address,
                address2: b.address?.propertyInfo?.aptNo,
                city: b.address?.propertyInfo?.city,
                state: b.address?.propertyInfo?.state,
                zip: b.address?.propertyInfo?.zipCode,
                email: b.address?.email,
                phone: b.address?.phone
              }
            },
            organization: null,
            joint: null
          };
        }
      }
      if (b.type === "Organization") {
        return {
          individual: null,
          organization: {
            name: b.organizationName,
            organizationType: b.organizationType,
            payeeName: b.organizationPayeeSameAsName ? b.organizationName : b.organizationPayeeName,
            attorneyLookupCode: buyersAttorney?.lookupCode,
            address: {
              address1: b.address?.propertyInfo?.address,
              address2: b.address?.propertyInfo?.aptNo,
              city: b.address?.propertyInfo?.city,
              state: b.address?.propertyInfo?.state,
              zip: b.address?.propertyInfo?.zipCode,
              email: b.address?.email,
              phone: b.address?.phone
            },
            vesting: b.vesting
          },
          joint: null
        };
      }
    });
  };
  const [errors, setErrors] = useState<Errors>({
    pointOfContact: { pointOfContactType: false, name: true },
    sellersAttorney: false,
    underwriter: true,
    titleCompany: true
  });

  const [autoFilled, setAutoFilled] = useState<AutoFilled>({
    properties: [{ propertyType: { autoFilled: false, reason: "" } }],
    propertyUse: { autoFilled: false, reason: "" },
    lender: { autoFilled: true, reason: "Autofilled as default" },
    seller: {
      firstName1: { autoFilled: true, reason: "Autofilled as default" },
      lastName1: { autoFilled: false, reason: "" },
      firstName2: { autoFilled: true, reason: "Autofilled as default" },
      lastName2: { autoFilled: false, reason: "" },
      organizationName: { autoFilled: true, reason: "Autofilled as default" },
      organizationType: { autoFilled: false, reason: "" }
    },
    buyer: {
      firstName1: { autoFilled: true, reason: "Autofilled as default" },
      lastName1: { autoFilled: false, reason: "" },
      firstName2: { autoFilled: true, reason: "Autofilled as default" },
      lastName2: { autoFilled: false, reason: "" },
      organizationName: { autoFilled: true, reason: "Autofilled as default" },
      organizationType: { autoFilled: false, reason: "" }
    },
    titleCompany: { autoFilled: false, reason: "" },
    underwriter: { autoFilled: false, reason: "" },
    settlementAgent: { autoFilled: true, reason: "Autofilled as default" },
    pointOfContact: {
      clientType: { autoFilled: true, reason: "Autofilled as default" },
      salesRep: { autoFilled: false, reason: "" }
    },
    selectedClient: {
      internalDriver: { autoFilled: false, reason: "Autofilled as default" }
    }
  });

  const updateErrors = () => {
    setErrors({
      pointOfContact: {
        pointOfContactType: selectedClientPointOfContactType === null,
        name:
          selectedClientPointOfContactType?.name !== "Buyers Attorney" &&
          selectedClientPointOfContactType?.name !== "Sellers Attorney" &&
          isBlank(selectedClient?.name)
      },
      sellersAttorney: selectedClientPointOfContactType?.name === "Sellers Attorney" && sellersAttorney === null,
      underwriter: selectedUnderwriter === null && transactionType != "LastOwnerSearch",
      titleCompany: selectedTitleCompany === null
    });
  };

  const updatePointOfContact = () => {
    if (transactionType === "Refinance" && !buyersAttorney && propertyUse === "Residential") {
      setSelectedClientPointOfContactTypeAndMarketingSource({
        apiName: "Mortgage Broker",
        name: "Mortgage Broker",
        enum: "mortgageBroker",
        hasOther: false
      });
    } else if (transactionType === "LastOwnerSearch" && !buyersAttorney) {
      setSelectedClientPointOfContactTypeAndMarketingSource(null);
    } else if (selectedBuyerPrincipals.length > 1) {
      setSelectedClientPointOfContactTypeAndMarketingSource({
        apiName: "Principal-Buyer",
        name: "Principal-Buyer",
        enum: "principalBuyer",
        hasOther: true
      });
    } else if (selectedSellerPrincipals.length > 1) {
      setSelectedClientPointOfContactTypeAndMarketingSource({
        apiName: "Principal-Seller",
        name: "Principal-Seller",
        enum: "principalSeller",
        hasOther: true
      });
    } else {
      setSelectedClientPointOfContactTypeAndMarketingSource({
        apiName: "Attorney",
        name: transactionType === "Refinance" ? "Borrower's Attorney" : "Buyers Attorney",
        enum: "buyersAttorney",
        hasOther: false
      });
    }
  };

  const updateBuyerType = () => {
    if (
      !buyers[0].address.propertyInfo?.address &&
      buyers[0].firstName1 === "TBD" &&
      !buyers[0].lastName1 &&
      buyers[0].organizationName === "TBD"
    ) {
      setBuyers(
        buyers.map((b, i) =>
          i === 0
            ? {
                ...b,
                type: propertyUse === "Commercial" ? "Organization" : "Individual"
              }
            : b
        )
      );
    }
  };

  //**______________________________SECTION USE EFFECTS ______________________________________**//

  useEffect(() => {
    getAndSetSettlementAgentList();
    getAndSetPriorPolicyUnderwriterList();
    getAndSetRelationshipTypeList();
    getAndSetContactTypeList();
    getAndSetVestingList();
  }, []);

  useEffect(() => {
    setSelectedClientPointOfContactType({
      apiName: "Attorney",
      name: transactionType === "Refinance" ? "Borrowers Attorney" : "Buyers Attorney",
      enum: "buyersAttorney",
      hasOther: false
    });
    if (transactionType === "Refinance" || transactionType === "LastOwnerSearch") {
      setBuyers(sellers);
      setSelectedClientPointOfContactTypeAndMarketingSource({
        apiName: "Mortgage Broker",
        name: "Mortgage Broker",
        enum: "mortgageBroker",
        hasOther: false
      });
      setSellers;
    } else {
      setBuyers([
        {
          isJoint: false,
          sameAsPropertyAddress: true,
          type: "Individual",
          firstName1: "TBD",
          middleName1: null,
          lastName1: null,
          gender1: "Male",
          firstName2: "TBD",
          middleName2: null,
          lastName2: null,
          gender2: "Female",
          organizationPayeeSameAsName: true,
          organizationName: "TBD",
          organizationPayeeName: null,
          organizationType: null,
          relationshipType: "husband and wife",
          address: {} as Address
        }
      ]);
      setSelectedClientPointOfContactTypeAndMarketingSource({
        apiName: "Attorney",
        name: "Buyers Attorney",
        enum: "buyersAttorney",
        hasOther: false
      });
    }
    if (transactionType === "LastOwnerSearch") {
      getAndSetProductTypeList();
    }
  }, [transactionType]);

  useEffect(() => {
    if (selectedTitleCompany?.lookupCode) {
      getAndSetUnderwriterList(selectedTitleCompany.lookupCode);
    }

    if (selectedTitleCompany?.lookupCode === "MTAUS") {
      setSettlementAgentList([]);
      setSelectedSettlementAgent(null);
    }
  }, [selectedTitleCompany]);

  useEffect(() => {
    if (properties[0].propertyInfo?.state) {
      var state = properties[0].propertyInfo?.state.toLowerCase();
      setSelectedTitleCompany(null);
      setSelectedUnderwriter(null);
      getAndSetTitleAgencyList(properties[0].propertyInfo?.state);
      if (
        (state === "tx" || state === "texas" || state === "ny" || state === "new york") &&
        autoFilled.settlementAgent.autoFilled
      ) {
        setSelectedSettlementAgent(null);
        setSettlementAgentList([]);
        setAutoFilled({
          ...autoFilled,
          settlementAgent: { autoFilled: false, reason: "" }
        });
      }
    }
  }, [properties[0].propertyInfo?.state]);

  useEffect(() => {
    const mtaCompanies = titleCompanyList.filter((c) => c.lookupCode?.toLowerCase().includes("mta"));
    if (mtaCompanies.length === 1) {
      setSelectedTitleCompany(mtaCompanies[0]);
      setAutoFilled({
        ...autoFilled,
        titleCompany: { autoFilled: true, reason: "Autofilled as default" }
      });
    }
  }, [titleCompanyList]);

  useEffect(() => {
    updatePointOfContact();
  }, [transactionType, propertyUse]);

  useEffect(() => {
    updateBuyerType();
  }, [propertyUse]);

  useEffect(() => {
    updateErrors();
  }, [
    selectedClient,
    selectedClientPointOfContactType,
    selectedUnderwriter,
    sellersAttorney,
    buyersAttorney,
    selectedTitleCompany
  ]);

  const addressIsValid = (property: Property) => {
    const { propertyInfo } = property;
    const { formattedAddress, parcelIds, state, block, lots, city, county, zipCode } = propertyInfo;
    return (
      (formattedAddress || parcelIds[0] || (state && isNJorNY(state) && block && lots[0])) &&
      city &&
      state &&
      county &&
      (!zipCode || (zipCode && validateZipcode(zipCode)))
    );
  };

  const totalRelatedOrdersCharLength = useMemo(() => {
    return relatedOrders.reduce((total, relatedOrder) => {
      return total + (relatedOrder.fileNumber?.length ?? 0) + (relatedOrder.notes?.length ?? 0);
    }, 0);
  }, [relatedOrders]);

  const validateForm = () => {
    const hasErrors =
      totalRelatedOrdersCharLength > totalCharCount ||
      !propertyUse ||
      errors.underwriter ||
      errors.titleCompany ||
      errors.sellersAttorney ||
      Object.values(errors.pointOfContact).some((val) => val) ||
      orderFiles.some((f) => f.type === null) ||
      properties.some((p) => !addressIsValid(p));
    if (hasErrors) {
      setErrorMode(true);

      /////TEMP to figure out what is causing forms to fail without error
      console.error("Validation failed due to:");
      if (totalRelatedOrdersCharLength > totalCharCount) {
        console.error(" - Total related orders character length is greater than total character count.");
        console.log("totalCharCount:", totalCharCount);
        console.log("totalRelatedOrdersCharLength:", totalRelatedOrdersCharLength);
      }
      if (!propertyUse) {
        console.error(" - Property use is not selected.");
        console.log("propertyUse:", propertyUse);
      }
      if (errors.underwriter) {
        console.error(" - Underwriter error.");
        console.log("errors.underwriter:", errors.underwriter);
      }
      if (errors.titleCompany) {
        console.error(" - Title company error.");
        console.log("errors.titleCompany:", errors.titleCompany);
      }
      if (errors.sellersAttorney) {
        console.error(" - Seller's attorney error.");
        console.log("errors.sellersAttorney:", errors.sellersAttorney);
      }
      if (Object.values(errors.pointOfContact).some((val) => val)) {
        console.error(" - Point of contact error.");
        console.log("errors.pointOfContact", errors.pointOfContact);
      }
      if (orderFiles.some((f) => f.type === null)) {
        console.error(" - Order file error.");
        console.log("orderFiles", orderFiles);
      }
      if (properties.some((p) => !addressIsValid(p))) {
        console.error(" - Address is invalid.");
        console.log("properties", properties);
      }
      console.log("errors", errors || "");
      ////End TEMP Logging

      const errorElements = document.getElementsByClassName("Mui-error");
      if (errorElements.length > 0) {
        errorElements[0].scrollIntoView({ block: "center" });
      }
      return false;
    }
    return true;
  };

  const submitOrder = async () => {
    const isFormValid = validateForm();
    if (!isFormValid) {
      setErrorMode(true);
      return;
    }
    submitToSelectAPI();
  };

  const combineEmails = (email1: string | null | undefined, email2: string | null | undefined) => {
    if (email2) {
      var email2Parts = email2.split(/(?:;| )+/);
      email2 = email2Parts.join("; ");
    }
    if (email1 && email2) {
      return `${email1}; ${email2}`;
    } else if (email1) {
      return email1;
    }
    return email2;
  };

  const formatSettlementDate = (settlementDate: Date | null) => {
    settlementDate?.setHours(0, 0, 0, 0);
    return settlementDate;
  };

  const joinNotes = (notes: string[] | undefined | null | string) => {
    if (typeof notes === "string") {
      return notes;
    }
    if (!notes || notes.length < 1) {
      return null;
    } else {
      return notes.join("\n");
    }
  };

  const getPointOfContactName = (name: string | null | undefined, suffix: string | null | undefined) => {
    if (suffix) {
      return name + ", " + suffix;
    } else {
      return name;
    }
  };

  const getPointOfContact = () => {
    const isNational = !properties?.filter((p) => p.propertyInfo.state === "NY").length;
    if (selectedClientPointOfContactType?.name === "Buyers Attorney") {
      return {
        lookupCode: buyersAttorney?.lookupCode || null,
        name: getPointOfContactName(buyersAttorney?.name, buyersAttorney?.suffix),
        email: combineEmails(buyersAttorney?.email, buyersParalegalEmail),
        phone: buyersAttorney?.phone || null,
        fax: buyersAttorney?.fax || null,
        company: buyersAttorney?.company || null,
        internalDriver: selectedAccountRep?.username,
        notes: joinNotes(buyersAttorney?.notes),
        address1: buyersAttorney?.address1 || null,
        address2: buyersAttorney?.address2 || null,
        city: buyersAttorney?.city || null,
        state: buyersAttorney?.state || null,
        zip: buyersAttorney?.zip || null,
        clientInstructions: buyersAttorney?.clientInstructions || null
      };
    } else if (selectedClientPointOfContactType?.name === "Sellers Attorney") {
      return {
        lookupCode: sellersAttorney?.lookupCode || null,
        name: getPointOfContactName(sellersAttorney?.name, sellersAttorney?.suffix),
        email: combineEmails(sellersAttorney?.email, sellersParalegalEmail),
        phone: sellersAttorney?.phone || null,
        fax: sellersAttorney?.fax || null,
        company: sellersAttorney?.company || null,
        internalDriver: selectedAccountRep?.username,
        notes: joinNotes(sellersAttorney?.notes),
        address1: sellersAttorney?.address1 || null,
        address2: sellersAttorney?.address2 || null,
        city: sellersAttorney?.city || null,
        state: sellersAttorney?.state || null,
        zip: sellersAttorney?.zip || null,
        clientInstructions: sellersAttorney?.clientInstructions || null
      };
    } else {
      return {
        lookupCode: selectedClient?.lookupCode || null,
        name: getPointOfContactName(selectedClient?.name, selectedClient?.suffix),
        email: selectedClient?.email || null,
        phone: selectedClient?.phone || null,
        fax: selectedClient?.fax || null,
        company: selectedClient?.company || null,
        internalDriver: selectedAccountRep?.username,
        notes: joinNotes(selectedClient?.notes),
        address1: selectedClient?.address1 || null,
        address2: selectedClient?.address2 || null,
        city: selectedClient?.city || null,
        state: selectedClient?.state || null,
        zip: selectedClient?.zip || null,
        clientInstructions: selectedClient?.clientInstructions || null
      };
    }
  };

  const getBuyersAttorney = () => {
    if (selectedClientPointOfContactType?.name === "Buyers Attorney") {
      return null;
    } else {
      return {
        ...buyersAttorney,
        email: combineEmails(buyersAttorney?.email, buyersParalegal?.email),
        notes: joinNotes(buyersAttorney?.notes)
      };
    }
  };

  const getSellersAttorney = () => {
    if (selectedClientPointOfContactType?.name === "Sellers Attorney") {
      return null;
    }
    return {
      ...sellersAttorney,
      email: combineEmails(sellersAttorney?.email, sellersParalegal?.email),
      notes: joinNotes(sellersAttorney?.notes)
    };
  };

  const getAdditionalContacts = (contacts: Contact[], selectedFunder: InternalUser | null) => {
    const contactsWithExtraInfo = contacts.map((c) => {
      const emailsSplitWithValues = c.emailsSplit?.filter((val) => val) || [];
      return {
        ...c,
        email: emailsSplitWithValues.length ? emailsSplitWithValues.join(";") : c.email || "",
        contactType: c.contactType?.name,
        isOther: c.contactType?.isOther,
        notes: joinNotes(c.notes)
      };
    });

    if (!selectedFunder) {
      return contactsWithExtraInfo;
    }

    const selectedFunderAsContact = {
      ...convertInternalUserToContact(selectedFunder),
      lookupCode: "" /*as per Mr. Freylicher we don't want the lookupcode for funders to be submitted here */,
      contactType: "Funder",
      isOther: true,
      notes: ""
    };
    return contactsWithExtraInfo.concat(selectedFunderAsContact as any);
  };

  // This is necessary to cover for when user entered half year 01/12/22
  const formatPriorPolicyDate = () => {
    if (priorPolicyDate) {
      return new Date(priorPolicyDate.toString()).toUTCString();
    }
  };

  const convertToLenderWithSelectedLendersBranch = (loan: Loan) => {
    const { lender, lendersBranch } = loan;
    if (!lender?.id) {
      return null;
    }
    const hasBranch = lendersBranch;
    return {
      ...lender,
      address1: hasBranch ? lendersBranch.address1 : lender.address1,
      address2: hasBranch ? lendersBranch.address2 : lender.address2,
      city: hasBranch ? lendersBranch.city : lender.city,
      state: hasBranch ? lendersBranch.state : lender.state,
      zip: hasBranch ? lendersBranch.zip : lender.zip,
      notes: lender.notes?.join("\r\n")
    } as any;
  };

  const getLoanMortgageBrokers = (loans: Loan[]) => {
    const mortgageBrokers = loans
      .filter((l) => Boolean(l.mortgageBroker))
      .map((l) => ({
        ...l.mortgageBroker,
        type: "Mortgage Broker",
        contactType: { name: "Mortgage Broker", isOther: false } as ContactType,
        payeeName: l?.mortgageBroker?.payeeName || l?.mortgageBroker?.company || l?.mortgageBroker?.name,
        emailsSplit: l?.mortgageBroker?.email ? l?.mortgageBroker?.email?.split(";") : []
      }));
    return mortgageBrokers as unknown as Contact[];
  };

  const combineAdditionalAndPrincipalContacts = () => [
    ...getLoanMortgageBrokers(loans),
    ...additionalContacts,
    ...selectedBuyerPrincipals.map((p) => ({
      ...p,
      contactType: { name: "Principal - Buyer", isOther: true } as ContactType,
      email: p.emailsSplit && p.emailsSplit?.length > 0 ? [p.email, ...p.emailsSplit].join("; ") : p.email
    })),
    ...selectedSellerPrincipals.map((p) => ({
      ...p,
      contactType: { name: "Principal - Seller", isOther: true } as ContactType,
      email: p.emailsSplit && p.emailsSplit?.length > 0 ? [p.email, ...p.emailsSplit].join("; ") : p.email
    }))
  ];

  const submitToSelectAPI = async () => {
    setLoading(true);
    const order = {
      productType: transactionType === "LastOwnerSearch" ? selectedProductType : null,
      projectName,
      pointOfContact: {
        pointOfContactType: selectedClientPointOfContactType?.enum || null,
        team: selectedTeam,
        ...getPointOfContact(),
        salesRep1LookupCode: selectedMarketingRep1?.lookupCode || null,
        salesRep2LookupCode: selectedMarketingRep2?.lookupCode || null,
        salesRep3LookupCode: selectedMarketingRep3?.lookupCode || null
      },
      purchasePrice,
      transactionType,
      propertyUse,
      salesSuffix,
      isHud: settlementStatementType === "HUD",
      loans: loans.map((l) => ({
        loanAmount: l.loanAmount,
        lender: transactionType === "LastOwnerSearch" ? null : convertToLenderWithSelectedLendersBranch(l),
        loanNumber: l.loanNumber,
        lendersAttorney: l.lendersAttorney?.lookupCode
          ? {
              lookupCode: l.lendersAttorney?.lookupCode,
              name: l.lendersAttorney?.name,
              email: l.lendersAttorney?.email,
              phone: l.lendersAttorney?.phone,
              fax: l.lendersAttorney?.fax,
              company: l.lendersAttorney?.company,
              internalDriver: l.lendersAttorney?.internalDriver,
              address1: l.lendersAttorney?.address1,
              address2: l.lendersAttorney?.address2,
              city: l.lendersAttorney?.city,
              state: l.lendersAttorney?.state,
              zip: l.lendersAttorney?.zip
            }
          : null,
        isConstructionLoan: l.isConstructionLoan
      })),
      properties: properties.map((property) => ({
        address: property?.propertyInfo?.formattedAddress,
        city: property?.propertyInfo?.city,
        county: property?.propertyInfo?.county,
        state: property?.propertyInfo?.state,
        zipcode: property?.propertyInfo?.zipCode,
        section: property?.propertyInfo?.section,
        block: property?.propertyInfo?.block,
        lots: property?.propertyInfo?.lots.filter((l) => l),
        unit: property?.propertyInfo?.aptNo,
        parcelIds: property?.propertyInfo?.parcelIds.filter((i) => i),
        propertyType: property.propertyInfo?.propertyType,
        legalDescription: {
          description: property?.propertyInfo?.legalDescription?.description,
          lookupCode: property?.propertyInfo?.legalDescription?.lookupCode
        }
      })),
      priorPolicy: {
        underwriterLookupCode: selectedPriorPolicyUnderwriter?.name,
        amount: priorPolicyAmount,
        date: priorPolicyDate && formatPriorPolicyDate()
      },
      underwriter: selectedUnderwriter,
      sellers:
        transactionType === "Refinance" || transactionType === "LastOwnerSearch"
          ? []
          : { sellers: getSellers(), attorney: getSellersAttorney() },
      buyers: { buyers: getBuyers(), attorney: getBuyersAttorney() },
      additionalContacts: getAdditionalContacts(combineAdditionalAndPrincipalContacts(), selectedFunder),
      notes,
      blacklistNotes: getBlacklistNotes(
        patriotSearchResult,
        blackListSearchResults.map((r) => r.information).filter(Boolean)
      ),
      isRush,
      relatedOrders:
        relatedOrders.length > 0
          ? relatedOrders.map((o) => `${o.fileNumber || ""}${o.notes ? ` (${o.notes})` : ""}`)
          : [],
      createdByUsername: user?.username,
      titleCompanyLookupCode: selectedTitleCompany?.lookupCode,
      settlementAgent: selectedSettlementAgent,
      settlementDate: formatSettlementDate(settlementDate),
      isConfidential,
      orderType,
      baseNumber:
        !openAsSubfile || relatedOrders.length === 0 || !relatedOrders[0].fileNumber || !subfileSuffix
          ? ""
          : `${relatedOrders[0].fileNumber}${subfileSuffix}`
    };

    const { data } = await axios.post("/proxy/api/orders/create", order);
    logEvent("Order Entry", "New order created", JSON.stringify(data, null, 2));
    try {
      await postFiles(data.number);
      setDidFileUploadSucceed(true);
    } catch (e: any) {
      try {
        await axios.post("/api/ErrorLogger/log", {
          header: "Order Entry File Upload Failed",
          message: e.stack
        });
      } catch {}
      setDidFileUploadSucceed(false);
    }
    setOrderNumber(data.number);
    // if (data.number) {
    //   await setDealOwnerOnOrder(data.number);
    // }
    setIdentifier(data.identifier);
    setLoading(false);
  };

  return (
    <OrderContext.Provider
      value={{
        loading,
        isRush,
        isConfidential,
        projectName,
        orderFiles,
        selectedClient,
        selectedTeam,
        salesSuffix,
        selectedProductType,
        productTypeList,
        selectedMarketingRep1,
        selectedMarketingRep2,
        selectedMarketingRep3,
        selectedFunder,
        selectedAccountRep,
        selectedClientPointOfContactType,
        orderNumber,
        identifier,
        loans,
        purchasePrice,
        transactionType,
        settlementStatementType,
        propertyUse,
        properties,
        priorPolicyFiles,
        relatedOrders,
        priorPolicyUnderwriterList,
        underwriterList,
        vestingList,
        titleCompanyList,
        settlementAgentList,
        selectedSettlementAgent,
        selectedPriorPolicyUnderwriter,
        priorPolicyAmount,
        priorPolicyDate,
        selectedUnderwriter,
        selectedTitleCompany,
        settlementDate,
        relationshipTypeList,
        contactTypeList,
        sellers,
        sellersAttorney,
        sellersParalegal,
        setSellersParalegalEmail,
        buyers,
        buyersAttorney,
        buyersParalegal,
        setBuyersParalegalEmail,
        additionalContacts,
        notes,
        matchedOrders,
        matchedOldOrders,
        matchedOrdersIsLoading,
        errorMessage,
        submissionAttempted,
        errors,
        autoFilled,
        errorMode,
        totalRelatedOrdersCharLength,
        totalCharCount,
        orderPrincipal,
        orderType,
        dealOwner,
        setLoading,
        setIsRush,
        setIsConfidential,
        setProjectName,
        setOrderFiles,
        setSelectedTeam,
        setSelectedProductType,
        setSalesSuffix,
        setSelectedClient,
        setSelectedMarketingRep1,
        setSelectedMarketingRep2,
        setSelectedMarketingRep3,
        setSelectedFunder,
        setSelectedAccountRep,
        setSelectedClientPointOfContactType: setSelectedClientPointOfContactTypeAndMarketingSource,
        setVestingList,
        setLoans: setLoansAndMarketingSource,
        setOrderNumber,
        setIdentifier,
        setPurchasePrice,
        setTransactionType,
        setSettlementStatementType,
        setProperties,
        setPropertyUse,
        setPriorPolicyFiles,
        setSelectedPriorPolicyUnderwriter,
        setPriorPolicyAmount,
        setPriorPolicyDate,
        setRelatedOrders,
        setSelectedUnderwriter,
        setSelectedTitleCompany,
        setSettlementDate,
        setSelectedSettlementAgent,
        setSettlementAgentList,
        setMatchedOrders,
        setNotes,
        setSellers,
        setSellersAttorney: setSellersAttorneyAndMarketingSource,
        setSellersParalegal,
        setBuyers,
        setBuyersAttorney: setBuyersAttorneyAndMarketingSource,
        setBuyersParalegal,
        setAdditionalContacts,
        setErrorMessage,
        setAutoFilled,
        submitOrder,
        getAndSetMatchedOrders,
        getAndSetMatchedOrdersByOrderNumber,
        setOrderPrincipal,
        getContact,
        setOrderType,
        didFileUploadSucceed,
        patriotSearchResult,
        searchForPatriotsAndBlackList,
        blackListSearchResults,
        selectedBuyerPrincipals,
        setSelectedBuyerPrincipals: setSelectedBuyerPrincipalsAndMarketingSource,
        onContactSearchTextChanged,
        selectedSellerPrincipals,
        setSelectedSellerPrincipals: setSelectedSellerPrincipalsAndMarketingSource,
        setSelectedBuyerSellerPrincipalList,
        selectedBuyerSellerPrincipalList,
        setDealOwner,
        isOpenWarningModal,
        setIsOpenWarningModal,
        getFullNameForBlacklistAndPatriotSearching,
        currentBlacklistSearchRequestsCount,
        openAsSubfile,
        setOpenAsSubfile,
        subfileSuffix,
        setSubfileSuffix
      }}
    >
      {children}
    </OrderContext.Provider>
  );
}

export { OrderContext, OrderContextProvider };
