import { Grid, IconButton, Tooltip, Typography } from "@mui/material";
import { useContext, useState } from "react";
import isBlank from "../../utils/isBlank";
import { OrderContext } from "../../contexts/OrderDataContext";
import { Address } from "../types/Party";
import { PropertyInfo } from "../types/Property";
import { Buyer } from "./MatchedOrder";
import Iconify from "../../../minimals/components/iconify";

type Prop = {
  isOldOrder?: boolean;
  party: Buyer;
};

export default function MatchedParty({ party, isOldOrder }: Prop) {
  const { buyers, setBuyers, sellers, setSellers, transactionType, autoFilled, setAutoFilled } =
    useContext(OrderContext);

  let partyType: "Individual" | "Joint" | "Organization" | null = null;
  if (party.individual) {
    partyType = "Individual";
  } else if (party.joint) {
    partyType = "Joint";
  } else if (party.organization) {
    partyType = "Organization";
  }

  const setMatchedParty = () => {
    if (transactionType === "Purchase") {
      if (
        (partyType === "Organization" &&
          sellers[0].organizationName &&
          sellers[0].organizationName != "Certify as Found") ||
        sellers[0].firstName1 != "Certify as Found" ||
        (partyType != "Organization" && sellers[0].lastName1)
      ) {
        setSellers([
          ...sellers,
          {
            type: (partyType === "Joint" && "Individual") || partyType,
            isJoint: partyType === "Joint",
            firstName1: party.individual?.firstName || party.joint?.jointOne.firstName,
            middleName1: party.individual?.middleName || party.joint?.jointOne.middleName,
            lastName1: party.individual?.lastName || party.joint?.jointOne.lastName,
            gender1: party.joint?.jointOne.gender,
            firstName2: party.joint?.jointTwo.firstName,
            middleName2: party.joint?.jointTwo.middleName,
            lastName2: party.joint?.jointTwo.lastName,
            gender2: party.joint?.jointTwo.gender,
            organizationType: party.organization?.organizationType?.replace(/([a-z])([A-Z])/g, "$1 $2") || null,
            organizationName: party.organization?.name,
            organizationPayeeName: party.organization?.payeeName,
            organizationPayeeSameAsName: party.organization?.name === party.organization?.payeeName,
            relationshipType: party.joint?.relationshipType,
            address: {
              email: party.individual?.address?.email || party.organization?.address?.email || null,
              phone:
                party.individual?.address?.phone ||
                party.organization?.address?.phone ||
                party.joint?.jointOne?.address?.phone ||
                party.joint?.jointTwo?.address?.phone ||
                null,
              propertyInfo: {
                address:
                  party.individual?.address?.address1 ||
                  party.organization?.address?.address1 ||
                  party.joint?.jointOne?.address?.address1 ||
                  party.joint?.jointTwo?.address?.address1 ||
                  null,
                aptNo:
                  party.individual?.address?.address2 ||
                  party.organization?.address?.address2 ||
                  party.joint?.jointOne?.address?.address2 ||
                  party.joint?.jointTwo?.address?.address2 ||
                  null,
                city:
                  party.individual?.address?.city ||
                  party.organization?.address?.city ||
                  party.joint?.jointOne?.address?.city ||
                  party.joint?.jointTwo?.address?.city ||
                  null,
                state:
                  party.individual?.address?.state ||
                  party.organization?.address?.state ||
                  party.joint?.jointOne?.address?.state ||
                  party.joint?.jointTwo?.address?.state ||
                  null,
                zipCode:
                  party.individual?.address?.zip ||
                  party.organization?.address?.zip ||
                  party.joint?.jointOne?.address?.zip ||
                  party.joint?.jointTwo?.address?.zip ||
                  null
              } as PropertyInfo
            } as Address
          }
        ]);
      } else {
        setSellers([
          {
            type: (partyType === "Joint" && "Individual") || partyType,
            isJoint: partyType === "Joint",
            firstName1: party.individual?.firstName || party.joint?.jointOne.firstName,
            middleName1: party.individual?.middleName || party.joint?.jointOne.middleName,
            lastName1: party.individual?.lastName || party.joint?.jointOne.lastName,
            gender1: party.joint?.jointOne.gender,
            firstName2: party.joint?.jointTwo.firstName,
            middleName2: party.joint?.jointTwo.middleName,
            lastName2: party.joint?.jointTwo.lastName,
            gender2: party.joint?.jointTwo.gender,
            organizationType: party.organization?.organizationType?.replace(/([a-z])([A-Z])/g, "$1 $2") || null,
            organizationName: party.organization?.name,
            organizationPayeeName: party.organization?.payeeName,
            organizationPayeeSameAsName: party.organization?.name === party.organization?.payeeName,
            relationshipType: party.joint?.relationshipType,
            address: {
              email: party.individual?.address?.email || party.organization?.address?.email || null,
              phone:
                party.individual?.address?.phone ||
                party.organization?.address?.phone ||
                party.joint?.jointOne?.address?.phone ||
                party.joint?.jointTwo?.address?.phone ||
                null,
              propertyInfo: {
                address:
                  party.individual?.address?.address1 ||
                  party.organization?.address?.address1 ||
                  party.joint?.jointOne?.address?.address1 ||
                  party.joint?.jointTwo?.address?.address1 ||
                  null,
                aptNo:
                  party.individual?.address?.address2 ||
                  party.organization?.address?.address2 ||
                  party.joint?.jointOne?.address?.address2 ||
                  party.joint?.jointTwo?.address?.address2 ||
                  null,
                city:
                  party.individual?.address?.city ||
                  party.organization?.address?.city ||
                  party.joint?.jointOne?.address?.city ||
                  party.joint?.jointTwo?.address?.city ||
                  null,
                state:
                  party.individual?.address?.state ||
                  party.organization?.address?.state ||
                  party.joint?.jointOne?.address?.state ||
                  party.joint?.jointTwo?.address?.state ||
                  null,
                zipCode:
                  party.individual?.address?.zip ||
                  party.organization?.address?.zip ||
                  party.joint?.jointOne?.address?.zip ||
                  party.joint?.jointTwo?.address?.zip ||
                  null
              } as PropertyInfo
            } as Address
          }
        ]);
        setAutoFilled({
          ...autoFilled,
          seller: {
            ...autoFilled.seller,
            firstName1: { autoFilled: false, reason: "" },
            lastName1: { autoFilled: false, reason: "" },
            firstName2: { autoFilled: false, reason: "" },
            lastName2: { autoFilled: false, reason: "" },
            organizationName: { autoFilled: false, reason: "" }
          }
        });
      }
      const relatedOrdersElement = document.getElementById(`seller${sellers.length - 1}`);
      relatedOrdersElement?.scrollIntoView({
        behavior: "smooth",
        block: "center"
      });
      setIsHovered(false);
    } else {
      if (
        (partyType === "Organization" && buyers[0].organizationName && buyers[0].organizationName != "TBD") ||
        buyers[0].firstName1 != "TBD" ||
        (partyType != "Organization" && buyers[0].lastName1)
      ) {
        setBuyers([
          ...buyers,
          {
            type: (partyType === "Joint" && "Individual") || partyType,
            isJoint: partyType === "Joint",
            firstName1: party.individual?.firstName || party.joint?.jointOne.firstName,
            middleName1: party.individual?.middleName || party.joint?.jointOne.middleName,
            lastName1: party.individual?.lastName || party.joint?.jointOne.lastName,
            gender1: party.joint?.jointOne.gender,
            firstName2: party.joint?.jointTwo.firstName,
            middleName2: party.joint?.jointTwo.middleName,
            lastName2: party.joint?.jointTwo.lastName,
            gender2: party.joint?.jointTwo.gender,
            organizationType: party.organization?.organizationType?.replace(/([a-z])([A-Z])/g, "$1 $2") || null,
            organizationName: party.organization?.name,
            organizationPayeeName: party.organization?.payeeName,
            organizationPayeeSameAsName: party.organization?.name === party.organization?.payeeName,
            relationshipType: party.joint?.relationshipType,
            address: {
              email: party.individual?.address?.email || party.organization?.address?.email || null,
              phone:
                party.individual?.address?.phone ||
                party.organization?.address?.phone ||
                party.joint?.jointOne?.address?.phone ||
                party.joint?.jointTwo?.address?.phone ||
                null,
              propertyInfo: {
                address:
                  party.individual?.address?.address1 ||
                  party.organization?.address?.address1 ||
                  party.joint?.jointOne?.address?.address1 ||
                  party.joint?.jointTwo?.address?.address1 ||
                  null,
                aptNo:
                  party.individual?.address?.address2 ||
                  party.organization?.address?.address2 ||
                  party.joint?.jointOne?.address?.address2 ||
                  party.joint?.jointTwo?.address?.address2 ||
                  null,
                city:
                  party.individual?.address?.city ||
                  party.organization?.address?.city ||
                  party.joint?.jointOne?.address?.city ||
                  party.joint?.jointTwo?.address?.city ||
                  null,
                state:
                  party.individual?.address?.state ||
                  party.organization?.address?.state ||
                  party.joint?.jointOne?.address?.state ||
                  party.joint?.jointTwo?.address?.state ||
                  null,
                zipCode:
                  party.individual?.address?.zip ||
                  party.organization?.address?.zip ||
                  party.joint?.jointOne?.address?.zip ||
                  party.joint?.jointTwo?.address?.zip ||
                  null
              } as PropertyInfo
            } as Address
          }
        ]);
      } else {
        setBuyers([
          {
            type: (partyType === "Joint" && "Individual") || partyType,
            isJoint: partyType === "Joint",
            firstName1: party.individual?.firstName || party.joint?.jointOne.firstName,
            middleName1: party.individual?.middleName || party.joint?.jointOne.middleName,
            lastName1: party.individual?.lastName || party.joint?.jointOne.lastName,
            gender1: party.joint?.jointOne.gender,
            firstName2: party.joint?.jointTwo.firstName,
            middleName2: party.joint?.jointTwo.middleName,
            lastName2: party.joint?.jointTwo.lastName,
            gender2: party.joint?.jointTwo.gender,
            organizationType: party.organization?.organizationType?.replace(/([a-z])([A-Z])/g, "$1 $2") || null,
            organizationName: party.organization?.name,
            organizationPayeeName: party.organization?.payeeName,
            organizationPayeeSameAsName: party.organization?.name === party.organization?.payeeName,
            relationshipType: party.joint?.relationshipType,
            address: {
              email: party.individual?.address?.email || party.organization?.address?.email || null,
              phone:
                party.individual?.address?.phone ||
                party.organization?.address?.phone ||
                party.joint?.jointOne?.address?.phone ||
                party.joint?.jointTwo?.address?.phone ||
                null,
              propertyInfo: {
                address:
                  party.individual?.address?.address1 ||
                  party.organization?.address?.address1 ||
                  party.joint?.jointOne?.address?.address1 ||
                  party.joint?.jointTwo?.address?.address1 ||
                  null,
                aptNo:
                  party.individual?.address?.address2 ||
                  party.organization?.address?.address2 ||
                  party.joint?.jointOne?.address?.address2 ||
                  party.joint?.jointTwo?.address?.address2 ||
                  null,
                city:
                  party.individual?.address?.city ||
                  party.organization?.address?.city ||
                  party.joint?.jointOne?.address?.city ||
                  party.joint?.jointTwo?.address?.city ||
                  null,
                state:
                  party.individual?.address?.state ||
                  party.organization?.address?.state ||
                  party.joint?.jointOne?.address?.state ||
                  party.joint?.jointTwo?.address?.state ||
                  null,
                zipCode:
                  party.individual?.address?.zip ||
                  party.organization?.address?.zip ||
                  party.joint?.jointOne?.address?.zip ||
                  party.joint?.jointTwo?.address?.zip ||
                  null
              } as PropertyInfo
            } as Address
          }
        ]);
        setAutoFilled({
          ...autoFilled,
          buyer: {
            ...autoFilled.buyer,
            firstName1: { autoFilled: false, reason: "" },
            lastName1: { autoFilled: false, reason: "" },
            firstName2: { autoFilled: false, reason: "" },
            lastName2: { autoFilled: false, reason: "" },
            organizationName: { autoFilled: false, reason: "" }
          }
        });
      }
      const relatedOrdersElement = document.getElementById(`buyer${buyers.length - 1}`);
      relatedOrdersElement?.scrollIntoView({
        behavior: "smooth",
        block: "center"
      });
      setIsHovered(false);
    }
  };

  const [isHovered, setIsHovered] = useState<boolean>(false);

  const checkIfImported = () => {
    if (transactionType === "Purchase") {
      if (
        partyType === "Individual" &&
        sellers.some((s) => s.firstName1 === party.individual.firstName && s.lastName1 === party.individual.lastName)
      ) {
        return true;
      } else if (partyType === "Organization" && sellers.some((s) => s.organizationName === party.organization.name)) {
        return true;
      } else if (
        partyType === "Joint" &&
        sellers.some(
          (s) =>
            s.firstName1 === party.joint.jointOne.firstName &&
            s.lastName1 === party.joint.jointOne.lastName &&
            s.firstName2 === party.joint.jointTwo.firstName &&
            s.lastName2 === party.joint.jointTwo.lastName
        )
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      if (
        partyType === "Individual" &&
        buyers.some((b) => b.firstName1 === party.individual.firstName && b.lastName1 === party.individual.lastName)
      ) {
        return true;
      } else if (partyType === "Organization" && buyers.some((b) => b.organizationName === party.organization.name)) {
        return true;
      } else if (
        partyType === "Joint" &&
        buyers.some(
          (b) =>
            b.firstName1 === party.joint.jointOne.firstName &&
            b.lastName1 === party.joint.jointOne.lastName &&
            b.firstName2 === party.joint.jointTwo.firstName &&
            b.lastName2 === party.joint.jointTwo.lastName
        )
      ) {
        return true;
      } else {
        return false;
      }
    }
  };
  const isImported = checkIfImported();

  if (partyType === "Individual") {
    return (
      <Grid container marginTop={3}>
        <Grid item xs={2}>
          {!isImported && !isOldOrder && (
            <IconButton
              color={isHovered ? "primary" : "primary"}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              onClick={!isOldOrder ? setMatchedParty : () => null}
            >
              {!isOldOrder && isHovered && <Iconify icon={"eva:plus-square-fill"} />}
              {!isHovered && !isOldOrder && <Iconify icon={"eva:person-outline"} />}
            </IconButton>
          )}
          {isImported && (
            <IconButton color="success">
              <Iconify icon={"eva:checkmark-fill"} />
            </IconButton>
          )}
          {isOldOrder && (
            <Tooltip title="Please use copy and paste since importing is not available on old orders.">
              <IconButton color="primary">
                <Iconify icon={"eva:person-outline"} />
              </IconButton>
            </Tooltip>
          )}
        </Grid>
        <Grid item xs={10}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="caption">Owner</Typography>
              <Typography>
                {party.individual.firstName} {party.individual.lastName}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  } else if (partyType === "Organization" && !isBlank(party.organization.name)) {
    return (
      <Grid container marginTop={3}>
        <Grid item xs={2}>
          {!isImported && (
            <IconButton
              color={isHovered ? "primary" : "primary"}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              onClick={setMatchedParty}
            >
              {isHovered && <Iconify icon={"eva:plus-square-fill"} />}
              {!isHovered && <Iconify icon={"eva:person-outline"} />}
            </IconButton>
          )}
          {isImported && (
            <IconButton color="success">
              <Iconify icon={"eva:checkmark-fill"} />
            </IconButton>
          )}
        </Grid>
        <Grid item xs={10}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="caption">Owner</Typography>
              <Typography>{party.organization.name}</Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  } else if (partyType === "Joint") {
    return (
      <Grid container marginTop={3}>
        <Grid item xs={2}>
          {!isImported && (
            <IconButton
              color={isHovered ? "primary" : "primary"}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
              onClick={setMatchedParty}
            >
              {isHovered && <Iconify icon={"eva:plus-square-fill"} />}
              {!isHovered && <Iconify icon={"eva:person-outline"} />}
            </IconButton>
          )}
          {isImported && (
            <IconButton color="success">
              <Iconify icon={"eva:checkmark-fill"} />
            </IconButton>
          )}
        </Grid>
        <Grid item xs={10}>
          <Grid container>
            <Grid item xs={12}>
              <Typography variant="caption">Owners</Typography>
              <Typography>
                {party.joint.jointOne.firstName} {party.joint.jointOne.lastName} &
              </Typography>
              <Typography>
                {party.joint.jointTwo.firstName} {party.joint.jointTwo.lastName}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  } else return <></>;
}
