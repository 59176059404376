import { Box, Card, Dialog, DialogTitle, DialogContent, Button, DialogActions } from "@mui/material";
import { useContext } from "react";
import BlackListSearchAlert from "../contacts/BlackListSearchAlert";
import PatriotSearchResultAlert from "../contacts/PatriotSearchResultAlert";
import { OrderContext } from "../contexts/OrderDataContext";

export default function OrderWarningsDialog() {
  const { patriotSearchResult, blackListSearchResults, isOpenWarningModal, setIsOpenWarningModal } =
    useContext(OrderContext);

  if (!patriotSearchResult?.length && !blackListSearchResults?.length) {
    return null;
  }

  return (
    <Dialog
      open={isOpenWarningModal}
      onClose={() => console.log("Dialog closed")}
      aria-labelledby="order-warning-dialog"
      aria-describedby="order-warning-dialog-description"
      PaperProps={{ sx: { color: "warning.contrastText", boxShadow: 24 } }}
    >
      <DialogTitle id="order-warning-dialog">Important Warning!!</DialogTitle>
      <DialogContent dividers>
        <Box>
          {patriotSearchResult.length > 0 && <PatriotSearchResultAlert patriotSearchResult={patriotSearchResult} />}
          {blackListSearchResults.length > 0 && (
            <BlackListSearchAlert blackListSearchResults={blackListSearchResults} />
          )}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setIsOpenWarningModal(false)}>I understand</Button>
      </DialogActions>
    </Dialog>
  );
}
