import { Card, Typography, CardHeader, Stack, Box, Link, CardContent } from "@mui/material";
import { useContext } from "react";
// import Phone from "../../shared/phones/Phone";
import { Company, Phone as IPhone } from "../types";
import { StyledIcon } from "../../components/StyledIcon";
import Phone from "../shared/phones/Phone";
import { Link as RouterLink } from "react-router-dom";
import Label from "../../../minimals/components/label/Label";
// ----------------------------------------------------------------------

const covertToTypePhone = (number: string, countryCode: string, extension: string, type: string): IPhone => {
  return {
    number: number || "",
    extension: extension || "",
    countryCode: countryCode || "",
    type: type || "",
    key: ""
  };
};

export default function CompanyInfoCard({ company }: { company: Company }) {
  if (!company) {
    return <></>;
  }

  const { name, email, website, phone, phoneCountryCode, fax, faxCountryCode, companyType, emailPolicyTo } = company;
  const phoneObj = covertToTypePhone(phone, phoneCountryCode, "", "Phone");
  const faxObj = covertToTypePhone(fax, faxCountryCode, "", "Fax");
  return (
    <Card sx={{ minHeight: 200 }}>
      {company && !company.isActive && (
        <Label variant="soft" color="error">
          Inactive
        </Label>
      )}
      <CardHeader
        title={
          <>
            <RouterLink
              to={`../phonebook/company/profile?id=${company?.id}`}
              style={{
                textDecoration: "none",
                color: "#000000"
              }}
            >
              {name}
            </RouterLink>
            {companyType?.name && <Typography variant="body2">{companyType?.name}</Typography>}
          </>
        }
      />
      <Stack spacing={2} sx={{ p: 3 }}>
        {email && (
          <Stack direction="row">
            <StyledIcon icon="eva:email-fill" />
            <Typography variant="body2">
              <Link color="initial" href={`mailto:${email}`}>
                {email}
              </Link>
            </Typography>
          </Stack>
        )}
        {phoneObj.number && <Phone phone={phoneObj} />}
        {faxObj.number && <Phone phone={faxObj} />}
        {website && (
          <Stack direction="row">
            <StyledIcon icon="eva:globe-3-fill" color="#0000EE" />
            <Typography variant="body2">
              <Link color="#0000EE" target="blank" href={`https://${website}`}>
                {website}
              </Link>
            </Typography>
          </Stack>
        )}
        {emailPolicyTo && (
          <Stack direction="row">
            <Typography variant="body2">
              <strong>Email Policy To:</strong> {emailPolicyTo}
            </Typography>
          </Stack>
        )}
      </Stack>
    </Card>
  );
}
