import { Typography, Card, Stack, styled, Button } from "@mui/material";
import { useContacts } from "../../contexts/ContactsContext";
import { Link, useNavigate } from "react-router-dom";
import Highlighter from "react-highlight-words";
import { Contact } from "../../orderEntry/types/Contact";
import Iconify from "../../../minimals/components/iconify";
import ConditionalLink from "../../components/ConditionalLink";
import { highlightStyles } from "../../utils/highlightStyles";

const IconStyle = styled(Iconify)(({ theme }) => ({
  width: 20,
  height: 20,
  marginTop: 1,
  flexShrink: 0,
  marginRight: theme.spacing(2)
}));

type NewClientProps = {
  firstName: string | null | undefined;
  lastName: string | null | undefined;
  email: string | null | undefined;
  phone: string | null | undefined;
  cell: string | null | undefined;
  fax: string | null | undefined;
};

export default function DuplicateClientCard({
  duplicateClient,
  setDuplicateClientList,
  newClient,
  duplicateButtonText,
  onAfterSubmitFunction
}: {
  duplicateClient: Contact;
  setDuplicateClientList: React.Dispatch<React.SetStateAction<Contact[]>>;
  newClient: NewClientProps;
  duplicateButtonText?: string;
  onAfterSubmitFunction?: (contact: any) => void;
}) {
  const { setNewContact, setSubmittedStatus } = useContacts();
  const navigate = useNavigate();

  const phoneSearchWords = newClient?.phone && newClient.phone;
  const cellSearchWords = newClient?.cell && newClient.cell;
  const faxSearchWords = newClient?.fax && newClient.fax;

  const allPhoneSearchWords = [phoneSearchWords!, cellSearchWords!, faxSearchWords!];
  return (
    <Card sx={{ marginBottom: 2, bgcolor: "warning.lighter" }}>
      <Stack spacing={2} sx={{ p: 3 }}>
        <Typography variant="h6">
          <Highlighter
            textToHighlight={duplicateClient.name || ""}
            highlightStyle={highlightStyles}
            searchWords={[newClient.firstName || "", newClient.lastName || ""]}
          />
        </Typography>
        {duplicateClient?.role && (
          <Typography variant="body2">
            {duplicateClient?.role} {duplicateClient?.company && "at"}{" "}
            <span style={{ fontWeight: 600 }}> {duplicateClient?.company}</span>
          </Typography>
        )}
        <Stack direction="row">
          {(duplicateClient.address1 || duplicateClient.address2) && (
            <>
              <IconStyle icon={"eva:pin-fill"} />
              <Typography sx={{ fontWeight: 400 }}>
                {duplicateClient?.company && <Typography variant="subtitle2">{duplicateClient.company}</Typography>}
                {duplicateClient?.address1 && <Typography variant="subtitle2">{duplicateClient.address1}</Typography>}
                {duplicateClient?.address2 && <Typography variant="subtitle2">{duplicateClient.address2}</Typography>}
                <Typography variant="subtitle2">
                  {duplicateClient.city} {duplicateClient.state} {duplicateClient.zip}
                </Typography>
              </Typography>
            </>
          )}
        </Stack>

        {duplicateClient.email && (
          <Stack direction="row">
            <IconStyle icon={"eva:email-fill"} />
            <Typography variant="body2">
              <Highlighter
                textToHighlight={duplicateClient.email || ""}
                highlightStyle={highlightStyles}
                searchWords={[newClient.email || ""]}
              />
            </Typography>
          </Stack>
        )}

        {duplicateClient.phone && (
          <Stack direction="row">
            <IconStyle icon={"carbon:phone-filled"} />
            <Typography variant="body2">
              <Highlighter
                textToHighlight={duplicateClient.phone || ""}
                highlightStyle={highlightStyles}
                searchWords={allPhoneSearchWords}
                autoEscape={true}
              />
            </Typography>
          </Stack>
        )}

        {duplicateClient.cell && (
          <Stack direction="row">
            <IconStyle icon={"carbon:phone-filled"} />
            <Typography variant="body2">
              <Highlighter
                textToHighlight={duplicateClient.cell || ""}
                highlightStyle={highlightStyles}
                searchWords={allPhoneSearchWords}
                autoEscape={true}
              />
            </Typography>
          </Stack>
        )}
        {duplicateClient.fax && (
          <Stack direction="row">
            <IconStyle icon={"carbon:phone-filled"} />
            <Typography variant="body2">
              <Highlighter
                textToHighlight={duplicateClient.fax || ""}
                highlightStyle={highlightStyles}
                searchWords={allPhoneSearchWords}
                autoEscape={true}
              />
            </Typography>
          </Stack>
        )}
        <div style={{ textAlign: "center", marginTop: "24px" }}>
          {duplicateButtonText && (
            <Button
              size="small"
              onClick={() => onAfterSubmitFunction && onAfterSubmitFunction(duplicateClient)}
              variant="outlined"
              sx={{ display: "inline-flex", marginRight: "8px" }}
            >
              {duplicateButtonText}
            </Button>
          )}

          <ConditionalLink
            condition={!duplicateClient.isDynamicsContact}
            style={{ textDecoration: "none" }}
            to={`/contacts/edit/${duplicateClient.lookupCode}`}
            target="_blank"
            rel="noreferrer"
          >
            <Button
              size="small"
              variant="outlined"
              sx={{ width: duplicateButtonText ? undefined : "100%" }}
              {...(duplicateClient.isDynamicsContact && {
                onClick: () => setNewContact(duplicateClient)
              })}
            >
              {!duplicateClient.isDynamicsContact ? "View" : "Import from Dynamics"}
            </Button>
          </ConditionalLink>
        </div>
      </Stack>
    </Card>
  );
}
