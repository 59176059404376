import { Grid, Typography, Skeleton, Chip } from "@mui/material";
import { fCurrency } from "../../utils/formatNumber";
import { fMonthDayYearSlashNotation } from "../../utils/formatTime";
import { useOrderInfo } from "../../shared/orderInfo/OrderInfoContext";
import StatusAndDate from "./StatusAndDate";

const Order = () => {
  const orderInfo = useOrderInfo();
  return (
    <Grid container width={320} columnSpacing={3} sx={{ "&:first-child": { marginLeft: "-24px" } }}>
      <Grid item xs={6}>
        <Typography variant="subtitle1">
          {orderInfo?.propertyUse} {!orderInfo && <Skeleton />}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="subtitle1">
          {orderInfo?.transactionType}
          {!orderInfo && <Skeleton />}
        </Typography>
      </Grid>
      {orderInfo?.commitmentDate && (
        <Grid item xs={6}>
          <Typography variant="caption">Commitment Date</Typography>
          <Typography variant="subtitle1">
            {orderInfo?.commitmentDate && fMonthDayYearSlashNotation(orderInfo?.commitmentDate)}
            {!orderInfo && <Skeleton />}
          </Typography>
        </Grid>
      )}
      {(orderInfo?.status || orderInfo?.settlementDate) && (
        <Grid item xs={6}>
          {orderInfo && <StatusAndDate order={orderInfo} />}
          {!orderInfo && <Skeleton />}
          {!orderInfo && <Skeleton />}
        </Grid>
      )}
      <Grid item xs={6}>
        <Typography variant="caption">Purchase Price</Typography>
        <Typography variant="subtitle1">
          {orderInfo && fCurrency(orderInfo?.salesPrice || 0)}
          {!orderInfo && <Skeleton />}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Typography variant="caption">Loan Amount</Typography>
        <Typography variant="subtitle1">
          {(orderInfo && orderInfo?.loanAmounts.map((l) => fCurrency(l)).join(", ")) || (orderInfo && "--")}
          {!orderInfo && <Skeleton />}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default Order;
