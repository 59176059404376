import { MRT_ColumnDef } from "material-react-table";
import { fMonthDayYearSlashNotation } from "../utils/formatTime";
import { Box, Chip, IconButton, Link, Stack, Typography } from "@mui/material";
import { fCurrency } from "../utils/formatNumber";
import { RecordableStatusCounts, TMEOrderDetails } from "./types";
import CustomStatusFilter from "./CustomMultiSelectFilter";
import Iconify from "../../minimals/components/iconify";
import { getStatusColor } from "../calendar/CalendarUtils";
import StatusCountsFilter from "./CustomRecordedDocumentsFilter";
import SingleDateRangeFilter from "./SingleDateRangeFilter";

export const tmeOrderColumns: MRT_ColumnDef<TMEOrderDetails>[] = [
  {
    id: "settlementDate",
    header: "Settlement Date",
    accessorKey: "settlementDate",
    filterVariant: "date",
    sortingFn: "datetime",
    filterFn: (row, id, filterValue) => {
      if (filterValue && filterValue[0] && filterValue[1] && !row.original.settlementDate) {
        return false;
      } else if (filterValue && filterValue[0] && filterValue[1] && row.original.settlementDate) {
        const settlementDate = new Date(row.original.settlementDate).getTime();
        const startDate = new Date(filterValue[0]).getTime();
        const endDate = new Date(filterValue[1]).getTime();

        return settlementDate >= startDate && settlementDate <= endDate;
      } else {
        return true;
      }
    },
    Filter: SingleDateRangeFilter,
    accessorFn: (row) => (row.settlementDate ? new Date(row.settlementDate) : null),
    Cell: ({ row }) => (
      <Stack direction="row" alignItems="center">
        <Typography variant="subtitle2" display="inline-block">
          {row.original.settlementDate ? fMonthDayYearSlashNotation(row.original.settlementDate) : null}
        </Typography>
        <EditLinkButton number={row.original.number} sx={{ position: "absolute", left: 84 }} />
      </Stack>
    ),
    muiFilterTextFieldProps: {
      variant: "outlined",
      size: "small"
    }
  },
  {
    id: "number",
    header: "Order #",
    accessorKey: "number",
    Cell: ({ row }) => (
      <Link variant="body2" href={`https://internal.mcres.com/new-home/${row.original.number}`} target="_blank">{`${
        row.original.prefix ? row.original.prefix + "-" : ""
      }${row.original.number}`}</Link>
    ),
    muiFilterTextFieldProps: {
      variant: "outlined",
      size: "small"
    },
    filterFn: (row, _, filterValue) => {
      const cellValue = `${row.original.prefix ? row.original.prefix + "-" : ""}${row.original.number}`;
      return cellValue.includes(filterValue);
    }
  },
  {
    id: "salesPrice",
    header: "Transaction",
    accessorKey: "salesPrice",
    accessorFn: (row) => `${fCurrency(row.salesPrice || "")}-${fCurrency(row.salesPrice || "")}`,
    Cell: ({ renderedCellValue, row }) => (
      <>
        {row.original.transactionType === "Purchase" && (
          <Typography variant="subtitle2">
            {row.original.salesPrice !== null ? `P ${fCurrency(row.original.salesPrice || "")}` : null}
          </Typography>
        )}
        <Typography variant="body2" color="grey">
          {row.original.loanAmount === 0 && "L ---"}
          {!!row.original.loanAmount && row.original.loanAmount > 0 && `L ${fCurrency(row.original.loanAmount || "")}`}
        </Typography>
      </>
    ),
    muiFilterTextFieldProps: {
      variant: "outlined",
      size: "small"
    }
  },
  {
    id: "propertyAddress",
    header: "Address/Project",
    accessorKey: "propertyAddress",
    accessorFn: (row) => `${row.projectName}-${row.propertyAddress}`,
    Cell: ({ renderedCellValue, row }) => (
      <Box width={200} position="relative" whiteSpace="pre-wrap">
        <Typography variant="subtitle2">{row.original.projectName}</Typography>
        <Typography variant="body2" color="grey">
          {row.original.propertyAddress}
        </Typography>
      </Box>
    ),
    muiFilterTextFieldProps: {
      variant: "outlined",
      size: "small"
    }
  },
  {
    id: "clientName",
    header: "Attorney/Client",
    accessorKey: "clientName",
    accessorFn: (row) => row.clientName,
    Cell: ({ renderedCellValue, row }) => <Typography variant="subtitle2">{row.original.clientName}</Typography>,
    muiFilterTextFieldProps: {
      variant: "outlined",
      size: "small"
    }
  },
  {
    id: "status",
    header: "Status",
    accessorKey: "status",
    Cell: ({ renderedCellValue, row }) => (
      <Stack direction="row" spacing={1} alignItems="center">
        <Chip
          variant="outlined"
          label={row.original.status}
          sx={{
            height: "24px",
            width: "100px",
            borderRadius: "6px",
            fontWeight: 600,
            borderColor: getStatusColor(row.original.status).color,
            color: getStatusColor(row.original.status).color
          }}
        />
        <EditLinkButton number={row.original.number} />
      </Stack>
    ),
    accessorFn: (row) => row.status,
    filterVariant: "multi-select",
    filterSelectOptions: [
      { text: "Firm", value: "Firm" },
      { text: "In process", value: "In process" },
      { text: "Transferred", value: "Transferred" },
      { text: "Closed", value: "Closed" },
      { text: "Canceled", value: "Canceled" },
      { text: "Unknown", value: "Unknown" },
      { text: "Dormant", value: "Dormant" }
    ],
    muiFilterTextFieldProps: {
      variant: "outlined",
      size: "small"
    },
    Filter: CustomStatusFilter
  },
  {
    id: "heldByFullName",
    header: "Closer",
    accessorKey: "heldByFullName",
    muiFilterTextFieldProps: {
      variant: "outlined",
      size: "small"
    }
  },
  {
    id: "funder",
    header: "Funder",
    accessorKey: "funder",
    muiFilterTextFieldProps: {
      variant: "outlined",
      size: "small"
    }
  },
  {
    id: "postCloserFullName",
    header: "Post Closer",
    accessorKey: "postCloserFullName",
    muiFilterTextFieldProps: {
      variant: "outlined",
      size: "small"
    }
  },
  {
    id: "placedByFullName",
    header: "Placed By",
    accessorKey: "placedByFullName",
    muiFilterTextFieldProps: {
      variant: "outlined",
      size: "small"
    }
  },
  // {
  //   id: "salesRepFullName",
  //   header: "Sales Rep",
  //   accessorKey: "salesRepFullName",
  //   muiTableHeadCellFilterTextFieldProps: {
  //     variant: "outlined",
  //     size: "small"
  //   }
  // },
  {
    id: "proFormaFullName",
    header: "Pro Forma",
    accessorKey: "proFormaFullName",
    muiFilterTextFieldProps: {
      variant: "outlined",
      size: "small"
    }
  },
  {
    id: "statusCounts",
    header: "Recorded Documents",
    accessorKey: "statusCounts",
    enableColumnFilter: true,
    filterVariant: "multi-select",
    Filter: ({ column }) => <StatusCountsFilter column={column} placeholder="Filter by Document Status" />,
    filterSelectOptions: [
      { text: "None Sent/Recorded", value: "None Sent/Recorded" },
      { text: "Rejected", value: "Rejected" },
      { text: "Some Sent/Recorded", value: "Some Sent/Recorded" },
      { text: "All Recorded", value: "All Recorded" },
      { text: "No Documents", value: "No Documents" },
      { text: "N/A", value: "N/A" }
    ],
    filterFn: (row, columnId, filterValue) => {
      const { notSent, sent, recorded, rejected } = row?.original?.statusCounts || {};
      const totalDocuments = notSent + sent + recorded + rejected;

      if (!filterValue.length) return true;

      return filterValue.some((value: any) => {
        switch (value) {
          case "N/A":
            return row?.original?.isRecordablesNotApplicable;
          case "Rejected":
            return rejected > 0;
          case "All Recorded":
            return recorded > 0 && recorded === totalDocuments;
          case "Some Sent/Recorded": //If some are recorded or some or all are sent
            return (recorded > 0 || sent > 0) && recorded < totalDocuments;
          case "None Sent/Recorded":
            return totalDocuments > 0 && recorded === 0 && sent === 0 && rejected == 0;
          case "No Documents":
            return totalDocuments === 0 && !row?.original?.isRecordablesNotApplicable;
          default:
            return true;
        }
      });
    },
    sortingFn: (rowA, rowB) => {

      // if(rowA.original.)
      const getStatusOrder = (statusCounts: RecordableStatusCounts, isRecordablesNotApplicable: boolean) => {
        const { notSent, sent, recorded, rejected } = statusCounts;
        const totalDocuments = notSent + sent + recorded + rejected;
        if (totalDocuments === 0 && isRecordablesNotApplicable) return 4;
        if (notSent > 0 && recorded === 0 && sent === 0) return 0;
        if (rejected > 0) return 1; // Rejected
        if (recorded > 0 && recorded < totalDocuments) return 2;
        if (recorded > 0 && recorded === totalDocuments) return 3;
        return 5;
      };

      const statusOrderA = getStatusOrder(
        rowA.original.statusCounts,
        Boolean(rowA?.original?.isRecordablesNotApplicable)
      );
      const statusOrderB = getStatusOrder(
        rowB.original.statusCounts,
        Boolean(rowB?.original?.isRecordablesNotApplicable)
      );
      return statusOrderA - statusOrderB;
    },
    Cell: ({ row }) => {
      const settlementDate = row?.original?.settlementDate;
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      const isSettlementDateAfterToday = settlementDate && new Date(settlementDate) < today;

      const { notSent, sent, recorded, rejected } = row?.original?.statusCounts;

      if ([notSent, sent, recorded, rejected].every((e) => e === 0) && row?.original?.isRecordablesNotApplicable) {
        return <Typography variant="subtitle2">N/A</Typography>;
      }

      return (
        <>
          <Link
            href={`/recordables/?order=${row.original.number}`}
            target="_blank"
            sx={{
              textDecoration: "none",
              color: "inherit",
              "&:hover": {
                textDecoration: "none",
                cursor: "pointer"
              }
            }}
          >
            {(notSent > 0 || rejected > 0) && (
              <>
                <Typography variant="subtitle2">
                  {rejected > 0 && (
                    <span style={{ color: "#EF5350", marginRight: rejected > 0 && notSent > 0 ? "8px" : "0" }}>
                      {rejected} Rejected
                    </span>
                  )}
                  {notSent > 0 && (
                    <span style={{ color: isSettlementDateAfterToday ? "#E77D22" : "default" }}>
                      {notSent} Not Sent
                    </span>
                  )}
                </Typography>
              </>
            )}
            {(recorded > 0 || sent > 0) && (
              <>
                <Typography variant="subtitle2">
                  {recorded > 0 && (
                    <span style={{ color: "#54D62C", marginRight: recorded > 0 && sent > 0 ? "8px" : "0" }}>
                      {recorded} Recorded
                    </span>
                  )}
                  {sent > 0 && <span style={{ color: "#FFC107" }}>{sent} Sent</span>}
                </Typography>
              </>
            )}
          </Link>
        </>
      );
    }
  }
];

const EditLinkButton = ({ number, sx }: { number: string; sx?: object }) => {
  return (
    <Link href={`/calendar?ordernumber=${number}&mode=edit`} target="_blank" sx={sx}>
      <IconButton>
        <Iconify icon={"eva:edit-outline"} />
      </IconButton>
    </Link>
  );
};
