import { Alert, Stack, Typography } from "@mui/material";
import { BlackListResult } from "../components/BlacklistNameSearch/types";

export default function BlackListSearchAlert({
  blackListSearchResults
}: {
  blackListSearchResults: BlackListResult[];
}) {
  const getNames = () => {
    if (!blackListSearchResults) {
      return "";
    }
    return blackListSearchResults.map((bl, index) => `${index + 1}) ${bl.information}`).join(", ");
  };
  return (
    <Alert severity="error">
      <Stack direction="row" alignItems="center" spacing={2}>
        <Typography variant="body2">
          DO NOT PROCEED! Blacklists matches found: {getNames()}.{" "}
          <span style={{ fontWeight: "bold", color: "red" }}>DO NOT CONTINUE </span>
          transaction until confirmed in writing with your manager!
        </Typography>
      </Stack>
    </Alert>
  );
}
