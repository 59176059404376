import { Alert, Autocomplete, Box, Card, Grid, TextField } from "@mui/material";
import { useEffect } from "react";
import { useContacts } from "../../contexts/ContactsContext";
import Notes from "../../orderEntry/Notes";
import ClientLoaderButton from "./ClientLoaderButton";

export default function Preferences({
  onNextClick,
  submitButtonText
}: {
  onNextClick: () => void;
  submitButtonText?: string;
}) {
  const { currentContact, newContact, isEditMode, selectUsers, editContact, submittedStatus, setSubmittedStatus } =
    useContacts();

  useEffect(() => {
    setSubmittedStatus("unsubmitted");
  }, []);

  const contact = isEditMode ? currentContact : newContact;
  return (
    <>
      <Grid container spacing={3}>
        {submittedStatus != "unsubmitted" && (
          <Grid marginBottom={2} item xs={8}>
            {submittedStatus === "success" && (
              <Alert style={{ maxWidth: 712, fontWeight: "bold" }}>Your contact was saved successfully</Alert>
            )}
            {submittedStatus === "error" && (
              <Alert severity="error" style={{ maxWidth: 712, fontWeight: "bold" }}>
                There is an error with your submission
              </Alert>
            )}
          </Grid>
        )}
      </Grid>
      <Card
        sx={{
          p: 4,
          maxWidth: 712,
          display: "flex",
          flexDirection: "column",
          overflow: "visible"
        }}
      >
        <Box>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Autocomplete
                disablePortal
                options={selectUsers}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      {option.displayName}
                    </li>
                  );
                }}
                getOptionLabel={(option) => option.displayName || ""}
                onChange={(e, value) => editContact("internalDriver", value?.name)}
                renderInput={(params) => <TextField {...params} label="NY Account Rep" />}
                value={selectUsers.find((item) => item.name === contact?.internalDriver) || null}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                disablePortal
                options={selectUsers}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      {option.displayName}
                    </li>
                  );
                }}
                getOptionLabel={(option) => option.displayName || ""}
                onChange={(e, value) => editContact("nationalInternalDriver", value?.name)}
                renderInput={(params) => <TextField {...params} label="National Account Rep" />}
                value={selectUsers.find((item) => item.name === contact?.nationalInternalDriver) || null}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Autocomplete
                disablePortal
                options={selectUsers}
                renderOption={(props, option) => {
                  return (
                    <li {...props} key={option.id}>
                      {option.displayName}
                    </li>
                  );
                }}
                getOptionLabel={(option) => option.displayName || ""}
                renderInput={(params) => <TextField {...params} label="NJ Account Rep" />}
                value={null}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              {/* <Notes
                notes={contact?.notes}
                onChange={(note) => editContact("notes", note)}
              /> */}
            </Grid>
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end"
              }}
            >
              <ClientLoaderButton onNextClick={onNextClick} submitAll={true} submitButtonText={submitButtonText} />
            </div>
          </Grid>
        </Box>
      </Card>
    </>
  );
}
