import {
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  DialogActions,
  Button,
  Box,
  TextField,
  Stack,
  Autocomplete
} from "@mui/material";
import axios from "axios";
import { useState } from "react";
import { BasicTask } from "./types";

const AddTaskModal = ({
  open,
  closeModal,
  applicationId,
  orderNumber,
  refreshTasks,
  tasks
}: {
  open: boolean;
  closeModal: () => void;
  applicationId: number;
  orderNumber: string | null;
  refreshTasks: () => Promise<void>;
  tasks: BasicTask[];
}) => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [description, setDescription] = useState<string>("");
  const [selectedParentTaskId, setSelectedParentId] = useState<number | null>(null);

  const save = async () => {
    setSubmitting(true);
    await axios.post("/api/taskmanagement/tasks/add", {
      applicationId,
      orderNumber,
      description,
      parentTaskId: selectedParentTaskId
    });
    refreshTasks();
    closeModal();
    setSubmitting(false);
  };

  return (
    <Dialog open={open} onClose={closeModal} fullWidth maxWidth="sm">
      <DialogTitle>
        <Typography variant="subtitle1" marginBottom={3}>
          New Task
        </Typography>
      </DialogTitle>
      <DialogContent sx={{ paddingBottom: 3 }}>
        <Box paddingTop={1}>
          <Stack direction="column" spacing={2}>
            <Autocomplete
              options={tasks}
              renderInput={(params) => <TextField {...params} label="Parent Task (optional)" />}
              onChange={(_, val) => setSelectedParentId(val?.id || null)}
              getOptionLabel={(option) => option.description}
            />
            <TextField
              fullWidth
              label="Task Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Stack>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={closeModal} color="inherit">
          Cancel
        </Button>
        <Button variant="contained" disabled={submitting} onClick={save}>
          {submitting ? "Saving...." : "Save"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddTaskModal;
