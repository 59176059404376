import { MRT_ColumnDef } from "material-react-table";
import { IconButton, Link, Typography } from "@mui/material";
import { ContactWithSuspectedMatch } from "../../phonebook/types";
import NavigationIconButton from "./NavigateIcon";

export const suspectedMatchesColums: MRT_ColumnDef<ContactWithSuspectedMatch>[] = [
  {
    header: "Date/Time Created",
    accessorKey: "createdDate",
    accessorFn: (row) => {
      return new Date(row.createdDate).toLocaleDateString();
    },
    muiTableBodyCellProps: {
      sx: {
        verticalAlign: "top"
      }
    },
    muiFilterTextFieldProps: {
      variant: "outlined",
      size: "small"
    }
  },
  {
    header: "Contact",
    accessorFn: (row) => {
      const isContact = row.name;
      return (
        <Link target="_blank" rel="noreferrer" href={`/phonebook/contact/profile?id=${row.id}`}>
          {row.name || ""}
        </Link>
      );
    },
    muiTableBodyCellProps: {
      sx: {
        verticalAlign: "top"
      }
    },
    muiFilterTextFieldProps: {
      variant: "outlined",
      size: "small"
    }
  },

  {
    id: "suspectedMatches",
    header: "Suspected Duplicates",
    size: 200,
    accessorFn: (row) => {
      return row?.suspectedMatches?.map((s) => (
        <Link target="_blank" rel="noreferrer" href={`/phonebook/contact/profile?id=${s.contact?.id}`}>
          <Typography variant="body2" color="error">
            {s.contact?.firstName || ""} {s.contact?.lastName}
          </Typography>
        </Link>
      ));
    },
    muiFilterTextFieldProps: {
      variant: "outlined",
      size: "small"
    }
  },
  {
    id: "matchingField",
    header: "Matching Field",
    size: 200,
    accessorFn: (row) => {
      return row?.suspectedMatches?.map((s) => (
        <Typography variant="body2" color="error">
          {s.matchedOnBusinessEmail && "Matched on Email"}
          {s.matchedOnName && "Matched on Name"}
          {s.matchedOnPhoneNumber && "Matched on Phone"}
        </Typography>
      ));
    },
    muiFilterTextFieldProps: {
      variant: "outlined",
      size: "small"
    }
  },
  {
    id: "merge",
    header: "Merge Contacts",
    size: 200,
    accessorFn: (row) => {
      return row?.suspectedMatches?.map((s) => (
        <NavigationIconButton
          to={`../phonebook/contactsMerge?ids=${row.id}&ids=${s.contact.id}`}
          openInNewTab={true}
          aria-label="Merge Contacts"
        />
      ));
    },
    muiFilterTextFieldProps: {
      variant: "outlined",
      size: "small"
    }
  }
];
