import { Button, Container } from "@mui/material";
import axios from "axios";
import { useEffect } from "react";
import { useParams } from "react-router";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useContacts } from "../contexts/ContactsContext";
import Page from "../../minimals/components/page";
import UserNewEditForm from "../contacts/new-contact";
import HeaderBreadcrumbs from "./HeaderBreadcrumbs";

interface IProps {
  lookupCode?: string | null;
}
export default function EditContact(props: IProps) {
  const { lookupCode } = useParams();
  const { setCurrentContact, isEditMode, setIsEditMode } = useContacts();
  const navigate = useNavigate();

  useEffect(() => {
    if (props.lookupCode) {
      setIsEditMode(true);
    }
  }, [props.lookupCode, isEditMode]);

  useEffect(() => {
    if (lookupCode || props.lookupCode) {
      axios.get(`/proxy/api/clients/search?searchQuery=${lookupCode || props.lookupCode}`).then(({ data }) => {
        if (data?.length > 0) {
          setCurrentContact(data[0]);
        } else {
          navigate(`../`);
        }
      });
    }
  }, [lookupCode, props.lookupCode]);

  return (
    <Page title="Edit Contact">
      <Container>
        {lookupCode && (
          <HeaderBreadcrumbs
            sx={{ maxWidth: 760 }}
            heading="Edit Contact"
            links={[]}
            action={
              <Button variant="contained" color="inherit" component={RouterLink} to={"/contacts"}>
                Back
              </Button>
            }
          />
        )}
        <UserNewEditForm />
      </Container>
    </Page>
  );
}
