import { Container } from "@mui/material";

const ExecutiveReports = () => {
  return (
    <Container sx={{ height: "calc(100vh - 200px)", width: "100%", maxWidth: "none !important" }}>
      <iframe
        title="Executive Reports"
        width="100%"
        height="100%"
        src="https://app.powerbi.com/reportEmbed?reportId=138d9776-e329-472b-96bb-b8660677599f&autoAuth=true&ctid=bbfeea83-3c0f-4fcc-838e-a0848f3fcb93"
        frameBorder={0}
        allowFullScreen
      ></iframe>
    </Container>
  );
};

export default ExecutiveReports;
