import { useState, useEffect } from "react";
import axios from "axios";
import { StatusType } from "../types";

const useStatusTypes = (applicationId: number) => {
  const [statusTypes, setStatusTypes] = useState<StatusType[]>([]);

  const getStatusTypes = async () => {
    const { data } = await axios.get(`/api/taskmanagement/tasks/getStatusTypes?applicationid=${applicationId}`);
    setStatusTypes(data);
  };

  useEffect(() => {
    getStatusTypes();
  }, [applicationId]);

  return { statusTypes };
};

export default useStatusTypes;
