import { Card, InputAdornment, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useContacts } from "../../contexts/ContactsContext";
import useDebounce from "../../../hooks/useDebounce";
import Iconify from "../../../minimals/components/iconify";
import ClientLoaderButton from "./ClientLoaderButton";

export default function Company({ onNextClick }: { onNextClick: () => void }) {
  const [searchText, setSearchText] = useState("");
  const debouncedSearchText = useDebounce(searchText, 500);
  const { getContacts, editContact, currentContact, newContact, isEditMode } = useContacts();

  const contact = isEditMode ? currentContact : newContact;
  useEffect(() => {
    if (debouncedSearchText) {
      getContacts(debouncedSearchText);
    }
  }, [debouncedSearchText]);

  return (
    <Card
      sx={{
        p: 4,
        maxWidth: 712,
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end"
      }}
    >
      <TextField
        value={contact?.company || ""}
        onChange={(e) => editContact("company", e.target.value)}
        fullWidth
        placeholder="Company"
        label="Company"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify icon={"eva:search-fill"} sx={{ color: "text.disabled", width: 20, height: 20 }} />
            </InputAdornment>
          )
        }}
      />
      <ClientLoaderButton onNextClick={onNextClick} />
    </Card>
  );
}
